
 
//need to update below link
export const domain = "https://dev.icoapp.backend.ozonechain.io/"


export const apiURI = ({
    URL: 'https://dev.icoapp.backend.ozonechain.io/',

})

