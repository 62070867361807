import React, { useState, useEffect } from 'react';

import ExcelJS from 'exceljs';
import readXlsxFile from 'read-excel-file';
import "./OzoneTransferWrapper.css"
import Web3 from 'web3';
import Modal from 'react-bootstrap/Modal';
import Lottie from 'react-lottie';
import Cookies from 'js-cookie'
import animationData from "../transactions/dots.json"


import { ethers } from 'ethers';

const OzoneTransferWrapper = () => {

    const [data, setData] = useState([]);
    const [excel, setExcel] = useState(false);
    const [popup, setPopup] = useState(false)
    const [counter, setCounter] = useState(0);
    const [total, setTotal] = useState(0)
    const transactionHash = [];

    const [loading, setLoading] = useState(false)

    const [mainPrivateKey, setMainPrivateKey] = useState('');
    const [mainWalletAddress, setMainWalletAddress] = useState('')
    const [dataPopup, setDataPopup] = useState(true)
    const [addressBalance, setAddressBalance] = useState(0)


    const getBalance = async () => {
        const web3 = new Web3("https://node1.ozonechain.io/")
        const balance = await web3.eth.getBalance(mainWalletAddress);
        const OZOBalance = parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(3);
        setAddressBalance(OZOBalance)
    }

    const handleUpload = (event) => {
        let fileObj = event.target.files[0];
        readXlsxFile(fileObj).then((rows) => {
            setData(rows);
            setExcel(true);
        });
    };

    const txs = [];

    const handleTransfer = async () => {
        setPopup(false)

        try {
            const provider = new ethers.providers.JsonRpcProvider('https://node1.ozonechain.io/');
            const MainWallet = new ethers.Wallet(mainPrivateKey, provider);
            setTotal(data.length - 1);
            setLoading(true);

            for (let i = 1; i < data.length; i++) {

                const address = ethers.utils.getAddress(data[i][1].toString());
                const amount = ethers.utils.parseEther(data[i][2].toString());

                const tx = {
                    to: address,
                    value: amount,
                };
                // gasPrice: ethers.utils.parseUnits("8", "gwei"),
                // gasLimit: 21000

                txs.push(tx);
            }

            for (let i = 0; i < txs.length; i++) {
                const tx = txs[i];
                const txResponse = await MainWallet.sendTransaction(tx);
                const txHash = txResponse.hash;
                setCounter(i + 1)
                await transactionHash.push(txHash);

                // const nonce = await web3.eth.getTransactionCount(web3.eth.defaultAccount, "pending")
                // tx.nonce = nonce;
                // const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);
                // const sentTx = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
                // // console.log(sentTx)
                // const txHash = sentTx.transactionHash;
                // // console.log(txHash,"TXN Hash")


            }
            setLoading(false)
            setCounter(0)
            setTotal(0)
            await handleDownload("try");

        }
        catch (e) {
            alert(e);
            await handleDownload("catch");
            setLoading(false)
        }
    };



    const handleDownload = async () => {
        // console.log("txn hash download",transactionHash)
        // console.log("Excel Data",data)
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Transactions');

        worksheet.columns = [
            { header: 'S.NO', key: 'index' },
            { header: 'Public Address', key: 'address' },
            { header: 'Amount', key: 'amount' },
            { header: 'Transaction Hash', key: 'hash' }
        ];
         
        data.slice(1).forEach((row, i) => {
            const address = row[1];
            const amount = row[2];
            const hash = transactionHash[i];
            const index = i + 1;
            worksheet.addRow({ index, address, amount, hash });

        });
    

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ozo_transfer.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            readXlsxFile(blob).then((rows) => {
                setData(rows);
            });
        });
    }


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const handleDataPopup = () => {
        if (mainWalletAddress === '' || mainPrivateKey === '') {
            alert("Please Fill up Data to Proceed");
        }
        else {
            const wallet = new ethers.Wallet(mainPrivateKey);
            console.log(wallet.address, "\t", mainWalletAddress);
            if (wallet.address === mainWalletAddress) {
                setDataPopup(false)
                getBalance();
            }
            else {
                alert("Private Key and Public Key Doesn't Match");
            }
        }
    }


    if (dataPopup) {
        return (
            <Modal show={"true"} backdrop="static">
                <Modal.Header >
                    <Modal.Title>Enter Wallet Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div class="input-group my-5">
                        <span class="input-group-text " id="basic-addon1">Enter Private Key </span>
                        <input type="text" class="ms-2 form-control" placeholder="Private Key" aria-label="Username" aria-describedby="basic-addon1" value={mainPrivateKey} onChange={(e) => setMainPrivateKey(e.target.value)} />
                    </div>
                    <div class="input-group my-5">
                        <span class="input-group-text " id="basic-addon1">Enter Wallet Address </span>
                        <input type="text" class="ms-2 form-control" placeholder="Wallet Address" aria-label="Username" aria-describedby="basic-addon1" value={mainWalletAddress} onChange={(e) => setMainWalletAddress(e.target.value)} />
                    </div>
                    <div className="modal-content">
                        <div className="popup-body">
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <button className="btn btn-primary" style={{ width: "80px" }} onClick={handleDataPopup}>Submit</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        )
    }

    return (
        <main className="page-content-admin">
            <div className=''>
                <div className='d-flex justify-content-between'>
                    <h2>Ozone Transfer Section</h2>
                    <button className='btn btn-danger btn-sm' onClick={() => setDataPopup(true)}>Edit Private Key</button>
                </div>
                <hr />
                <div className='d-flex mb-5 mt-3' style={{ justifyContent: "space-evenly" }}>
                    <div className='d-flex flex-column' >
                        <h5>Main Public Key : </h5>
                        <p>{mainWalletAddress}</p>
                    </div>
                    <div className='d-flex flex-column' >
                        <h5>Ozone Distribution Address Balance : </h5>
                        <p style={{ textAlign: "center" }}>{addressBalance} OZO</p>
                    </div>
                </div>

                <div className='d-flex ' style={{ justifyContent: "space-evenly" }}>
                    <input type="file" name="file" onChange={handleUpload} />
                    <button className='btn btn-primary' disabled={!excel ? true : false} onClick={() => setPopup(true)}>Transfer OZO</button>
                </div>
                <br />

                {excel ?

                    <table className='table align-middle'>
                        <thead className='table-secondary'>
                            {data.slice(0, 1).map((row, i) => (
                                <tr key={i}>
                                    {row.map((cell, j) => (
                                        <th key={j}>{cell}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {data.slice(1).map((row, i) => (
                            <tr key={i}>
                                {row.map((cell, j) => (
                                    <td key={j}>{cell}</td>
                                ))}
                            </tr>
                        ))}
                    </table>
                    :
                    <h5 className='mt-4' style={{ textAlign: "center" }}>Please Upload a File to Transfer</h5>
                }
            </div>

            <Modal show={popup} onHide={() => { setPopup(false) }}>
                <Modal.Header >
                    <Modal.Title>Ozone Distribution Confirmation </Modal.Title>
                    <a className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => { setPopup(false) }} style={{ cursor: "pointer" }}><em className="ti ti-close"></em></a>
                </Modal.Header>
                <Modal.Body>
                    <p className='mt-4 mb-4 ' style={{ textAlign: "center" }}> Please confirm your decision to proceed with the transfer of funds or engage in a similar financial transaction. It is important to ensure that you have thoroughly considered and are fully committed to this action before proceeding.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-success" style={{ width: "80px" }} onClick={handleTransfer} >Yes</button>
                    <button className="btn btn-outline-success" style={{ width: "80px" }} onClick={() => { setPopup(false) }}>No</button>
                </Modal.Footer>
            </Modal>

            <Modal show={loading} centered size="sm">
                <Modal.Body>
                    <Lottie
                        options={defaultOptions}
                        height={200}
                        width={150}
                    />
                    <p className='mb-4' style={{ fontSize: "20px", marginTop: "-40px", color: "black", textAlign: "center" }}> {counter}/{total} Completed</p>
                </Modal.Body>
            </Modal>
        </main>
    )
}

export default OzoneTransferWrapper


