
import React from 'react';
import { useState } from 'react';
import metamask from "../../assets/img-ozo/metamask-1.png"
import paypal from "../../assets/img-ozo/pay-c.png"
import Password from './profiledetails/password';
import Personaldata from './profiledetails/personaldata';
import Wallet from './profiledetails/wallets';
import { useDispatch, useSelector } from 'react-redux';
import { navwallet, currentwallet } from '../../../src/reducers/ConstantSlice';
import { useEffect } from 'react';
import { BiCopy, BiLink } from 'react-icons/bi';
import { useCopyToClipboard } from "usehooks-ts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { apiURI } from "./../../config/config"
import Cookies from 'js-cookie'
function Profilewrapper() {
   const dispatch = useDispatch();
   const refferalcode = useSelector((state) => state.constVar.refferalcode)
   const [personaldata, setpersonaldata] = useState(false);
   const [wallets, setWallets] = useState(true);
   const [password, setPassword] = useState(false);
   const [allWalletDetails, setallWalletDetails] = useState()
   const [walletAddress, setwalletAddress] = useState("");
   const [fromWallet, setfromWallet] = useState("")
   const [showReferral, setshowReferral] = useState(false)
   const [firstName, setfirstName] = useState("")
   const [lastName, setlastName] = useState("")
   const [email, setemail] = useState("")
   const [mobileNumber, setmobileNumber] = useState("")
   const [DOB, setDOB] = useState("")
   const [nationality, setnationality] = useState("")
   const [value, copy] = useCopyToClipboard();


   const navdata = useSelector((state) => state.constVar.navwallet)
   const user_id = useSelector((state) => state.constVar.user_id)

   const handlepersonaldata = () => {
      dispatch(navwallet("product"))
      setpersonaldata(true);
      setWallets(false);
      setPassword(false);
   }
   const handleWallet = () => {
      setpersonaldata(false);
      setWallets(true);
      setPassword(false);
   }

   const handlePassword = () => {
      dispatch(navwallet("product"))
      setpersonaldata(false);
      setWallets(false);
      setPassword(true);
   }


   const getWallet = () => {
      console.log(walletAddress, "wallet adres")
      try {
         fetch(apiURI.URL + 'get_wallet_address/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(
               {
                  user_id: Cookies.get('type')==="guest"?143:user_id,

               }
            )
         })
            .then(response => response.json())

            .then(data => {
               console.log("all wallet data", data)
               setallWalletDetails(data);
               let currentWallet = data.find(object => object.current === true);
               setfromWallet(currentWallet.wallet_address)
               dispatch(currentwallet(currentWallet.wallet_address));
            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }

   }

   useEffect(() => {

      getWallet();

   }, [])


   const getProfile = () => {


      try {
         fetch(apiURI.URL + 'get_user_profile/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  user_id: Cookies.get('type')==="guest"?143:user_id,
               }
            )
         })
            .then((response) => {

               const json = response.json();
               console.log("response data", json)
               return json;
            })

            .then(data => {
               console.log("receiving data", data[0]?.first_name)
               setfirstName(data[0]?.first_name)
               setlastName(data[0]?.last_name)
               setemail(data[0]?.email)
               setDOB(data[0]?.date_of_birth)
               setmobileNumber(data[0]?.phone)
               setnationality(data[0]?.nationality)

            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }




   }

   useEffect(() => {
      getProfile();
   }, [])

   const generateReferral = () => {
      if(Cookies.get("type")==="guest"){
         toast.warn("Please Sign in as User to get Referral Link", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
         });

      }
      else{
         if (fromWallet != null && fromWallet !== "" && fromWallet !== undefined) {
            setshowReferral(true)
         }
         else {
            toast.warn("Please add a wallet address to your profile to generate referal link", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
            });
         }


      }


   }

   const copied = () => {
      toast.success("Copied Successfully", {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
      });
   }

   return (
      <div className="page-content">
         <div className="container">
            <div className="row">
               <div className="main-content col-lg-8">
                  <div className="content-area card">
                     <div className="card-innr">
                        <div className="card-head">
                           <h4 className="card-title">Profile Details</h4>
                        </div>
                        {Cookies.get('type') === "guest"?"":
                        <>
                        {personaldata && navdata === "product" ? <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                           <li className="nav-item" style={{ cursor: "pointer" }}><a className="nav-link active" onClick={() => handlepersonaldata()}>Personal Data</a></li>
                           <li className="nav-item" style={{ cursor: "pointer" }} ><a className="nav-link" onClick={() => handleWallet()}>Wallets</a></li>
                           <li className="nav-item" style={{ cursor: "pointer" }} ><a className="nav-link" onClick={() => handlePassword()}>Password</a></li>
                        </ul>
                           : wallets || navdata === "wallet" ? <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                              <li className="nav-item" style={{ cursor: "pointer" }} ><a className="nav-link " onClick={() => handlepersonaldata()}>Personal Data</a></li>
                              <li className="nav-item" style={{ cursor: "pointer" }} ><a className="nav-link active" onClick={() => handleWallet()}>Wallets</a></li>
                              <li className="nav-item" style={{ cursor: "pointer" }} ><a className="nav-link" onClick={() => handlePassword()}>Password</a></li>
                           </ul>
                              : password ?

                                 <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                                    <li className="nav-item" style={{ cursor: "pointer" }} ><a className="nav-link " onClick={() => handlepersonaldata()}>Personal Data</a></li>
                                    <li className="nav-item" style={{ cursor: "pointer" }} ><a className="nav-link " onClick={() => handleWallet()}>Wallets</a></li>
                                    <li className="nav-item" style={{ cursor: "pointer" }} ><a className="nav-link active" onClick={() => handlePassword()}>Password</a></li>
                                 </ul> : ""

                        }</>}



                        <div className="tab-content" id="profile-details">

                           {personaldata && navdata === "product" ?
                              <Personaldata
                                 getProfile={getProfile}
                                 firstName={firstName}
                                 lastName={lastName}
                                 DOB={DOB}
                                 mobileNumber={mobileNumber}
                                 email={email}
                                 nationality={nationality}
                                 setfirstName={setfirstName}
                                 setlastName={setlastName}
                                 setemail={setemail}
                                 setDOB={setDOB}
                                 setnationality={setnationality}
                                 setmobileNumber={setmobileNumber}

                              ></Personaldata>
                              : wallets || navdata === "wallet" ?
                                 <Wallet
                                    getWallet={getWallet}
                                    allWalletDetails={allWalletDetails}
                                    walletAddress={walletAddress}
                                    setwalletAddress={setwalletAddress}
                                 ></Wallet>
                                 : password ?
                                    <Password></Password> : ""
                           }





                        </div>

                     </div>

                  </div>

                  {/* <div className="content-area card">
                     <div className="card-innr">
                        <div className="card-head">
                           <h4 className="card-title">Two-Factor Verification</h4>
                        </div>
                        <p>Two-factor authentication is a method for protection your web account. When it is activated you need to enter not only your password, but also a special code. You can receive this code by in mobile app. Even if third person will find your password, then can't access with that code.</p>
                        <div className="d-sm-flex justify-content-between align-items-center pdt-1-5x">
                           <span className="text-light ucap d-inline-flex align-items-center"><span className="mb-0"><small>Current Status:</small></span> <span className="badge badge-disabled ml-2">Disabled</span></span>
                           <div className="gaps-2x d-sm-none"></div>
                           
                        </div>
                     </div>

                  </div> */}

               </div>

               <div className="aside sidebar-right col-lg-4">
                  <div className="account-info card">
                     <div className="card-innr">
                        {/* <h6 className="card-title card-title-sm">Your Account Status</h6>
                        <ul className="btn-grp">
                           <li><p className="btn btn-auto btn-xs btn-success" style={{ cursor: "default" }}>Email Verified</p></li>

                        </ul> */}
       
                        <h6 className="card-title card-title-sm">Receiving Wallet Address</h6>
                        <div className="d-flex justify-content-between"><span style={{ width: "300px", overflowX: "auto" }}><span style={{ width: "300px", overflowX: "auto" }} >{fromWallet==undefined||fromWallet==null||fromWallet==""?localStorage.getItem("walletaddress"):fromWallet}</span>
                           &nbsp;<em className="fas fa-info-circle text-exlight" data-toggle="tooltip" data-placement="bottom" title="The sozo will be received in this account"></em>
                        </span>

                        </div>
                        <Link to="/buyozo"><button className="btn btn-primary mt-3" >Buy OZO</button></Link>
                     </div>
                  </div>
                
                  <div className="referral-info card">
                     <div className="card-innr">
                        <h6 className="card-title card-title-sm">Earn with Referral</h6>
                        <p className=" pdb-0-5x">Invite your friends and receive referral tokens.</p>

                        {showReferral ?
                           <div className="copy-wrap mgb-0-5x" onClick={() => copy("https://dev.icoapp.frontend.ozonechain.io/signup?id=" + refferalcode)} style={{
                              display: "flex", border: "2px solid #e3e3e3", width: "100%", margin: "0", padding: "5px", cursor: "pointer"
                           }}>
                              <BiLink />&nbsp;&nbsp;

                              <p style={{ margin: "0", width: "100%", overflowY: "hidden" }} >https://dev.icoapp.frontend.ozonechain.io/signup?id={refferalcode}
                                 &nbsp;<div style={{ float: "right" }} className="copy-trigger copy-clipboard"><BiCopy onClick={copied} /></div>
                                 {/* <button onClick={() => copy( referralCode )}><BiCopy/></button> */}
                              </p>
                           </div> : <></>}
                           
                       
                           <button className="btn btn-primary " onClick={generateReferral}>Generate referral link</button>
                           <p className='mt-2' style={{fontWeight:"500"}}>The referral OZO coins will be distributed 30 days after the Token Generation Event (TGE).</p>
                           
                          
                           


                        {/* <div className="copy-wrap mgb-0-5x"><span className="copy-feedback"></span><em className="fas fa-link"></em><input type="text" className="copy-address" value="https://ozone.io/" disabled></input><button className="copy-trigger copy-clipboard" data-clipboard-text="https://demo.themenio.com/ico?ref=7d264f90653733592"><em className="ti ti-files"></em></button></div> */}

                     </div>
                  </div>
               </div>

            </div>

         </div>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
         {/* Same as */}
         <ToastContainer />
      </div>





   );
}

export default Profilewrapper;
