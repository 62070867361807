
import React from 'react';
import Footer from "../footer"
import Topbar from "../topbar"
import Navbar from "../navbar"
import Dashboardwrapper from './dashboardwrapper';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ratesRemoveAll } from '../../../src/reducers/TokenDetailSlice';
import { accountdata } from '../../../src/reducers/ConstantSlice';
import { apiURI } from "./../../config/config"
   import Cookies from 'js-cookie'


function Dashboard() {
   const dispatch = useDispatch();
   const [divClass, setDivClass] = useState('');
   const [displaymobnav, setdisplaymobnav] = useState('');
   const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
   const [coinvalue, setcoinvalue] = useState()
   const user_id = useSelector((state) => state.constVar.user_id)
   const [ban, setban] = useState(true)


//navbar window/mobile switch
   useEffect(() => {
      const handleResize = () => setViewportWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   useEffect(() => {
      if (viewportWidth < 992) {
         setDivClass('navbar-mobile');
         setdisplaymobnav("none")
      }
      else {
         setdisplaymobnav("block")
      }
   }, [viewportWidth]);

   const handleButtonClick = () => {
      console.log("clicked")
      if (viewportWidth < 992) {
         console.log("check if")
         setDivClass(prevClass => prevClass === 'navbar-mobile' ? 'navbar-mobile active' : 'navbar-mobile');
         setdisplaymobnav(prevClass => prevClass === 'none' ? 'block' : 'none')
      }
   };


//token details API call
   const fetchrate = async () => {
      try {
         await fetch(apiURI.URL + "ozonetoken/",
         {
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
         }
            )
            .then((response) => {
               const json = response.json();
               return json;
            })
            .then(data => {

               console.log(data, "coinmarket");
               setcoinvalue(data);
            })

      } catch (error) {
         console.log(error, "error");
      }

   }

//account details API call for topbar
   const accountDetails = () => {
      try {
         fetch(apiURI.URL + 'myaccount/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  "user_id": user_id,

               }
            )
         })
            .then(response => response.json())
            .then(data => {
               console.log("Account token details", data)
               dispatch(accountdata(data));
            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
   }


   useEffect(() => {
      fetchrate();
      accountDetails();
   }, [])

   dispatch(ratesRemoveAll(coinvalue))
  

   var adminstatus;
   if (Cookies.get('session') === undefined) {
     console.log("cookies not defined")
   }
   else{
     let sessionData = Cookies.get('session');
     let sessionJSON = JSON.parse(sessionData);
     adminstatus = sessionJSON.adminstatus;
   }
 
   Cookies.set('session', JSON.stringify({ userId: user_id, adminstatus: adminstatus,coin:coinvalue}));




   return (

      <div className="">
         <div className="topbar-rap">
            <Topbar
               handleButtonClick={handleButtonClick}
               setdisplaymobnav={setdisplaymobnav}
               ban={ban}
               displaymobnav={displaymobnav}
               divClass={divClass}
               setDivClass={setDivClass}
            ></Topbar>
            <Navbar
            ban={ban}
               displaymobnav={displaymobnav}
               divClass={divClass}
               setDivClass={setDivClass}
            ></Navbar>
         </div>
         <Dashboardwrapper></Dashboardwrapper>
         <Footer></Footer>
      </div>

   );
}

export default Dashboard;
