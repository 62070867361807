
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
function Transactionsrow({i,viewTransaction,showDelete}) {
    const coinvalue = useSelector((state) => state.TokenDet.arr)
    const round = useSelector((state) => state.TokenDet.arr)


   return (
                        <tr className="data-item">
                           <td className="data-col ">
                              <div className="d-flex align-items-center">
                                 <div className="fake-class"><span className="lead tnx-id">{i?.transaction_no}</span><span className="sub sub-date">{i?.timestamp_initiated}</span></div>
                              </div>
                           </td>
                           <td className="data-col"><span className="lead token-amount">{i?.funding_type}</span></td>
                           <td className="data-col"><span className="lead token-amount">{i?.ozo_received.toFixed(2)}</span><span className="sub sub-symbol">OZO</span></td>
                           {/* {(coinvalue?.data[0]?.price_per_token)*i?.send_tokens} */}
                           <td className="data-col"><span className="lead amount-pay">{i?.usdt_spent.toFixed(2)}</span><span className="sub sub-symbol">USD ($) </span></td>
                           <td className="data-col"><span className="lead user-info"> {i?.paid_amount===undefined||i?.paid_amount===null?0:i?.paid_amount.toFixed(2)} $</span><span className="sub sub-date"></span></td>
                           
                           <td className="data-col"><span className="lead user-info">{i?.to_address.substring(0, 8)}.....{i?.to_address.substring(38, 42)}</span><span className="sub sub-date"></span></td>
                           <td className="data-col"><span className="lead user-info">{i?.transaction_type}</span><span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">P</span></td>
                           
                           {(i?.coinbase_status === "NEW" ||i?.coinbase_status === "PENDING"  ||i?.status==="pending")? 
                           <td className="data-col"><span className="badge badge-warning ucap" >Pending</span></td>
                            :i?.coinbase_status === "COMPLETED" && i?.status === "paid"? 
                           <td className="data-col"><span className="badge badge-success ucap">Paid</span></td>
                           :i?.coinbase_status === "UNRESOLVED" && i?.status === "paid"? 
                           <td className="data-col"><span className="badge badge-success ucap">Paid</span></td>
                           :i?.coinbase_status === "COMPLETED" && i?.status === "transferred"? 
                           <td className="data-col"><span className="badge badge-info ucap">Transferred</span></td>
                           :<td className="data-col"><span className="badge badge-danger ucap">{i?.coinbase_status}</span></td>
                           }
                           
                           <td className="data-col">
                              <ul className="data-action-list  d-md-inline-flex">
                              {(i?.coinbase_status === "COMPLETED" ||i?.coinbase_status === "EXPIRED" ||i?.coinbase_status === "CANCELED" ||i?.coinbase_status === "REFUNDED"||i?.coinbase_status === "PARTIALLY_REFUNDED"|| i?.coinbase_status === "UNRESOLVED")  ?
                              <></>
                              :   
                              (i?.coinbase_status === "PENDING" ||i?.coinbase_status === "NEW"||i?.coinbase_status === "RESOLVED"  ||i?.status==="pending")&&(i?.transaction_type!=="Referral") ?                           
                              <li><a href={"https://commerce.coinbase.com/charges/"+i?.coinbase_charge_id} className="btn btn-auto btn-primary btn-xs" style={{width:"auto"}} target="_blank" rel="noreferrer"><span>Pay <span className="d-none d-xl-inline-block">Now</span></span><em className="ti ti-wallet"></em></a></li>
                             :
                             <></>
                              }


                              </ul>
                           </td>
                           <td className="data-col">
                           <ul className="data-action-list  d-md-inline-flex">
                           <li>
                              <button  onClick={() => {viewTransaction(i?.transaction_no)}} className="btn btn-light-alt btn-xs btn-icon">
                                 <em className="ti ti-eye"></em>
                              </button>
                              </li>
                              {i?.status==="pending"?
                                                               <li onClick={() => {showDelete(i?.transaction_no)}}>
                                                               <p class="btn btn-danger-alt btn-xs btn-icon"><em class="ti ti-trash"></em></p></li>:""}

                                 </ul>
                           </td>
                        </tr>

   );
}

export default Transactionsrow;
