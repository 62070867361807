import React from "react";
import logo from "../assets/img-ozo/logo-ozone-light-284x85.png"
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import dashboardlogo from "../assets/img-ozo/dashboard.png"
import buylogo from "../assets/img-ozo/ozo-ico.png"
import transactionlogo from "../assets/img-ozo/tran.png"
import profilelogo from "../assets/img-ozo/profile.png"
import myaccountlogo from "../assets/img-ozo/my-account.png"
// import banner from "../assets/img-ozo/ozo-ico-banner.jpg"
// import banner2 from "../assets/img-ozo/ozo-ico-banner-2.jpg"
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie'

function Topbar({ handleButtonClick,setDivClass,
  divClass, displaymobnav,ban }) {
  const [showprofile, setshowprofile] = useState(false);
  const [showprofile2, setshowprofile2] = useState(false);
  const navigate = useNavigate()

  
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const coinvalue = useSelector((state) => state.TokenDet.arr)
  const username = useSelector((state) => state.constVar.username)
  const accountdata = useSelector((state) => state.constVar.accountdata)
  const round = useSelector((state) => state.TokenDet.round)
  let Navigate = useNavigate();



  useEffect(() => {
    const handleClickOutside = (event) => {
      //  console.log("triggered")
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setshowprofile(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const handleClickOutside2 = (event) => {
      //  console.log("triggered")
      if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
        setshowprofile2(false);
      }
    };
    document.addEventListener('click', handleClickOutside2);
    return () => {
      document.removeEventListener('click', handleClickOutside2);
    };
  }, [dropdownRef2]);

  const logout = () => {
    Cookies.set('session', JSON.stringify({ userId: undefined, adminstatus: undefined, Token: undefined }));
    Cookies.set('type', "guest");
    Navigate("/")
  }
  const signin = () => {

    localStorage.setItem("metamaskaddress","")
    Navigate("/login")
  }
  const paymentOption = (paytype) => 
  {
  localStorage.setItem("paytype",paytype)
  navigate("/buyozo")
}

  return (

<>

    <div className="topbar is-sticky">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">

          <ul className="topbar-nav d-lg-none">
            <li className="topbar-nav-item relative" onClick={handleButtonClick}>
              <p className="toggle-nav" href="">
                <div className="toggle-icon"><span className="toggle-line"></span><span className="toggle-line"></span><span className="toggle-line"></span><span className="toggle-line"></span></div>
              </p>
            </li>
          </ul>

          <a className="topbar-logo" href="#">
            <Link to="/dashboard"><img src={logo} alt="logo" ></img></Link>
          </a>
     


<div>
<div className={divClass} style={{ display: displaymobnav, backgroundColor: "black" }} >
   <div className="container" >
      <div className="navbar-innr">
         <ul className="navbar-menu">
            <li><a href="#" onclick="return false;" ><em className="ikon ozo "><img src={dashboardlogo}></img></em> <Link to="/dashboard" style={{ color: "white" }}>Dashboard</Link></a></li>

            <li><a href="#" onClick={(e)=>{paymentOption("coinbase")}} style={{color:"white"}}><em className="ikon ozo"><img src={buylogo}></img></em> Buy OZO (Crypto)</a></li>
            <li><a href="#" onClick={(e)=>{paymentOption("paypal")}} style={{color:"white"}}><em className="ikon ozo"><img src={buylogo}></img></em> Buy OZO (Fiat)</a></li>

            {Cookies.get('type') !== "guest" ?
               <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={transactionlogo}></img></em> <Link to="/transactions" style={{ color: "white" }}>Transactions</Link></a></li> : ""}
            {/* <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={vesting}></img></em> <Link to="/vesting" style={{color:"white"}}>Vesting</Link></a></li> */}
            <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={profilelogo}></img></em><Link to="/profile" style={{ color: "white" }}> Profile</Link></a></li>
            {Cookies.get('type') !== "guest" ?
               <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={myaccountlogo}></img></em><Link to="/account" style={{ color: "white" }}> My Account</Link></a></li> : ""}
                                    {Cookies.get('type') == "guest" ?
               <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={myaccountlogo}></img></em><Link to="/checktransaction" style={{ color: "white" }}> Transaction</Link></a></li> : ""}
         </ul>
      </div>
   </div>
</div>
</div>


          <ul className="topbar-nav">
            {/* <li className="topbar-nav-item relative">
              {Cookies.get('type') === "guest"?"":
              <span className="user-welcome d-none d-lg-inline-block">Coin Balance: {accountdata?.token_balance === undefined || accountdata?.token_balance === null ? 0 : accountdata?.token_balance.toFixed(2)} OZO</span>
            }
            </li> */}
            {Cookies.get('type') === "guest"&&(localStorage.getItem("metamaskaddress")==undefined||localStorage.getItem("metamaskaddress")==null||localStorage.getItem("metamaskaddress")=="")?
            <li className="topbar-nav-item relative" style={{padding:"0px"}}>
            <button className="btn btn-primary btn-block sgbt" style={{boxShadow:"0 0 20px black"}} onClick={()=>{signin()}}>Sign In</button>
            </li>:""}
            {(localStorage.getItem("metamaskaddress")!==undefined&&localStorage.getItem("metamaskaddress")!==null&&localStorage.getItem("metamaskaddress")!=="")?
            <li className="topbar-nav-item relative" style={{padding:"0px"}}>
            <button className="btn btn-primary btn-block sgbt" style={{boxShadow:"0 0 20px black"}} onClick={()=>{signin()}}>Sign In as User</button>
            </li>:""}

            
            {Cookies.get('type') === "guest"?"":
            <li className="topbar-nav-item relative" ref={dropdownRef}>
              <span className="user-welcome d-none d-lg-inline-block ">Welcome {username}</span>
              <button className="toggle-tigger user-thumb" onClick={() => setshowprofile(!showprofile)}>
                <em className="ti ti-user"></em>
              </button>
              
              <>
              {showprofile && (
                <div className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown active">
               
                  

                  <>
                     <div className="user-status">
                    <h6 className="user-status-title">Coin balance</h6>
                    <div className="user-status-balance">
                      {accountdata?.token_balance === undefined || accountdata?.token_balance === null ? 0 : accountdata?.token_balance.toFixed(2)}
                      <small> OZO</small>
                    </div>
                  </div>
                  <ul className="user-links">
                  
                    <Link to="/profile">
                      <li>
                        <a>
                          <i className="ti ti-id-badge"></i>My Profile
                        </a>
                      </li>
                    </Link>
                  </ul>
                  <ul className="user-links bg-light">
                  
                    <li>
                      <a href="#" onClick={() => { logout() }}>
                        <i className="ti ti-power-off"></i>Logout
                      </a>
                    </li>

                  </ul>
                  </>

                 


                </div>
              )}</>


            </li>
            }

          </ul>






        </div>

        <div className="mbv">
        <div style={{display:"flex",width:"100%",justifyContent:"space-between"}}>
<ul className="topbar-nav d-lg-none">
  <li className="topbar-nav-item relative" onClick={handleButtonClick}>
    <p className="toggle-nav" href="">
      <div className="toggle-icon"><span className="toggle-line"></span><span className="toggle-line"></span><span className="toggle-line"></span><span className="toggle-line"></span></div>
    </p>
  </li>
</ul>

<a className="topbar-logo" href="#">
  <Link to="/dashboard"><img src={logo} alt="logo" ></img></Link>
</a>
<ul className="topbar-nav">
  {/* <li className="topbar-nav-item relative">
    {Cookies.get('type') === "guest"?"":
    <span className="user-welcome d-none d-lg-inline-block">Coin Balance: {accountdata?.token_balance === undefined || accountdata?.token_balance === null ? 0 : accountdata?.token_balance.toFixed(2)} OZO</span>
  }
  </li> */}
  {Cookies.get('type') === "guest"&&(localStorage.getItem("metamaskaddress")==undefined||localStorage.getItem("metamaskaddress")==null||localStorage.getItem("metamaskaddress")=="")?
  <li className="topbar-nav-item relative" style={{padding:"0px"}}>
  <button className="btn btn-primary btn-block sgbt" style={{boxShadow:"0 0 20px black"}} onClick={()=>{signin()}}>Sign In</button>
  </li>:""}
  {(localStorage.getItem("metamaskaddress")!==undefined&&localStorage.getItem("metamaskaddress")!==null&&localStorage.getItem("metamaskaddress")!=="")?
  <li className="topbar-nav-item relative" style={{padding:"0px"}}>
  <button className="btn btn-primary btn-block sgbt" style={{boxShadow:"0 0 20px black"}} onClick={()=>{signin()}}>Sign In as User</button>
  </li>:""}

  
  {Cookies.get('type') === "guest"?"":
  <li className="topbar-nav-item relative" ref={dropdownRef2}>
    <span className="user-welcome d-none d-lg-inline-block ">Welcome {username}</span>
    <button className="toggle-tigger user-thumb" onClick={() => setshowprofile2(!showprofile2)}>
      <em className="ti ti-user"></em>
    </button>
    
    <>
    {showprofile2 && (
      <div className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown active">
     
        

        <>
           <div className="user-status">
          <h6 className="user-status-title">Coin balance</h6>
          <div className="user-status-balance">
            {accountdata?.token_balance === undefined || accountdata?.token_balance === null ? 0 : accountdata?.token_balance.toFixed(2)}
            <small> OZO</small>
          </div>
        </div>
        <ul className="user-links">
        
          <Link to="/profile">
            <li>
              <a>
                <i className="ti ti-id-badge"></i>My Profile
              </a>
            </li>
          </Link>
        </ul>
        <ul className="user-links bg-light">
        
          <li>
            <a href="#" onClick={() => { logout() }}>
              <i className="ti ti-power-off"></i>Logout
            </a>
          </li>

        </ul>
        </>

       


      </div>
    )}</>


  </li>
  }

</ul>


</div>



<div>
<div className={divClass} style={{ display: displaymobnav, backgroundColor: "black" }} >
<div className="container" >
<div className="navbar-innr">
<ul className="navbar-menu">
  <li><a href="#" onclick="return false;" ><em className="ikon ozo "><img src={dashboardlogo}></img></em> <Link to="/dashboard" style={{ color: "white" }}>Dashboard</Link></a></li>
  <li><a href="#" onClick={(e)=>{paymentOption("coinbase")}} style={{color:"white"}}><em className="ikon ozo"><img src={buylogo}></img></em> Buy OZO (Crypto)</a></li>
            <li><a href="#" onClick={(e)=>{paymentOption("paypal")}} style={{color:"white"}}><em className="ikon ozo"><img src={buylogo}></img></em> Buy OZO (Fiat)</a></li>

  {Cookies.get('type') !== "guest" ?
     <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={transactionlogo}></img></em> <Link to="/transactions" style={{ color: "white" }}>Transactions</Link></a></li> : ""}
  {/* <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={vesting}></img></em> <Link to="/vesting" style={{color:"white"}}>Vesting</Link></a></li> */}
  <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={profilelogo}></img></em><Link to="/profile" style={{ color: "white" }}> Profile</Link></a></li>
  {Cookies.get('type') !== "guest" ?
     <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={myaccountlogo}></img></em><Link to="/account" style={{ color: "white" }}> My Account</Link></a></li> : ""}
                          {Cookies.get('type') == "guest" ?
     <li><a href="#" onclick="return false;"><em className="ikon ozo"><img src={myaccountlogo}></img></em><Link to="/checktransaction" style={{ color: "white" }}> Transaction</Link></a></li> : ""}
</ul>
</div>
</div>
</div>
</div>









</div>
      </div>
    </div>
</>



  );
}

export default Topbar;
