import React, { useState, useEffect } from 'react';
import './entrypages.css';
import ozologo from "../../assets/img-ozo/logo-ozone-dark-284x85.png"
import { Link } from 'react-router-dom';
import { apiURI } from "./../../config/config"
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Lottie from 'react-lottie';
import animationData from "./dots.json"
import { IconEye,IconEyeOff } from '@tabler/icons-react';
import Cookies from 'js-cookie'

function Resetpassword() {
   const [Token, setToken] = useState("");
   const [password1, setpassword1] = useState("")
   const [password2, setPassword2] = useState("")
   const [passworderr, setPassworderr] = useState(false)
   const [passworderr2, setPassworderr2] = useState(false)
   const [passworderr3, setPassworderr3] = useState(false)
   const [show, setShow] = useState(false);
   const [showPopup, setshowPopup] = useState(false);
   const [passwordType, setPasswordType] = useState("password");
   const [passwordTypecn, setPasswordTypecn] = useState("password");
  
   const navigate = useNavigate()
   const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

   // referel code implementation 
   useEffect(() => {
      const url = window.location.search;
      const urlParams = new URLSearchParams(url);
      console.log(urlParams, "urlParams");

      const token = urlParams.get('token');
      console.log(token, "token");
      if (null != token) {
         setToken(token);
      }

   }, []);



   const togglePasswordcn = () => {
      if (passwordTypecn === "password") {
        setPasswordTypecn("text")
        return;
      }
      setPasswordTypecn("password")
    }
    const togglePassword = () => {
      if (passwordType === "password") {
        setPasswordType("text")
        return;
      }
      setPasswordType("password")
    }

    
   const submit = () => {

      var patternjjj = new RegExp(
         /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      );
      var firstLetter = patternjjj.test(password1)

      if (password1 === "" || password2 === "") {
         setPassworderr(true)
      }
      else {
         setPassworderr(false)
         if (firstLetter) {
            if (password1 === password2) {
               setPassworderr3(false)
               setPassworderr2(false)
               console.log("both password typed and password character pass", password1, password2, Token)
               try {
                  setShow(true);
                  fetch(apiURI.URL + 'reset_password/', {
                     method: 'POST',
                     headers: { 'Content-Type': 'application/json',
                     'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`},
                     body: JSON.stringify(
                        {
                           "token": Token,
                           "password1": password1,
                           "password2": password2
                        }
                     )
                  })
                     .then((response) => {

                        const json = response.json();
                        console.log("response data", json)
                        return json;
                     })

                     .then(data => {
                        setShow(false);
                        if (data?.message === "Password reset successful") {
                           setshowPopup(true)
                           // navigate('/login');
                        }
                        else if (data?.message === "Invalid or expired reset link") {
                           alert("There was an error with the link,Send reset link again")
                           navigate('/forgotpassword');
                        }

                     })
                     .catch(error => console.error(error));
               }
               catch (error) {
                  console.log(error, "error");
               }
            }
            else {
               setPassworderr3(true)
            }
         }
         else {
            setPassworderr2(true)
         }

      }



   }

   const navigatelogin = () =>{
      setshowPopup(false)
      navigate("/login")
   }


   return (
      <div className="page-ath-wrap">
         <div className="page-ath-content">
            <div className="page-ath-header"><a href="index.php" className="page-ath-logo"><img src={ozologo} alt="logo"></img></a></div>
            <div className="page-ath-form">
               <h2 className="page-ath-heading">Reset password
                  {/* <span>If you forgot your password, well, then we’ll email you instructions to reset your password.</span> */}

               </h2>
               <div >
                  <div className="input-item cs">
                     <input type={passwordType} placeholder="New Password" className="input-bordered" tabIndex="1" onChange={(e) => { setpassword1(e.target.value) }}></input>
                     
                     <div className="input-group-btn">
                        <button className="" onClick={togglePassword} style={{ height: '100%', borderRadius: '0 4px 4px 0',width:"50px",padding:"3px",backgroundColor:"transparent",border:"solid grey 1px" }}>
                          {passwordType === "password" ? <IconEyeOff /> : <IconEye />}
                        </button>
                      </div>


                  </div>
                  <div className="input-item cs">
                     <input type={passwordTypecn} placeholder="Confirm Password" className="input-bordered" tabIndex="2" onChange={(e) => { setPassword2(e.target.value) }}></input>
                     <div className="input-group-btn">
                        <button  onClick={togglePasswordcn} style={{ height: '100%', borderRadius: '0 4px 4px 0',width:"50px",padding:"3px",backgroundColor:"transparent",border:"solid grey 1px" }}>
                          {passwordTypecn === "password" ? <IconEyeOff /> : <IconEye />}
                        </button>
                      </div>
                  </div>
                  {passworderr ? <p style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>Please fill both password</p> : <></>}
                  {passworderr2 ? <p style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>Password must have 8 character length of atleast one capital letter and one small letter with one or more special characters and numbers</p> : <></>}
                  {passworderr3 ? <p style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>password do not match</p> : <></>}

                  <div className="d-flex justify-content-between align-items-center cs">
                     <div><button className="btn btn-primary btn-block" onClick={submit}>Submit</button></div>
                     <div><Link to="/login"><p className='mt-2'>Return to login</p></Link></div>
                  </div>
                  <div className="gaps-2x"></div>
               </div>
            </div>
            <div className="page-ath-footer">
               <ul className="footer-links">

                  <li>&copy; 2023 Ozonechain Labs.</li>
               </ul>
            </div>
         </div>
         <div className="page-ath-gfx">
            <div className="w-100 d-flex justify-content-center">

            </div>
         </div>
         <div style={{position:"fixed", padding: "100px", display:show===true?"flex":"none" , justifyContent: "center",width:"100%",height:"100%",backgroundColor:"#80808063",top:"0px",left:"0px" }}>
<Lottie 
	    options={defaultOptions}
        height={300}
        width={300}
      style={{marginTop:"10%"}}/>
               </div>

               <Modal show={showPopup} 
               size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
            <Modal.Body>
               <div style={{ padding: "20px", justifyContent: "center",textAlign:"center" }}>


                  <p style={{fontSize:"17px"}}>Password changed successfully
</p>
                  <br></br>
                  <div style={{display:"flex",justifyContent:"center"}}>
                  <button className="btn btn-primary" style={{width:"80px"}} onClick={navigatelogin}>OK</button>

                  </div>
               </div>



            </Modal.Body>
         </Modal>
      </div>
   );
}

export default Resetpassword;
