import React, { useState, useEffect } from 'react';

import ExcelJS from 'exceljs';
import readXlsxFile from 'read-excel-file';
import "./DistributionWrapper.css"
import Web3 from 'web3';
import Modal from 'react-bootstrap/Modal';
import { apiURI } from "../../../config/config"
import Lottie from 'react-lottie';
import Cookies from 'js-cookie'
import animationData from "../transactions/dots.json"

const DistributionWrapper = () => {

  const [data, setData] = useState([]);
  const [excel, setExcel] = useState(false);
  const [popup, setPopup] = useState(false)
  const [counter, setCounter] = useState(0);
  const [total, setTotal] = useState(0)
  // const [transactions, setTransactions] = useState([]);
  const transactionHash = [];
  const newTransactions = [];

  const [addressBalance, setAddressBalance] = useState(0)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getBalance = async () => {
      const web3 = new Web3("https://node1.testnet.ozonechain.io/")
      const balance = await web3.eth.getBalance("0x3b1e20Aea65D5dC0b7948258f3B0b41618a96c6b");
      const ethBalance = parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(3);
      setAddressBalance(ethBalance)
    }
    getBalance();
  }, [])


  const handleUpload = (event) => {
    let fileObj = event.target.files[0];
    readXlsxFile(fileObj).then((rows) => {
      setData(rows);
      setExcel(true);
    });
  };

  const txs = [];

  const handleTransfer = async () => {
    setPopup(false)

    try {
      const web3 = new Web3("https://node1.testnet.ozonechain.io/");
      const privateKey = "1db528ef49379554cf982f54eb5210644613d1673acb3f1920768fd5ca1d50f5";
      // const privateKey = process.env.REACT_APP_PRIVATE_KEY;
      const account = web3.eth.accounts.privateKeyToAccount(privateKey);
      web3.eth.accounts.wallet.add(account);
      web3.eth.defaultAccount = account.address;
      setTotal(data.length - 1)
      setLoading(true)



      for (let i = 1; i < data.length; i++) {

        const address = web3.utils.toChecksumAddress(data[i][1].toString())
        const amount = data[i][2].toString();

        const tx = {
          from: web3.eth.defaultAccount,
          to: address,
          value: web3.utils.toWei(amount, 'ether'),
          gasPrice: web3.utils.toWei("8", "gwei"),
          gasLimit: 21000
        };

        txs.push(tx);
      }

      for (let i = 0; i < txs.length; i++) {
        // console.log("gasPrice",tx)
        const tx = txs[i];
        const nonce = await web3.eth.getTransactionCount(web3.eth.defaultAccount, "pending")
        tx.nonce = nonce;
        const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);
        const sentTx = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
        // console.log(sentTx)
        const txHash = sentTx.transactionHash;
        // console.log(txHash,"TXN Hash")

        setCounter(i + 1)
        await transactionHash.push(txHash);
      }
      setLoading(false)
      setCounter(0)
      setTotal(0)
      await handleDownload("try");

    }
    catch (e) {
      alert(e);
      await handleDownload("catch");
      setLoading(false)
    }
  };

  // const updateAutoTransfer = (transactions, location) => {
  //   console.log(transactions, "transaction data after break", transactionHash)


  //   try {
  //     fetch(apiURI.URL + 'bulk_transaction/', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': Cookies.get('type') === "guest" ? "" : `Bearer ${localStorage.getItem('token')}`,
  //       },
  //       body: JSON.stringify(
  //         {
  //           "transactions": location === "try" ? transactions : transactions.slice(0, counter - 1)

  //         }
  //       )
  //     })
  //       .then(response => response.json())

  //       .then(data => {

  //       })
  //       .catch(error => console.error(error));
  //   }
  //   catch (error) {
  //     console.log(error, "error");
  //   }


  // }

  const handleDownload = async (location) => {
    // console.log("txn hash download",transactionHash)
    // console.log("Excel Data",data)
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Transactions');

    worksheet.columns = [
      { header: 'S.NO', key: 'index' },
      { header: 'Public Address', key: 'address' },
      { header: 'Amount', key: 'amount' },
      { header: 'Transaction Hash', key: 'hash' }
    ];
    let newTransaction = [];
    data.slice(1).forEach((row, i) => {
      const address = row[1];
      const amount = row[2];
      const hash = transactionHash[i];
      const index = i + 1;
      worksheet.addRow({ index, address, amount, hash });

      var currentDate = new Date();
      var options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      };
      let timestamp_init = new Intl.DateTimeFormat('en-US', options).format(currentDate);
      newTransaction = {
        to_address: address,
        ozo_received: amount,
        user_id: 58,
        timestamp_initiated: timestamp_init,
        tx_hash: hash,
      };

      newTransactions.push(newTransaction);
      // Add row to Excel sheet here


      // Update state with new transactions
      // setTransactions(newTransactions);





    });
    // updateAutoTransfer(newTransactions, location)

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ozo_distribution.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      readXlsxFile(blob).then((rows) => {
        setData(rows);

      });
    });
  }


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <main className="page-content-admin">
      <div className=''>
        <h2>Ozone Distribution Tab</h2>
        <hr />
        <div className='d-flex mb-5 mt-3' style={{ justifyContent: "space-evenly" }}>
          <div className='d-flex flex-column' >
            <h5>Ozone Distribution Address : </h5>
            <p>0xFe3829fE9e50ddb78F5F9bB5050CcD0B3753C16A</p>

          </div>
          <div className='d-flex flex-column' >
            <h5>Ozone Distribution Address Balance : </h5>
            <p style={{ textAlign: "center" }}>{addressBalance} OZO</p>
          </div>
        </div>

        <div className='d-flex ' style={{ justifyContent: "space-evenly" }}>
          <input type="file" name="file" onChange={handleUpload} />
          <button className='btn btn-primary' disabled={!excel ? true : false} onClick={() => setPopup(true)}>Transfer OZO</button>
        </div>
        <br />

        {excel ?

          <table className='table align-middle'>
            <thead className='table-secondary'>
              {data.slice(0, 1).map((row, i) => (
                <tr key={i}>
                  {row.map((cell, j) => (
                    <th key={j}>{cell}</th>
                  ))}
                </tr>
              ))}
            </thead>
            {data.slice(1).map((row, i) => (
              <tr key={i}>
                {row.map((cell, j) => (
                  <td key={j}>{cell}</td>
                ))}
              </tr>
            ))}
          </table>
          :
          <h5 className='mt-4' style={{ textAlign: "center" }}>Please Upload a File to Transfer</h5>
        }
      </div>
      <Modal show={popup} onHide={() => { setPopup(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content">
            <div className="popup-body">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="btn btn-primary" style={{ width: "80px" }}>Yes</button>
                <button className="btn btn-primary ml-2" style={{ width: "80px" }}>No</button>
              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>

      <Modal show={popup} onHide={() => { setPopup(false) }}>
        <Modal.Header >
          <Modal.Title>Ozone Distribution Confirmation </Modal.Title>
          <a className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => { setPopup(false) }} style={{ cursor: "pointer" }}><em className="ti ti-close"></em></a>
        </Modal.Header>
        <Modal.Body>
          <p className='mt-4 mb-4 ' style={{ textAlign: "center" }}> Please confirm your decision to proceed with the transfer of funds or engage in a similar financial transaction. It is important to ensure that you have thoroughly considered and are fully committed to this action before proceeding.</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-success" style={{ width: "80px" }} onClick={handleTransfer} >Yes</button>
          <button className="btn btn-outline-success" style={{ width: "80px" }} onClick={() => { setPopup(false) }}>No</button>
        </Modal.Footer>
      </Modal>

      <Modal show={loading} centered size="sm">
        <Modal.Body>
          <Lottie
            options={defaultOptions}
            height={200}
            width={150}
          />
          <p className='mb-4' style={{ fontSize: "20px", marginTop: "-40px", color: "black", textAlign: "center" }}> {counter}/{total} Completed</p>
        </Modal.Body>
      </Modal>
    </main>
  )
}

export default DistributionWrapper


