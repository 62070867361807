import './App.css';
import "./assets/css/vendor.css"
import "./assets/css/style.css"
import "./assets/css/customize.css"
import Buyozo from './Subpages/buyozo/buyozo'
import Profile from './Subpages/profile/profile'
import Transactions from './Subpages/transactions/transactions';
import Vesting from './Subpages/vesting/vesting';
import Dashboard from './Subpages/dashboard/dashboard';
import { Routes, Route, Redirect, Navigate } from 'react-router-dom';
import { user_id } from "./reducers/ConstantSlice"
import Account from './Subpages/account/account';
import Login from './Subpages/entrypages/login';
import Signup from './Subpages/entrypages/signup';
import Forgotpassword from './Subpages/entrypages/forgotpassword';
import Dashboardadmin from './Subpages-admin/dashboard/dashboard/dashboard-admin';
import Transactionadmin from './Subpages-admin/dashboard/transactions/transaction-admin';
import Walletadmin from './Subpages-admin/dashboard/wallets/walletwrapper';
import { useState, useEffect } from 'react';
import Vestingadmin from './Subpages-admin/dashboard/vesting/vesting-admin';
import Resetpassword from './Subpages/entrypages/resetpassword';
import Paymentfailed from './Subpages/paymentstatus/paymentfailed';
import Paymentsuccess from './Subpages/paymentstatus/paymentsuccess';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie'
import { ratesRemoveAll } from './reducers/TokenDetailSlice';
import Distribution from './Subpages-admin/dashboard/distribution/Distribution';
import BabyOZO from "./Subpages-admin/dashboard/babyozo/BabyOZO";
import Checktransaction from './Subpages/checktransaction/checktransaction';
import "./assets/css/topbar.css"
import OzoneTransfer from './Subpages-admin/dashboard/ozone/OzoneTransfer';


function App() {
  const [authenticated, setauthenticated] = useState(false)
  const [admin, setadmin] = useState(false)
  const [user, setuser] = useState(false)
  const dispatch = useDispatch();


//dispatch userid from cookies for API fetch if page is reloaded
    if (Cookies.get('session') === undefined) {
      console.log("cookies not defined")
    }
    else {
      let sessionData = Cookies.get('session');
      let sessionJSON = JSON.parse(sessionData);
      let loginId = sessionJSON.userId;
      let coin = sessionJSON.coin;
      console.log("login Id from cookie", loginId)
      dispatch(user_id(loginId));
      dispatch(ratesRemoveAll(coin));
    }


//checking userid and admin status to allow route if page is reloaded.
  var userstatus;
  var adminstatus;
  if (Cookies.get('session') === undefined) {
    console.log("cookies not defined")
  }
  else{
    let sessionData = Cookies.get('session');
    let sessionJSON = JSON.parse(sessionData);
    userstatus = sessionJSON.userId;
    adminstatus = sessionJSON.adminstatus;
  }


//setting cookies to be guest

if(Cookies.get('type')=== undefined)
{
  Cookies.set('type', "guest");
}

//chat bot code
(function(d, s, id){
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {return;}
  js = d.createElement(s); js.id = id;
  js.src = 'https://api.anychat.one/widget/ab9a810f-fec3-4c90-b8f2-48493b16d045?r=' + encodeURIComponent(window.location);
  fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'contactus-jssdk'));

  return (

    <div className="">
    
      <Routes>
        {Cookies.get('type')==="guest"?
        <Route path="/" element={<Dashboard />} />:
        <Route path="/" element={<Login setauthenticated={setauthenticated} setadmin={setadmin} setuser={setuser} />}   />
      }
        
        <Route path="/login" element={<Login setauthenticated={setauthenticated} setadmin={setadmin} setuser={setuser} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="/paymentfailed" element={<Paymentfailed />} />
        <Route path="/paymentsuccess" element={<Paymentsuccess />} />

        {/* <Route path="/dashboard-admin" element={<Dashboardadmin />} />
                <Route path="/transactions-admin" element={<Transactionadmin />} />
                <Route path="/ozone-distribution" element={<Distribution />} />
                <Route path="/baby-ozone-distribution" element={<BabyOZO />} /> */}
      
        {
          (authenticated === true && admin === false && user === true) || (userstatus !== undefined && adminstatus === false)||Cookies.get('type')==="guest"?
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/buyozo" element={<Buyozo />} />
              <Route path="/transactions" element={<Transactions />} />

              
              <Route path="/vesting" element={<Vesting />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/account" element={<Account />} />
     
              <Route path="/checktransaction" element={<Checktransaction />} />
            </>
            : (authenticated === true && admin === true && user === false) || (userstatus !== undefined && adminstatus === true) ?
              <>
                <Route path="/dashboard-admin" element={<Dashboardadmin />} />
                <Route path="/transactions-admin" element={<Transactionadmin />} />
                <Route path="/ozone-distribution" element={<Distribution />} />
                <Route path="/baby-ozone-distribution" element={<BabyOZO />} />
                <Route path="/ozone-transfer" element={<OzoneTransfer />} />
                {/* <Route path="/wallet-admin" element={<Walletadmin />} /> */}
                <Route path="/vesting-admin" element={<Vestingadmin />} />
              </>
              :
              <>
                <Route path="/transactions" element={<Navigate replace to="/" />} />
                <Route path="/dashboard" element={<Navigate replace to="/" />} />
                <Route path="/buyozo" element={<Navigate replace to="/" />} />
                <Route path="/profile" element={<Navigate replace to="/" />} />
                <Route path="/account" element={<Navigate replace to="/" />} />
                <Route path="/dashboard-admin" element={<Navigate replace to="/" />} />
                <Route path="/transactions-admin" element={<Navigate replace to="/" />} />
                <Route path="/wallet-admin" element={<Navigate replace to="/" />} />
              </>
        }
      </Routes>
 
    </div>
  );
}

export default App;
