
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom"
import { Provider } from 'react-redux';
import store from './config/store'
import { ThirdwebProvider } from "@thirdweb-dev/react";
// import dotenv from 'dotenv';
// dotenv.config();


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThirdwebProvider    activeChain={{
        // === Required information for connecting to the network === \\
        chainId: 401, // Chain ID of the network
        // Array of RPC URLs to use
        rpc: ["https://node1.testnet.ozonechain.io/"],

        // === Information for adding the network to your wallet (how it will appear for first time users) === \\
        // Information about the chains native currency (i.e. the currency that is used to pay for gas)
        nativeCurrency: {
          decimals: 18,
          name: "Ozone Chain Testnet",
          symbol: "OZO",
        },
        shortName: "ozone-testnet", // Display value shown in the wallet UI
        slug: "ozone-testnet", // Display value shown in the wallet UI
        testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
        chain: "Ozone Chain Testnet", // Name of the network
        name: "Ozone Chain Testnet", // Name of the network
      }}
      supportedChains={[{
        chainId: 401, // Chain ID of the network
        rpc: ["https://node1.testnet.ozonechain.io/"],
        nativeCurrency: {
        decimals: 18,
        name: "Ozone Chain Testnet",
        symbol: "OZO",
        },
        shortName: "ozone-testnet", // Display value shown in the wallet UI
        slug: "ozone-testnet", // Display value shown in the wallet UI
        testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
        chain: "Ozone Chain Testnet", // Name of the network
        name: " Ozone Chain Testnet", // Name of the network
        }]}
      >
     <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </Provider>
    </ThirdwebProvider>
  </React.StrictMode>

);


reportWebVitals();
