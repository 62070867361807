import { createSlice, createEntityAdapter} from '@reduxjs/toolkit';
const userAdapter = createEntityAdapter();
const initialState = userAdapter.getInitialState({
    navwallet:'product',
    paymentaddress:"",
    buyvalue:0,
    user_id:"",
    refferalcode:"",
    username:"",
    accountdata:{},
    currentwallet:""
});
export const constantSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        navwallet(state, action) {
            state.navwallet = action.payload;
            console.log(action.payload, "datadata");
        },
        paymentaddress(state, action) {
            state.paymentaddress = action.payload;
            console.log(action.payload, "datadata paymentaddress");
        },

        buyvalue(state, action) {
            state.buyvalue = action.payload;
            console.log(action.payload, "datadata buyvalue");
        },
        user_id(state, action) {
            state.user_id = action.payload;
            console.log(action.payload, "user id ");
        },
        username(state, action) {
            state.username = action.payload;
            console.log(action.payload, "user name ");
        },
        refferalcode(state, action) {
            state.refferalcode = action.payload;
            console.log(action.payload, "referral");
        },
        accountdata(state, action) {
            state.accountdata = action.payload;
            console.log(action.payload, "accountdata");
        },
        currentwallet(state, action) {
            state.currentwallet = action.payload;
            console.log(action.payload, "current wallet");
        },
    },
});

export const {navwallet,paymentaddress,buyvalue,user_id,refferalcode,username,accountdata,currentwallet } = constantSlice.actions;

export default constantSlice.reducer;






















