
import React from 'react';
import './entrypages.css';
import ozologo from "../../assets/img-ozo/logo-ozone-dark-284x85.png"
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { paymentaddress, user_id, refferalcode, username } from '../../reducers/ConstantSlice'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { apiURI } from "./../../config/config"
import Cookies from 'js-cookie'

import Web3 from 'web3'; 

function Login({ setauthenticated, setadmin, setuser }) {
   const dispatch = useDispatch();
   const navigate = useNavigate()
   const [emailId, setEmailID] = useState("")
   const [password, setPassword] = useState("")
   const [passwordType, setPasswordType] = useState("password");
   const myVariable = process.env.REACT_APP_POWER_KEY;
   // console.log("prcoess",myVariable)
   //toggle view password
   const togglePassword = () => {
      if (passwordType === "password") {
         setPasswordType("text")
         return;
      }
      setPasswordType("password")
   }

   //navigations
   const navigatetosignup = () => {
      navigate('/signup');
   }
   const navigatetoforrgotpassword = () => {
      navigate('/forgotpassword');
   }

   //check user details are suitable
   const checkUser = () => {
      console.log("username & pass: ", emailId, password)
      let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = pattern.test(emailId);
      if (emailId !== undefined && emailId != null && typeof (password) == 'string' && emailId !== '' && result === true && password !== '' && password !== undefined && password != null) {
         authenticateUser()
      }
      else {
         toast.warn("please enter valid details", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
         });
      }
   }


//authenticate called once details collected
   const authenticateUser = async () => {
      try {
         await fetch(apiURI.URL + "signin/", {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               // 'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {

                  "email": emailId,
                  "password": password

               }
            )
         })
            .then(response => response.json())
            .then(data => {
               console.log("response login", data?.message)
               if (data?.message === "Invalid username or password" || data?.message === "check the parameters" || data?.message === "An error occurred while processing the request" || data?.message === "Invalid email or password") {
                  toast.error("Invalid credentials", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                  });
               }
               else if (data?.message === "Your email is not verified yet") {
                  toast.warn("Account is not activated, check your mail and activate the account", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                  });
               }

               else {
                  localStorage.setItem('token', data?.token);
                  setauthenticated(true);
                  dispatch(user_id(data?.user_id));
                  
                  Cookies.set('session', JSON.stringify({ userId: data?.user_id, adminstatus: data?.is_admin }));
              

                  dispatch(username(data?.first_name));
                  dispatch(refferalcode(data?.referral_code));

                  if (data?.is_admin) {
                     setuser(false);
                     setadmin(true);
                     Cookies.set('type', "user");
                     navigate('/dashboard-admin');
                  }
                  else {
                     setuser(true);
                     setadmin(false);
                     Cookies.set('type', "user");
                     localStorage.setItem('walletaddress', "");
                     navigate('/dashboard');
                  }
               }
            }

            )
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
   }
   let ozo=12.01;
 
   var roundedValue = Math.ceil(ozo);
console.log(roundedValue,"rounded value "); // Output: 12


const connectWallet = async () => {
   if (window.ethereum) {
     try {
       // Request access to the user's MetaMask account
       await window.ethereum.request({ method: 'eth_requestAccounts' });

       // Create a new web3 instance
       const web3 = new Web3(window.ethereum);

       // Get the selected account address
       const accounts = await web3.eth.getAccounts();
       console.log(accounts[0])
      //  if(accounts[0]!==null&&accounts[0]!==undefined)
      //  {
         localStorage.setItem('metamaskaddress', accounts[0]);
         if(localStorage.getItem("link")!=="")
         {
            window.open(localStorage.getItem("link"), '_blank');
         }
         
         Cookies.set('type', "guest");
         navigate("/dashboard")
         localStorage.setItem("link","")

       

       
     } catch (error) {
       alert('Error connecting to wallet:', error);
     }
   } else {
     alert('Please install MetaMask to connect your wallet.');
   }
 };

   return (

      <div className="page-ath-wrap">
         <div className="page-ath-content">
            <div className="page-ath-header"><a href="/" className="page-ath-logo"><img src={ozologo} alt="logo"></img></a></div>
            <div className="page-ath-form">
               <h2 className="page-ath-heading">Sign in </h2>
               <div className="input-item"><input type="text" placeholder="Your Email" onChange={(e) => setEmailID(e.target.value)} className="input-bordered"></input></div>
               <div className="input-item cs">
                  <input type={passwordType} placeholder="Password" onChange={(e) => setPassword(e.target.value)} className="input-bordered"></input>
                  <div className="input-group-btn">
                     <button onClick={togglePassword} style={{ height: '100%', borderRadius: '0 4px 4px 0', width: "50px", padding: "3px", backgroundColor: "transparent", border: "solid grey 1px" }}>
                        {passwordType === "password" ? <IconEyeOff /> : <IconEye />}
                     </button>
                  </div>
               </div>
               <div className="d-flex justify-content-between align-items-center cs">
                  <div>
                     <p style={{ cursor: "pointer" }} onClick={() => navigatetoforrgotpassword()}>Forgot password?</p>
                     <div className="gaps-2x"></div>
                  </div>
               </div>
               <div className='d-flex align-items-end'>
               <button className="btn btn-primary btn-block " onClick={() => checkUser()}>Sign In</button>
               <button className="btn btn-primary btn-block" style={{marginLeft:"10px"}} onClick={() => connectWallet()}>Sign In with  Metamask</button>
               </div>
               <div className="gaps-2x"></div>
               <div className="gaps-2x"></div>
               <div className="form-note">Don’t have an account? <a href="#" style={{ cursor: "pointer" }} onClick={() => navigatetosignup()}> <strong>Sign up </strong></a></div>
            </div>
            <div className="page-ath-footer">
               <ul className="footer-links">
                  <li>&copy; 2023 Ozone Chain Labs.</li>
               </ul>
            </div>
         </div>
         <div className="page-ath-gfx">
            <div className="w-100 d-flex justify-content-center">
            </div>
         </div>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
         <ToastContainer />
      </div>

   );
}

export default Login;
