
import React from 'react';





function Vestingwrapperadmin() {



  return (
    <main className="page-content-admin">
      <div className="card">
        <div className="card-body">
          <div className="card-head">
            <div className='mb-2' style={{textAlign:"center"}}>
              <h4 className="card-title mb-2">Vesting</h4>
              
            </div>
          </div>


          <div className='row justify-content-center mb-2'>
            <div className='col-xl-2 col-lg-2 col-md-12 col-sm-12'>
              <label>TGE Date:</label>
              <input type="date" className="form-control"></input>
            </div>
            <div className='col-xl-2 col-lg-2 col-md-12 col-sm-12 pr-0'>
              <button className='btn btn-primary mb-2' style={{ float: "left",marginTop:"20px" }}>Start Vesting</button>
            </div>
          </div>
<div style={{border:"solid 1px green",borderRadius:"8px",padding:"20px"}}>



          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <div className="form-group row mb-2">
                <label for="hEmail" className="col-xl-4 col-sm-3 col-sm-2 col-form-label lab t">Select Round</label>
                <div className="col-xl-8 col-lg-8 col-sm-10">
                  <select className="form-control" >
                    <option selected>Choose...</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>


              <div className="form-group row mb-2">
                <label for="hEmail" className="col-xl-4 col-sm-12 col-form-label lab">Start Date:</label>
                <div className="col-xl-8 col-lg-8 col-sm-10">
                 <p>11/04/2023</p>
                </div>
              </div>

              <div className="form-group row mb-2">
                <label for="hEmail" className="col-xl-4 col-sm-12 col-form-label lab">End Date:</label>
                <div className="col-xl-8 col-lg-8 col-sm-10">
                <p>11/04/2023</p>
                </div>
              </div>
              <div className="form-group row mb-2">
                <label for="hEmail" className="col-xl-4 col-sm-12 col-form-label lab">Cliff (Months)</label>
                <div className="col-xl-8 col-lg-8 col-sm-10">
                  <span>2</span>
                </div>
              </div>




            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <div className="form-group row mb-2">
                <label for="hEmail" className="col-xl-4 col-sm-12 col-form-label lab">TGE (%)</label>
                <div className="col-xl-8 col-lg-8< col-sm-10">
                  <span>10</span>
                </div>
              </div>
              <div className="form-group row mb-2">
                <label for="hEmail" className="col-xl-4 col-sm-12 col-form-label lab">TGE (OZO)</label>
                <div className="col-xl-8 col-lg-8< col-sm-10">
                  <span>25</span>
                </div>
              </div>

              <div className="form-group row mb-2">
                <label for="hEmail" className="col-xl-4 col-sm-12 col-form-label lab">Initial Market Cap ($)</label>
                <div className="col-xl-8 col-lg-8< col-sm-10">
                  <span>50000</span>
                </div>
              </div>

              <div className="form-group row mb-2">
                <label for="hEmail" className="col-xl-4 col-sm-12 col-form-label lab">Vesting (Daily)</label>
                <div className="col-xl-8 col-lg-8< col-sm-10">
                  <span>2</span>
                </div>
              </div>
            </div>



          </div>
          </div>
          

          <div className='row'>
            <div className="table-responsive mt-3">
   <table className="table align-middle">
                 <thead className="table-secondary">
                   <tr>
{/*                   
                    <th className="th">S.No</th> */}
                    <th className="th">Round</th>
                    <th className="th">Released OZO</th>
                    <th className="th">Unreleased OZO</th>
                    <th className="th">Days Completed</th>
                    <th className="th">Days Left</th>
                 
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
             
                    <td>Angel</td>
                    <td> 10</td>
                    <td>5</td>
                    <td>50</td>
                    <td>20</td>
                  
                   </tr>
                   <tr>
                  
                   <td>Presale 1</td>
                    <td> 50</td>
                    <td>4</td>
                    <td>200</td>
                    <td>20</td>
                    
                   </tr>
                   <tr>
                
                   <td>Presale 2</td>
                    <td> 30</td>
                    <td>3</td>
                    <td>90</td>
                    <td>20</td>
                  
                   </tr>
                   <tr>
            
                   <td>Presale 3</td>
                    <td> 80</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  
                   </tr>


                 </tbody>
               </table>
            </div>
          </div>
        </div>
      </div>
    </main>



  );
}

export default Vestingwrapperadmin;
