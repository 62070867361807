
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Transactionpay from './transactionpay';
import Transactionsrow from './transactionrow';
import Transactioview from './transactionview';
import { useSelector, useDispatch } from 'react-redux';
import { apiURI } from "./../../config/config"
import Cookies from 'js-cookie'

function Transactionswrapper() {

   const [show, setshow] = useState(false)
   const [deletePopup, setdeletePopup] = useState(false)

   const [allTransaction, setallTransaction] = useState()
   const [viewTransactiono, setviewTransactiono] = useState()
   const [transactionHash, settransactionHash] = useState("")

   const [currentDelete, setcurrentDelete] = useState("")
   const user_id = useSelector((state) => state.constVar.user_id)
   console.log(user_id, "user specific transaction")

   //paypopups
   const handleClose = () => {
      setshow(false)
   }
   const handleCloseDelete = () => {
      setdeletePopup(false)
   }



   const showDelete = (transaction_no) => {
      setcurrentDelete(transaction_no)
      setdeletePopup(true)
   }
   const deleteTransaction = async () => {
      console.log(currentDelete, "deleted successfully")
      setdeletePopup(false)

      try {
         await fetch(apiURI.URL+'user_transaction/', {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  "transaction_no": currentDelete,

               }
            )
         })
            .then(response => response.json())
            .then(data => {

               if(data.message==="Transaction deleted successfully"){
                  alert("transaction deleted successfully")
                  getTransactions();
               }

            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }

   }





   //view transactions
   const viewTransaction = (transaction_no) => {

      setviewTransactiono(transaction_no);
      setshow(true)
   }


   const getTransactions = () => {
      try {
         fetch(apiURI.URL + 'get_all_user_transaction/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  "user_id": user_id,

               }
            )
         })
            .then(response => response.json())
            .then(data => {
               setallTransaction(data)
            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
   }

   useEffect(() => {
      getTransactions();
   }, [])






   return (

      <div className="page-content tr">
         <div className="container">
            <div className="card content-area">
               <div className="card-innr">
                  <div className="card-head" style={{ display: "flex", justifyContent: "space-between" }}>
                     <h4 className="card-title">Transactions</h4>
                     <button style={{ border: "0px solid", borderRadius: "5px", backgroundColor: "yellowgreen", color: "white", fontWeight: "600", cursor: "pointer" }} onClick={getTransactions}>Refresh</button>
                  </div>

                  <div style={{ width: "100%", overflowX: "scroll", height: "70vh", overflowY: "scroll" }}>
                     <table className="data-table dt-init user-tnx" >
                        <thead>
                           <tr className="data-item data-head">
                              <th className="data-col dt-tnxno">Tranx NO</th>
                              <th className="data-col dt-usd-amount">Funding&nbsp;&nbsp;</th>
                              <th className="data-col dt-token">Coins&nbsp;</th>

                              <th className="data-col dt-usd-amount">Payment Req</th>
                              <th className="data-col dt-account"> Payment done</th>
                              <th className="data-col dt-account">Receiving Wallet Address</th>
                              <th className="data-col dt-type">
                                 <div className="dt-type-text">Type</div>
                              </th>

                              <th className="data-col dt-account">STATUS</th>
                              <th className="data-col"></th>
                              <th className="data-col">Actions</th>

                           </tr>
                        </thead>
                        <tbody >
                           {
                              allTransaction?.length > 0 &&
                              allTransaction?.map((i, index) => (

                                 <Transactionsrow
                                    i={i}
                                    index={index}
                                    viewTransaction={viewTransaction}
                                    showDelete={showDelete}
                                 >
                                 </Transactionsrow>
                              ))
                           }



                        </tbody>
                     </table>
                  </div>

                  <Transactioview
                     show={show}
                     handleClose={handleClose}
                     allTransaction={allTransaction}
                     viewTransactiono={viewTransactiono}
                  ></Transactioview>



                  {allTransaction?.length === 0 ?
                     <h1 style={{ textAlign: "center", marginTop: "30px", marginBottom: "30px", color: "#b1b0b0" }}>No Transactions</h1> : ""

                  }
               </div>
            </div>
         </div>
         

         <Modal show={deletePopup} onHide={handleCloseDelete}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body>
               <div style={{ padding: "20px", justifyContent: "center", textAlign: "center" }}>
                  <p style={{ fontSize: "17px" }}>Are you sure you want to cancel this transaction?
                  </p>
                  <br></br>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                     <button className="btn btn-primary" onClick={() => deleteTransaction()} style={{ width: "80px" }}>Yes</button>
                     <button className="btn btn-primary ml-2" onClick={() => setdeletePopup(false)} style={{ width: "80px" }}>No</button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
      </div>


   );
}

export default Transactionswrapper;
