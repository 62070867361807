import React from 'react';
import './entrypages.css';
import ozologo from "../../assets/img-ozo/logo-ozone-dark-284x85.png"
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import loading from "./loading.gif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import animationData from "./dots.json"
import Lottie from 'react-lottie';
import { apiURI } from "./../../config/config"

function Signup() {
   const [firstName, setfirstName] = useState("")
   const [firstNameerr, setfirstNameerr] = useState(false)
   const [lastName, setlastName] = useState("")
   const [lastNameerr, setlastNameerr] = useState(false)
   const [emailId, setemailId] = useState("")
   const [emailIderr, setemailIderr] = useState(false)
   const [password, setPassword] = useState("")
   const [retypepass, setretypepass] = useState("")
   const [inviteId, setInviteId] = useState("")
   const [passworderr, setpassworderr] = useState(false)
   const [passwordmiss, setpasswordmiss] = useState(false)
   const [show, setShow] = useState(false);
   const [termsChecked, setTermsChecked] = useState(false)
   const [passwordType, setPasswordType] = useState("password");
   const [passwordTypecn, setPasswordTypecn] = useState("password");
   const [showpending, setShowpending] = useState(false);
   const navigate = useNavigate()

   //variable for lottie animation
   const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
         preserveAspectRatio: "xMidYMid slice"
      }
   };


   //close popup
   const handleClose = () => setShow(false);

   //password view toggle
   const togglePassword = () => {
      if (passwordType === "password") {
         setPasswordType("text")
         return;
      }
      setPasswordType("password")
   }
   const togglePasswordcn = () => {
      if (passwordTypecn === "password") {
         setPasswordTypecn("text")
         return;
      }
      setPasswordTypecn("password")
   }

   //agree to terms
   const termsFunc = () => {
      console.log("clicked")
      setTermsChecked(!termsChecked)
   }


   //verifying all details siutable to register
   const submit = () => {

      var patternjjj = new RegExp(
         /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
      );

      var firstLetter = patternjjj.test(password)
      let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = pattern.test(emailId);
      if (emailId !== '' && password !== '' && password === retypepass && password.length >= 8 && firstLetter === true && result === true && firstName !== "" && lastName !== "") {
         if (termsChecked) {
            authenticateUser();
         }
         else {

            toast.warn("Please agree to the privacy policy and terms", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
            });
         }
      }
      else if (firstLetter === false) {
         toast.warn("Password must have 8 character length of atleast one capital letter and one small letter with one or more special characters and numbers", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
         });
      }
      else if (result === false) {
         toast.warn("Email not valid", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
         });
      }
      if (firstName === "") {
         setfirstNameerr(true)
      }
      else {
         setfirstNameerr(false)
      }
      if (lastName === "") {
         setlastNameerr(true)
      }
      else {
         setlastNameerr(false)
      }
      if (emailId === "") {
         setemailIderr(true)
      }
      else {
         setemailIderr(false)
      }
      if (password === "") {
         setpassworderr(true)
      }
      else {
         setpassworderr(false)
      }

      if (retypepass === "") {
         setpassworderr(true)
      }
      else {
         setpassworderr(false);
      }

      if (password.length < 8) {
         // toast.warn("password must have caps letter of 8 character with special character and numbers", {
         //    position: "top-right",
         //    autoClose: 5000,
         //    hideProgressBar: false,
         //    closeOnClick: true,
         //    pauseOnHover: true,
         //    draggable: true,
         //    progress: undefined,
         //    theme: "light",
         //    });
      }
      if (password !== "" && retypepass !== "") {
         if (password !== retypepass) {
            setpasswordmiss(true)
         }
         else {
            setpasswordmiss(false)
         }
      }
      else {
         setpasswordmiss(false)
      }
   }


//authenticate called after all details are collected
   const authenticateUser = async () => {
      setShow(true);
      try {
         await fetch(apiURI.URL + "user_profile/", {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               // 'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {

                  "email": emailId,
                  "first_name": firstName,
                  "last_name": lastName,
                  "password": password,
                  "invited_by_code": inviteId
                  // "payment_wallet_address":"X0ferlkjk3kj3fjsk3kskdk3"

               }
            )
         })
            .then((response) => {

               const json = response.json();
               console.log("response data", json)
               return json;
            })
            .then(data => {
               setShow(false)
               console.log("response signup", data)
               if (data.message === "Email already exists") {
                  toast.warn("Email Id is already registered", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                  });
               }
               else if (data.message === "User created successfully, please check your email to activate your account") {
                  setShowpending(true)
               }

            }

            )
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
   }
   const navlogin = () => {
      setShowpending(false);
      navigate('/login');
   }

   // referel code implementation 
   useEffect(() => {
      const url = window.location.search;
      const urlParams = new URLSearchParams(url);
      console.log(urlParams, "urlParams");
      const inviteCode = urlParams.get('id');
      if (null != inviteCode) {
         setInviteId(inviteCode);
      }
      else {
         setInviteId("");
      }


   }, []);

   return (


      <div className="page-ath-wrap">
         <div className="page-ath-content">
            <div className="page-ath-header"><a href="/signup" className="page-ath-logo"><img src={ozologo} alt="logo"></img></a></div>
            <div className="page-ath-form">
               <h2 className="page-ath-heading">Sign up <small>Create New Ozone Chain Account</small></h2>
               {/* <form action="#"> */}
               <div className="input-item"><input type="text" placeholder="First Name" className="input-bordered" value={firstName} onChange={(e) => setfirstName(e.target.value)} tabIndex="1"></input>
                  {firstNameerr ? <p style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>Please fill first Name</p> : <></>}
               </div>
               <div className="input-item"><input type="text" placeholder="Last Name" className="input-bordered" value={lastName} onChange={(e) => setlastName(e.target.value)} tabIndex="2"></input>
                  {lastNameerr ? <p style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>Please fill Last Name</p> : <></>}
               </div>
               <div className="input-item"><input type="text" placeholder="Your Email" className="input-bordered" value={emailId} onChange={(e) => setemailId(e.target.value)} tabIndex="3"></input>
                  {emailIderr ? <p style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>Please fill Email Id</p> : <></>}
               </div>
               <div className="input-item cs"><input type={passwordType} placeholder="Password" className="input-bordered" value={password} onChange={(e) => setPassword(e.target.value)} tabIndex="4"></input>
                  <div className="input-group-btn">
                     <button className="" onClick={togglePassword} style={{ height: '100%', borderRadius: '0 4px 4px 0', width: "50px", padding: "3px", backgroundColor: "transparent", border: "solid grey 1px" }}>
                        {passwordType === "password" ? <IconEyeOff /> : <IconEye />}
                     </button>
                  </div>
               </div>
               <div className="input-item cs"><input type={passwordTypecn} placeholder="Confirm Password" className="input-bordered" value={retypepass} onChange={(e) => setretypepass(e.target.value)} tabIndex="5"></input>
                  <div className="input-group-btn">
                     <button onClick={togglePasswordcn} style={{ height: '100%', borderRadius: '0 4px 4px 0', width: "50px", padding: "3px", backgroundColor: "transparent", border: "solid grey 1px" }}>
                        {passwordTypecn === "password" ? <IconEyeOff /> : <IconEye />}
                     </button>
                  </div>
               </div>
               {passworderr ? <p style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>Please fill both password</p> : <></>}
               {passwordmiss ? <p style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>Password does not match with confirm password</p> : <></>}
               <div className="input-item text-left" onClick={termsFunc}>
                  <input className="input-checkbox input-checkbox-md" type="checkbox" checked={termsChecked} ></input>

                  <label for="term-condition">I agree to Ozone Chain's <a style={{ color: "#045193" }} href="https://ozonechain.io/privacy-policy/">Privacy Policy</a> &amp; <a style={{ color: "#045193" }} href="https://ozonechain.io/terms-of-use/"> Terms.</a>
                  </label>
               </div>
               <button className="btn btn-primary btn-block" onClick={submit}>Create Account</button>
               <div className="gaps-2x"></div>
               <div className="gaps-2x"></div>
               <div className="form-note">Already have an account ? <Link to="/login"><strong>Sign in </strong></Link></div>
            </div>
            <div className="page-ath-footer">
               <ul className="footer-links">
                  <li>&copy; 2023 Ozone Chain Labs.</li>
               </ul>
            </div>
         </div>
         <div className="page-ath-gfx">
            <div className="w-100 d-flex justify-content-center">
            </div>
         </div>
         <div style={{ position: "fixed", padding: "100px", display: show === true ? "flex" : "none", justifyContent: "center", width: "100%", height: "100%", backgroundColor: "#80808063" }}>
            <Lottie
               options={defaultOptions}
               height={300}
               width={300}
               style={{ marginTop: "10%" }}/>
         </div>


         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
         <ToastContainer />

         <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showpending}
            onHide={handleClose}>
            <Modal.Body>
               <div style={{ padding: "10px 30px", justifyContent: "center", textAlign: "center" }}>
                  <p style={{ fontSize: "17px" }}>Registered Successfully ! Proceed to Login</p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                     <button className="btn btn-primary mr-4 " style={{ width: "100px" }} onClick={() => navlogin()}>OK</button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>

      </div>

   );
}

export default Signup;
