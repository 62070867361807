import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { apiURI } from "../../../config/config";
import Modal from "react-bootstrap/Modal";
import Transactionadminrow from "./transactionadminrow";
import Ordercomponent from "./ordercomponent";
import Web3 from "web3";
import USDTabi from "./USDTabi.json";
import Lottie from "react-lottie";
import animationData from "./dots.json";
import * as XLSX from "xlsx";
import "./transaction-admin.css";

function Transactionwrapperadmin() {
  const [transactiondata, setTransactiondata] = useState();
  const [showpending, setShowpending] = useState(false);
  const [currentTransactionno, setcurrentTransactionno] = useState();
  const [transactionSearch, settransactionSearch] = useState();
  const [Transactionhash, setTransactionhash] = useState("");
  const [hash, sethash] = useState(false);
  const [showload, setShowload] = useState(false);
  const handleClose = () => setShowpending(false);
  const [searchValue, setsearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
  const [typeFilter, setTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [currentTab, setcurrentTab] = useState("paid");
  const [pageno, setpageno] = useState(1);

  //excel
  function convertToWorksheet(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    setShowload(false);
    return worksheet;
  }

  function downloadExcel() {
    setShowload(true);
    console.log("clicked");
    const worksheet = convertToWorksheet(transactionSearch);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const sortByType = () => {
    const sortedData = [...transactionSearch].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.transaction_type.localeCompare(b.transaction_type);
      } else {
        return b.transaction_type.localeCompare(a.transaction_type);
      }
    });
    setTransactiondata(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortByStatus = () => {
    const sortedData = [...transactionSearch].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.status.localeCompare(b.status);
      } else {
        return b.status.localeCompare(a.status);
      }
    });
    setTransactiondata(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const applyFilters = (typeFilter, statusFilter) => {
    let newFilteredData = transactionSearch;

    if (typeFilter !== "") {
      newFilteredData = newFilteredData.filter(
        (data) =>
          data.transaction_type.toLowerCase() === typeFilter.toLowerCase()
      );
    }

    if (statusFilter !== "") {
      newFilteredData = newFilteredData.filter(
        (data) => data.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    setTransactiondata(newFilteredData);
    setTypeFilter(typeFilter);
    setStatusFilter(statusFilter);
  };

  const showOrder = (transactionno) => {
    setcurrentTransactionno(transactionno);
    console.log(transactionno, "transaction");
    setShowpending(true);
  };

  //variable for lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const submitTransaction = async (tn, toAdd, ozo) => {
    console.log("transaction hash", Transactionhash, tn, toAdd, ozo.toFixed(0));
    console.log("verifypayment called !!");

    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://node1.ozonechain.io")
    );

    const txHash = Transactionhash;

    await web3.eth.getTransaction(txHash, (error, tx) => {
      if (error) {
        console.log(error);
        alert(error);
      } else {
        console.log("Transaction Details:", tx);
        console.log("\n\n From :", tx.from);
        console.log("\n\n To :", tx.to);
        console.log(
          "\n\n Transaction value:",
          web3.utils.fromWei(tx.value, "ether")
        );
        let ozostringdec = ozo.toFixed(2);

        if (toAdd.toLowerCase() == tx.to.toLowerCase()) {
          console.log("address pass");
          // if(ozostringdec.toString()===web3.utils.fromWei(tx.value, 'ether')|| ozo.toString()===web3.utils.fromWei(tx.value, 'ether')||ozo===web3.utils.fromWei(tx.value, 'ether')||ozo.toFixed(2)===web3.utils.fromWei(tx.value, 'ether'))
          if (
            Math.ceil(ozo).toFixed(0) ===
            Math.ceil(
              parseFloat(web3.utils.fromWei(tx.value, "ether"))
            ).toFixed(0)
          ) {
            alert("transaction closed");
            updatePayment(tn);
          } else {
            alert("The transferred amount is not matching with the records.");
          }
        } else {
          alert("Invalid Hash");
        }
      }
    });
  };

  const updatePayment = (tn) => {
    console.log("tn", tn);
    setShow(true);
    try {
      fetch(apiURI.URL + "user_transaction/", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          transaction_no: tn,
          transaction_hash: Transactionhash,
        }),
      })
        .then((response) => {
          const json = response.json();
          console.log("response data", json);
          return json;
        })

        .then((data) => {
          setShow(false);
          console.log(data);
          if (data?.message === "tx_hash updated successfully") {
            alert("Transaction closed successfully");
            setpageno(1);
            getAdminTransaction();
            setShowpending(false);
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error, "error");
    }
  };

  const paidTab = () => {
    setcurrentTab("paid");
    setpageno(1);
  };

  const pendingTab = () => {
    setcurrentTab("pending");
    setpageno(1);
  };

  const transferredTab = () => {
    setcurrentTab("transferred");
    setpageno(1);
  };



  const getAdminTransaction = () => {
    setShow(true);
    try {
      fetch(apiURI.URL + "get_transaction_list/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(
          {
            "status": currentTab,
            "page": pageno,
            "limit": "100"
          }
        )

      })
        .then((response) => response.json())
        .then((data) => {
          console.log("transaction admin data", data);
          setShow(false);
          const filteredData = data?.transactions?.filter(
            (item) => item.status !== "EXPIRED"
          );
          
          setTransactiondata(data);
          settransactionSearch(data);

        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getAdminTransaction();
  }, []);

  // useEffect(() => {
  //   console.log(searchValue, "searchValue")
  //   if (transactionSearch !== undefined) {
  //     const searchResults = transactionSearch.filter((obj) => {
  //       // Loop through all properties of the object
  //       for (const property in obj) {
  //         // Check if the property value is a string and includes the search value (case-insensitive)
  //         if (typeof obj[property] === 'string' && obj[property].toLowerCase().includes(searchValue.toLowerCase())) {
  //           return true; // Return true if the search value is found
  //         }
  //       }
  //       return false; // Return false if the search value is not found
  //     });
  //     setTransactiondata(searchResults)
  //     console.log("filter array", searchResults)
  //   }

  // }, [searchValue])

  useEffect(() => {
    getAdminTransaction();
  }, [currentTab, pageno]);

  // var a = Array.fill[10];

  return (
    <main className="page-content-admin">
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div>
          <button
            className="btn btn-sm btn-success px-5 mb-2"
            style={{ float: "right" }}
            onClick={getAdminTransaction}
          >
            Refresh
          </button>
        </div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt"></i>
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div
            className="d-flex align-items-center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5 className="mb-0 mr-2">Transactions Details</h5>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => {
                  downloadExcel();
                }}
              >
                <i className="bi bi-file-earmark-excel-fill"></i> Export as
                Excel
              </button>
            </div>

            {/* <label>Type :</label>
            <form onChange={(e) => applyFilters(e.target.value, statusFilter)}>
            <select className="minimal" style={{border:"solid 1px grey",borderRadius:"5px"}} >
                <option selected value="">All</option>
                <option value="purchase">Purchase</option>
                <option value="referral">Referral</option>

              </select>
            </form>


            <label>Status :</label> */}

            {/* <form onChange={(e) => applyFilters(typeFilter, e.target.value)}>
              <select className="minimal" style={{border:"solid 1px grey",borderRadius:"5px"}} >
                <option selected value="">All</option>
                <option value="paid">Paid</option>
                <option value="pending">Pending</option>
                <option value="transferred">Transferred</option> */}
            {/* <option value="expired">Expired</option> */}

            {/* </select>
            </form> */}

            {/* 
            <span className="" >

              <div id="example2_filter" className="" style={{ display: "flex", justifyContent: "space-between" }}>
                <label>Search:
                  <input type="search" className="form-control form-control-sm" placeholder="" value={searchValue} onChange={(e) => setsearchValue(e.target.value)}>
                  </input>
                </label>
              </div>

            </span> */}
          </div>
          <ul className="nav nav-pills mb-3 mt-3">
            <li className="nav-item">
              <a
                className={
                  currentTab === "paid" ? "nav-link active" : "nav-link"
                }
                onClick={() => paidTab()}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex align-items-center">
                  <div className="tab-icon">
                    <i className="bx bx-home font-18 me-1"></i>
                  </div>
                  <div className="tab-title">PAID</div>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  currentTab === "pending" ? "nav-link active" : "nav-link"
                }
                onClick={() => pendingTab()}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex align-items-center">
                  <div className="tab-icon">
                    <i className="bx bx-user-pin font-18 me-1"></i>
                  </div>
                  <div className="tab-title">PENDING</div>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  currentTab === "transferred" ? "nav-link active" : "nav-link"
                }
                onClick={() => transferredTab()}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex align-items-center">
                  <div className="tab-icon">
                    <i className="bx bx-microphone font-18 me-1"></i>
                  </div>
                  <div className="tab-title">TRANSFERRED</div>
                </div>
              </a>
            </li>
            
          </ul>
          <div className="tab-content py-3" style={{ overflowX: "scroll" }}>
            <div className="tab-pane fade active show">
              <table className="table align-middle">
                <thead className="table-secondary">
                  <tr>
                    <th>Transaction IDf </th>
                    <th>Order Date</th>
                    <th>Funding Type</th>
                    <th onClick={sortByType} style={{ cursor: "pointer" }}>
                      Type{" "}
                      <i
                        className="bi bi-sort-alpha-down ml-2"
                        style={{
                          fontSize: "16px",
                          boxShadow: "1px 1px 2px grey",
                          borderRadius: "3px",
                          background: "white",
                        }}
                      ></i>
                    </th>
                    <th>Coins</th>
                    <th> Payment Requested ($)</th>
                    <th> Payment Completed ($)</th>
                    <th>To Address</th>
                    <th onClick={sortByStatus} style={{ cursor: "pointer" }}>
                      Status{" "}
                      <i
                        className="bi bi-sort-alpha-down ml-2"
                        style={{
                          fontSize: "16px",
                          boxShadow: "1px 1px 2px grey",
                          borderRadius: "3px",
                          background: "white",
                        }}
                      ></i>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {transactiondata?.transactions?.length > 0 &&
                    transactiondata?.transactions?.map((i, index) => (
                      <Transactionadminrow
                        i={i}
                        index={index}
                        showOrder={showOrder}
                      ></Transactionadminrow>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div class="card-body">
              <nav aria-label="Page navigation example">
                <ul class="pagination" style={{ float: "right" }}>
                  <li class="page-item">
                    <a
                      class="page-link"
                      href="javascript:;"
                      onClick={
                        transactiondata?.has_previous_page
                          ? () => setpageno(pageno - 1)
                          : () => setpageno(pageno)
                      }
                    >
                      Previous
                    </a>
                  </li>
                  {/* {
                 
                    transactiondata?.transactions?.map((i, index) => (
                      <Transactionadminrow i={i} index={index} showOrder={showOrder}></Transactionadminrow>
                    ))
                  } */}
                  {
                    // console.log(a,"pages")
                    Array(transactiondata?.num_pages)
                      .fill()
                      .map((i, index) => (
                        <li class="page-item">
                          <button
                            class="page-link"
                            onClick={() => setpageno(index + 1)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))
                  }

                  {/* <li class="page-item"><a class="page-link" href="javascript:;">2</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="javascript:;">3</a>
                        </li> */}

                  <li class="page-item">
                    <a
                      class="page-link"
                      href="javascript:;"
                      onClick={
                        transactiondata?.has_next_page
                          ? () => setpageno(pageno + 1)
                          : () => setpageno(pageno)
                      }
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
              <p>
                Page No {pageno} of {transactiondata?.num_pages}
              </p>
            </div>
          </div>
        </div>
        <Ordercomponent
          showpending={showpending}
          handleClose={handleClose}
          currentTransactionno={currentTransactionno}
          transactiondata={transactiondata?.transactions}
          setTransactionhash={setTransactionhash}
          submitTransaction={submitTransaction}
          Transactionhash={Transactionhash}
        ></Ordercomponent>
        <div
          style={{
            position: "fixed",
            padding: "100px",
            display: show === true ? "flex" : "none",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "#80808063",
          }}
        >
          <Lottie
            options={defaultOptions}
            height={300}
            width={300}
            style={{ marginTop: "10%" }}
          />
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          padding: "100px",
          display: showload === true ? "flex" : "none",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          backgroundColor: "#80808063",
          top: "0px",
          left: "0px",
          margin: "0px",
        }}
      >
        <Lottie
          options={defaultOptions}
          height={300}
          width={300}
          style={{ marginTop: "10%" }}
        />
      </div>
    </main>
  );
}

export default Transactionwrapperadmin;
