
import React from 'react';

import Topbar from "../topbar"
import Navbar from "../navbar"
import "./vesting-admin.css"
// import 'bootstrap/dist/css/bootstrap.css';

import { useState, useEffect } from 'react';
import Vestingwrapperadmin from './vestingwrapperadmin';



function Vestingadmin() {

   const [style, setstyle] = useState('');
   const [displaymobnav, setdisplaymobnav] = useState('');
   const [viewportWidth, setViewportWidth] = useState(window.innerWidth);



   useEffect(() => {
      const handleResize = () => setViewportWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   useEffect(() => {
      if (viewportWidth < 1024) {
  setstyle("-300px")
      }
      else {
         setstyle("0px")
      }


   }, [viewportWidth]);
   const handleButtonClick = () => {
      console.log("clicked")
      if (viewportWidth < 1024) {
         console.log("check if")

         setstyle(prevStyle => prevStyle === "-300px"? "0px" : "-300px")

      }
   };


   return (
   
 
         <div className="wrapper">
            <Topbar handleButtonClick={handleButtonClick}></Topbar>
            <Navbar setstyle={setstyle}
            style={style}
            handleButtonClick={handleButtonClick}
            ></Navbar>
            <Vestingwrapperadmin></Vestingwrapperadmin>

         </div>
    

   );
}

export default Vestingadmin;
