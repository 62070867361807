
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Walletdet from './walletdet';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Web3 from "web3"
// var WAValidator = require('multicoin-address-validator');
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiURI } from "./../../../config/config"
import Cookies from 'js-cookie'

function Wallet({ getWallet, allWalletDetails, walletAddress, setwalletAddress }) {

   const [showaddwallet, setshowaddwallet] = useState(false);
   const [showeditWallet, setshoweditWallet] = useState(false)


   const user_id = useSelector((state) => state.constVar.user_id)



   const handleclosewallet = () => {

      setshowaddwallet(false);
      setwalletAddress("");
   }
   const handleAdd = () => {
      addWallet();
      handleclosewallet();
   }

   const handleshowwallet = () => {
      setshowaddwallet(true);
   }

   const handlecloseeditwallet = () => setshoweditWallet(false);
   const handleshoweditwallet = () => setshoweditWallet(true);

   const handleWalletAddress = (e) => {
      console.log(e.target.value, "wallet address")
      setwalletAddress(e.target.value);
   }

   const addWallet = async () => {
      //       var valid = WAValidator.validate(walletAddress);
      // if(valid)
      //     alert('This is a valid address');
      // else
      //     alert('Address INVALID');

      const web3 = new Web3('https://mainnet.infura.io/v3/your-project-id');
      const address = walletAddress; // replace with the address to check
      var existwallet = false;
      for (let i = 0; i < allWalletDetails.length; i++) {
         if (walletAddress === allWalletDetails[i]?.wallet_address) {
            console.log(allWalletDetails[0]?.wallet_address, "inside loop")
            existwallet = true;
         }
      }

      if (web3.utils.isAddress(address)) {

         console.log('Valid Ethereum wallet address');
         console.log("exist wallet", existwallet)

         if (Cookies.get('type') === "user") {


            if (existwallet === false) {
               try {
                  await fetch(apiURI.URL + 'wallet_address/', {
                     method: 'POST',
                     headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
                     },
                     body: JSON.stringify(
                        {
                           "user_id": user_id,
                           "wallet_address": walletAddress,
                           "current": allWalletDetails.length > 0 ? false : true,
                        }
                     )
                  })
                     .then(response => response.json())
                     .then(data => {
                        if (data?.message == "Wallet Address already exists") {
                           // alert("This wallet has already been added")
                           toast.warn("This wallet has already been added", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                           });
                        }
                        else if (data?.message === "failed to add") {
                           toast.error("Wallet is already added to another account", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                           });
                        }
                        else {
                           // alert("Wallet added successfully")
                           toast.success("Wallet added successfully", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                           });
                        }
                        console.log(data)
                     })
                     .catch(error => console.error(error));
               }
               catch (error) {
                  console.log(error, "error");
               }
               getWallet();

            }




            else {
               toast.warn("This wallet has already been added", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
               });
            }
         }
         else if (Cookies.get('type') === "guest") {

            localStorage.setItem('walletaddress', walletAddress);
            toast.success("wallet added successfully", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
            });

         }

      }
      else {
         // alert("Incorrect wallet address")
         toast.error("Incorrect wallet address", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
         });
      }

      console.log(walletAddress, "wallet adres")



   }




   const updateWallet = async (currentWalletadd) => {
      console.log(walletAddress, "wallet adres")
      try {
         await fetch(apiURI.URL + 'wallet_address/', {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  "user_id": user_id,
                  "wallet_address": currentWalletadd,
                  "active": true,
                  "current": true,
               }
            )
         })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
      getWallet();

   }


   const deleteWallet = async (currentWalletadd) => {
      console.log(walletAddress, "wallet adres")
      try {
         await fetch(apiURI.URL + 'wallet_address/', {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  "user_id": user_id,
                  "wallet_address": currentWalletadd,
               }
            )
         })
            .then(response => response.json())
            .then(data => {

               console.log(data)
               if (data?.message === "record deleted successfully")
                  toast.success("record deleted successfully", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                  });

            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
      getWallet();
   }






   return (




      <div className="tab-pane show active" style={{ overflowX: "scroll" }}>
         <div className="pdb-1-5x for-wallet-btn">
            <h5 className="card-title card-title-sm text-dark">Wallets</h5><span className="new-wallet">
               <button className="btn btn-primary new-wallet-btn" onClick={handleshowwallet}>Add New Wallet</button>
               {/* <button  className="btn btn-primary new-wallet-btn ml-2" onClick={getWallet}>get Wallet</button> */}
            </span>
         </div>

         <table className="data-table dt-init user-list" style={{ textAlign: "center",border:"solid 1px lightgrey",padding:"10px" }}>


            <thead>
               <tr className="data-item data-head" style={{border:"1px solid lightgrey",padding:"10px"}}>
                  <th className="data-col dt-user" style={{padding:"10px"}}>S.No</th>
                  <th className="data-col dt-email">Wallets</th>
                  <th className="data-col"><em className="fas fa-info-circle text-exlight" data-toggle="tooltip" data-placement="bottom" title="select radio button to set receiving wallet address"></em></th>
               </tr>
            </thead>
            <tbody>
               {/* <form> */}
               {
                  allWalletDetails?.length > 0 && Cookies.get('type') === "user" &&
                  allWalletDetails?.map((i, index) => (

                     <Walletdet i={i}
                        index={index}
                        handleshoweditwallet={handleshoweditwallet}
                        deleteWallet={deleteWallet}
                        allWalletDetails={allWalletDetails}
                        updateWallet={updateWallet}
                     >

                     </Walletdet>
                  ))
               }
               {Cookies.get('type') === "guest"?
               <tr className="data-item" >
               <td className="data-col dt-user">{1}</td>
               <td className="data-col dt-user">{ localStorage.getItem("walletaddress")}</td>
               <td className="data-col text-right">
                  <ul className="data-action-list  d-md-inline-flex">
                  </ul>
               </td>
               </tr>:""
               }




               {/* </form> */}








            </tbody>
         </table>
         <Modal show={showaddwallet} onHide={handleclosewallet}>
            {/* <Modal.Header closeButton>

        </Modal.Header> */}
            <Modal.Body>



               <div className="modal-content">
                  <button className="modal-close" style={{ border: "0px", background: "#f0f8ff00" }} onClick={handleclosewallet}><em className="ti ti-close"></em></button>
                  <div className="popup-body">

              
                        <div className="row">

                        </div>

                        <div className="input-item input-with-label">
                           <label for="token-address" className="input-item-label">Your Address for tokens:</label>
                           <input className="input-bordered" type="text" value={walletAddress} onChange={(e) => handleWalletAddress(e)}></input>
                           <span className="input-note">Note:Add only ERC-20 compatible (Metamask or Trust Wallet) addresses. Exchange wallet addresses should not be added.</span></div>

                        <div className="gaps-3x"></div>
                        <div className="d-sm-flex justify-content-between align-items-center">
                           <Link to="/profile"><button className="btn btn-primary" onClick={handleAdd}>Add Wallet</button></Link>
                           <div className="gaps-2x d-sm-none"></div>

                        </div>
          

                  </div>
               </div>



            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer> */}
         </Modal>

         <Modal show={showeditWallet} onHide={handlecloseeditwallet}>
            {/* <Modal.Header closeButton>

        </Modal.Header> */}
            <Modal.Body>



               <div className="modal-content">
                  <button className="modal-close" style={{ border: "0px", background: "#f0f8ff00" }} onClick={handlecloseeditwallet}><em className="ti ti-close"></em></button>
                  <div className="popup-body">

                    
                        <div className="row">

                        </div>

                        <div className="input-item input-with-label"><label for="token-address" className="input-item-label">Edit Your Address</label><input className="input-bordered" type="text" id="token-address" name="token-address" value="0xde0b295669a9fd93d5f28d9ec85e40f4cb697bae"></input><span className="input-note">Note: Add only ERC-20 compatible (Metamask or Trust Wallet) addresses. Exchange wallet addresses should not be added.</span></div>

                        <div className="gaps-3x"></div>
                        <div className="d-sm-flex justify-content-between align-items-center">
                           <Link to="/profile"><button className="btn btn-primary" onClick={handlecloseeditwallet}>Save</button></Link>
                           <div className="gaps-2x d-sm-none"></div>

                        </div>
               

                  </div>
               </div>



            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer> */}
         </Modal>

         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
         {/* Same as */}
         <ToastContainer />
      </div>



   );
}

export default Wallet;
