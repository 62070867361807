
import React from 'react';
import './account.css';
import Accountwrapper from './accountwrapper';
import Footer from "../footer"
import Topbar from "../topbar"
import Navbar from "../navbar"

import { useState, useEffect } from 'react';



function Account() {

   const [divClass, setDivClass] = useState('');

   const [displaymobnav, setdisplaymobnav] = useState('');

   const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
   const [ban, setban] = useState(false)


   useEffect(() => {
      const handleResize = () => setViewportWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);


   }, []);

   useEffect(() => {
      if (viewportWidth < 992) {
         setDivClass('navbar-mobile');
         setdisplaymobnav("none")
      }
      else {
         setdisplaymobnav("block")
      }


   }, [viewportWidth]);

   const handleButtonClick = () => {
      console.log("clicked")
      if (viewportWidth < 992) {
         console.log("check if")
         setDivClass(prevClass => prevClass === 'navbar-mobile' ? 'navbar-mobile active' : 'navbar-mobile');
         setdisplaymobnav(prevClass => prevClass === 'none' ? 'block' : 'none')
      }


   };
   return (

      <div className="">
         <div className="topbar-wrap">
         <Topbar
               handleButtonClick={handleButtonClick}
               setdisplaymobnav={setdisplaymobnav}
               ban={ban}
               displaymobnav={displaymobnav}
               divClass={divClass}
               setDivClass={setDivClass}
            ></Topbar>
            <Navbar
               ban={ban}
               displaymobnav={displaymobnav}
               divClass={divClass}
               setDivClass={setDivClass}
            ></Navbar>
         </div>
         <div style={{ minHeight: "80vh" }}>
            <Accountwrapper></Accountwrapper>

         </div>

         <Footer></Footer>
      </div>

   );
}

export default Account;
