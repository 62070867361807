
import React from 'react';
import { useState } from 'react';
import balancelogo from "../../assets/img-ozo/logo-monogram-200x200-white.png"
import convertlogo from "../../assets/img-ozo/favico-256x256-1.png"
import airdrop from "../../assets/img-ozo/icon-airdrop.png"
import faucet from "../../assets/img-ozo/icon-faucet.png"
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import "./dashboard.css"
import { buyvalue, currentwallet } from '../../reducers/ConstantSlice'
import { useEffect } from 'react';
import { BiCopy, BiLink } from 'react-icons/bi';
import { useCopyToClipboard } from "usehooks-ts";
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiURI } from "./../../config/config"
import Cookies from 'js-cookie'
import Modal from 'react-bootstrap/Modal';
import Web3 from "web3"
import animationData from "./dots.json"
import Lottie from 'react-lottie';
import Carousel from 'react-bootstrap/Carousel';



function Dashboardwrapper({ }) {
   const navigate = useNavigate()
   const buyusdt = useSelector((state) => state.constVar.buyvalue)
   const refferalcode = useSelector((state) => state.constVar.refferalcode)
   const user_id = useSelector((state) => state.constVar.user_id)
   const coinvalue = useSelector((state) => state.TokenDet.arr)
   const round = useSelector((state) => state.TokenDet.round)
   const roundId = useSelector((state) => state.TokenDet.round_id)
   const dispatch = useDispatch();
   const [usdt, setusdt] = useState()
   const [Token, setToken] = useState()
   const [fromWallet, setfromWallet] = useState("")
   const [showReferral, setshowReferral] = useState(false)
   const [showaddwallet, setshowaddwallet] = useState(false);
   const [walletAddress, setwalletAddress] = useState("")
   const [allWalletDetails, setallWalletDetails] = useState()
   const [paypop, setpaypop] = useState(false);
   const [paylink, setpaylink] = useState("")
   const handleClosepaypop = () => setpaypop(false);
   const [payModePopup, setpayModePopup] = useState(false)
   const [amt, setamt] = useState(100)
   const [value, copy] = useCopyToClipboard();
   const [show, setShow] = useState(false);
   const [usdtstate, setusdtstate] = useState()
   var usdtpay;
   const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
         preserveAspectRatio: "xMidYMid slice"
      }
   };
   //popups,setfunctions
   const handleclosewallet = () => {

      setshowaddwallet(false);
      setwalletAddress("");
   }
   const handleWalletAddress = (e) => {
      console.log(e.target.value, "wallet address")
      setwalletAddress(e.target.value);
   }
   const handleAdd = () => {
      addWallet();
      handleclosewallet();
   }
   const payModePopupClose = () => {
      setpayModePopup(false);
   }

   // add wallet address either to DB or local based on user or guest
   const addWallet = async () => {
      const web3 = new Web3('https://mainnet.infura.io/v3/your-project-id');
      const address = walletAddress; // replace with the address to check
      var existwallet = false;
      for (let i = 0; i < allWalletDetails.length; i++) {
         if (walletAddress === allWalletDetails[i]?.wallet_address) {
            console.log(allWalletDetails[0]?.wallet_address, "inside loop")
            existwallet = true;
         }
      }

      if (web3.utils.isAddress(address)) {

         console.log('Valid Ethereum wallet address');
         console.log("exist wallet", existwallet)

         if (Cookies.get('type') === "user") {


            if (existwallet === false) {
               try {
                  await fetch(apiURI.URL + 'wallet_address/', {
                     method: 'POST',
                     headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Cookies.get('type') === "guest" ? "" : `Bearer ${localStorage.getItem('token')}`
                     },
                     body: JSON.stringify(
                        {
                           "user_id": user_id,
                           "wallet_address": walletAddress,
                           "current": allWalletDetails.length > 0 ? false : true,
                        }
                     )
                  })
                     .then(response => response.json())
                     .then(async data => {
                        if (data?.message == "Wallet Address already exists") {
                           // alert("This wallet has already been added")
                           toast.warn("This wallet has already been added,If you want to switch Wallet address go to profile", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                           });
                        }
                        else if (data?.message === "failed to add") {
                           toast.error("Wallet is already added to another account", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                           });
                        }
                        else {
                           await getWallet();
                           // alert("Wallet added successfully")
                           toast.success("Wallet added successfully", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                           });


                           await payNow(amt);
                        }
                        console.log(data)
                     })
                     .catch(error => console.error(error));
               }
               catch (error) {
                  console.log(error, "error");
               }
            }
            else {
               toast.warn("This wallet has already been added", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
               });
            }
         }
         else if (Cookies.get('type') === "guest") {
            localStorage.setItem('walletaddress', walletAddress);
            toast.success("wallet added successfully", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
            });
            payNow(amt);
         }
      }
      else {
         toast.error("Incorrect wallet address", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
         });
      }

      console.log(walletAddress, "wallet adres")
   }



   //usd to token conversion
   const convertToken = (e) => {
      const usdtValue = e.target.value.trim() !== '' ? Number(e.target.value) : undefined;
      if (usdtValue === undefined || isNaN(usdtValue) || usdtValue < 0) {
         setusdt(undefined);
         setToken(undefined);
      } else {
         setusdt(usdtValue);
         setToken(usdtValue / coinvalue?.data[round]?.price_per_token);
      }
   }
   //token to usd conversion
   const convertToUsd = (e) => {
      const tokenValue = e.target.value.trim() !== '' ? Number(e.target.value) : undefined;
      if (tokenValue === undefined || isNaN(tokenValue) || tokenValue < 0) {
         setusdt(undefined);
         setToken(undefined);
      } else {
         setToken(tokenValue);
         setusdt(tokenValue * coinvalue?.data[round]?.price_per_token);
      }
   }
   //roundoff function
   function restrictDecimalDigits(e) {
      const input = e.target;
      const value = input.value;
      const indexOfDecimal = value.indexOf(".");
      if (indexOfDecimal >= 0 && value.substring(indexOfDecimal + 1).length > 2) {
         input.value = parseFloat(value).toFixed(2);
      }
   }

   //navigation with buy value
   const navRound = (e) => {
      if (coinvalue?.data[e]?.is_active === true) {
         navigate('/buyozo');
      }
      else {
         console.log(e, "not active")
      }


   }




   //navigation with buy value
   const navBuyozo = () => {
      console.log("clicked")
      dispatch(buyvalue(usdt));
      navigate('/buyozo');
   }

   //Referal link genaeration
   const generateReferral = () => {
      if (Cookies.get("type") === "guest") {
         toast.warn("Please Sign in as User to get Referral Link", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
         });

      }
      else {
         if (fromWallet != null && fromWallet !== "" && fromWallet !== undefined) {
            setshowReferral(true)
         }
         else {
            toast.warn("Please add a wallet address to your profile to generate referal link", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
            });
         }
      }
   }

   //paynow button trigger

   const payNow = (amt) => {
      console.log("amt",amt)
      usdtpay = amt;
setusdtstate(amt);
      if ((fromWallet == undefined || fromWallet === "" || fromWallet == null) && (localStorage.getItem("walletaddress") === undefined || localStorage.getItem("walletaddress") === null || localStorage.getItem("walletaddress") === "")) {
         console.log("no wallet available")
         setshowaddwallet(true);
      }
      else {
         //ask payment type and post payment
         setpayModePopup(true);
   
         // postPayment(false, amt);
      }
   }
   const handlePayMode = (type) => 
   {
      console.log("type,usdtpay :", type,usdtstate)
      
      postPayment(false,type);
      payModePopupClose();
      }

        const paymentOption = (paytype) => 
        {
        localStorage.setItem("paytype",paytype)
        navigate("/buyozo")
      }

   // Post payment data to backend

   const postPayment = async (igpending,type) => {
      console.log(localStorage.getItem("walletaddresss"), "wallet address inside post", igpending,type,usdtpay,usdtstate)
      var currentDate = new Date();
      var options = {
         year: 'numeric',
         month: '2-digit',
         day: '2-digit',
         hour: '2-digit',
         minute: '2-digit',
         second: '2-digit',
         hour12: true
      };
      let timestamp_init = new Intl.DateTimeFormat('en-US', options).format(currentDate);
      console.log(timestamp_init, "timestamp initiate");
      try {
         setShow(true);
         await fetch(apiURI.URL + 'user_transaction/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest" ? "" : `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  user_id: Cookies.get('type') === "guest" ? 143 : user_id,
                  timestamp_submitted: "0",
                  timestamp_confirmed: "0",
                  timestamp_initiated: timestamp_init,
                  transaction_hash: "",
                  usdt_spent: usdtpay===undefined?usdtstate:usdtpay,
                  ozo_received: usdtpay===undefined?(usdtstate / coinvalue?.data[round]?.price_per_token):(usdtpay / coinvalue?.data[round]?.price_per_token),
                  from_address: "",
                  to_address: fromWallet == null || fromWallet === undefined || fromWallet === "" ? localStorage.getItem("walletaddress") : fromWallet,
                  transaction_type: "Purchase",
                  tran_status: "pending",
                  round: roundId,
                  ignore_pending: igpending,
                  payment_type:type,
               }
            )
         })
            .then((response) => {
               const json = response.json();
               console.log("response data", json.object)
               return json;
            })
            .then(data => {
               console.log("create data", data?.message)
               // setcurrentTransactionno(data?.message)
               setShow(false);
               if (data?.message === "You have a pending transaction, please wait for it to complete.") {
                  // setShowpending(true)
                  postPayment(true,type);
               }
               else if (data?.message === "failed try again later") {
                  toast.warn("An error occured please try after sometime", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                  });
               }
               else {

                  // window.open(data?.message, '_blank');

                  setpaylink(data?.message);
                  setpaypop(true)
                  // Navigate("/transactions")
               }

            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
   }




   //get wallet address
   const getWallet = async () => {
      try {
         await fetch(apiURI.URL + 'get_wallet_address/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest" ? "" : `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  user_id: Cookies.get('type') === "guest" ? 143 : user_id,

               }
            )
         })
            .then(response => response.json())

            .then(data => {
               console.log("all wallet data", data)
               setallWalletDetails(data);
               let currentWallet = data.find(object => object.current === true);
               console.log(currentWallet.wallet_address, "getwallet")
               setfromWallet(currentWallet.wallet_address)
               dispatch(currentwallet(currentWallet.wallet_address));
            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }

   }

   useEffect(() => {
      getWallet();
   }, [])
   //copy function
   const copied = () => {
      toast.success("Copied Successfully", {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
      });
   }

   useEffect(() => {
      if (buyusdt > 0) {
         console.log("buyval", buyusdt)
         // setusdt(buyusdt)
         // setToken(buyusdt / coinvalue?.data[round]?.price_per_token);
      }
   }, [])
   useEffect(() => {
      if (window.location.href.includes('dashboard')) {
         // Create the script element
         const script = document.createElement('script');
         script.id = 'contactus-jssdk';
         script.src = 'https://api.anychat.one/widget/ab9a810f-fec3-4c90-b8f2-48493b16d045?r=' + encodeURIComponent(window.location);

         // Append the script to the body of the document
         document.body.appendChild(script);

         // Clean up the script when the component is unmounted
         return () => {
            document.body.removeChild(script);
         };
      }
   }, []);

   return (
      <div className="page-content" style={{ paddingTop: "0px", marginTop: "0px" }}>
         <div className="container" >


            <div className='mb-4 mt-4' style={{}}>

               <div style={{ width: "auto", justifyContent: "center", display: "flex", flexWrap: "wrap" }}>
                  <button className='btn btn-primary btn-block m-2 iv' onClick={() => { payNow(100) }}>Invest $100 ~ 500 OZO</button>
                  <button className='btn btn-primary btn-block m-2 iv' onClick={() => { payNow(500) }}>Invest $500 ~ 2500 OZO</button>
                  <button className='btn btn-primary btn-block m-2 iv' onClick={() => { payNow(1000) }}>Invest $1000 ~ 5000 OZO</button>
               </div>
               <div style={{ width: "auto", justifyContent: "center", display: "flex", flexWrap: "wrap" }}>
                  <button className='btn btn-primary btn-block m-2 iv' style={{ display: "flex", alignItems: "center", textTransform: "capitalize" }} onClick={() => { window.open("https://t.me/Ozonechain_airdrop_bot", "_blank"); }}><img style={{ width: "30px", marginRight: "5px" }} src={airdrop}></img>&nbsp;$ BabyOZO Airdrop</button>
                  <button className='btn btn-primary btn-block m-2 iv' style={{ display: "flex", alignItems: "center", textTransform: "capitalize" }} onClick={() => { window.open("https://t.me/babyozobot", "_blank"); }}><img style={{ width: "30px", marginRight: "5px" }} src={faucet}></img>&nbsp;$ OZO Faucet & Rewards</button>


               </div>


            </div>
            <div className='row' >

               <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="token-sales card lc card-full-height " style={{ border: "solid 1px green", cursor: "pointer", height: "auto" }} >
                     <div style={{ width: "100%", height: "auto", position: "absolute", zIndex: "2", backgroundColor: ((coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) == 0) || coinvalue?.data[0]?.is_active === true ? "#ffffff00" : "#ffffffcf" }}></div>
                     <div className="card-innr" >
                        <div className="card-head">
                           <div className='row'>
                              <div className='col p-0'>
                                 <h4 className="card-title stats">Funding Type </h4>
                                 <h2 className='h2 title'>{coinvalue?.data[0]?.funding_type}</h2>
                              </div>
                              <div className='col'>
                                 <img className="token-info-icon" src={convertlogo} alt="logo-sm"></img>
                              </div>
                           </div>
                           <h4 className="card-title stats">Price </h4>
                           <h2 className='h2 title'>${coinvalue?.data[0]?.price_per_token} </h2>
                           {/* <h4 className="card-title stats">Total Coins on Sale</h4>
                           <h2 className='h2 title'>{coinvalue?.data[0]?.total_tokens}</h2> */}
                           <div style={{ display: "flex", flexDirection: "column" ,textAlign: "center" }}>
                              {(coinvalue?.data[0]?.is_active === true) && ((coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) !== 0) ? <div style={{display:"flex",zIndex:"3"}}><button className='btn btn-primary btn-block mt-3  mr-1' onClick={(e)=>{paymentOption("coinbase")}} style={{ width: "auto", fontSize: "12px", alignSelf: "center" }}>Buy OZO (Crypto)</button><button className='btn btn-primary btn-block mt-3 ' onClick={(e)=>{paymentOption("paypal")}} style={{ width: "aut", fontSize: "12px", alignSelf: "center" }}>Buy OZO (USD)</button></div> : ""}
                              <p className='' style={{ marginTop: (coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) == 0 ? "1rem" : "1rem", fontWeight: "700", }}>
                                 {
                                    (coinvalue?.data[0]?.is_active === true) && ((coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) == 0) ? "Sold Out"
                                       // : (coinvalue?.data[0]?.is_active === true) && ((coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) !== 0) ? "Ongoing"  
                                       : (coinvalue?.data[0]?.is_active === false) && ((coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) == 0) ? "Sold Out"
                                          : (coinvalue?.data[0]?.is_active === false) && ((coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) !== 0) ? "Inactive"
                                             : ""
                                 }</p>

                           </div>
                        </div>
                        {/* <ul className="progress-info mb-2" style={{ display: (coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) == 0 || (coinvalue?.data[0]?.is_active === false) && ((coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) !== 0) ? "none" : "flex" }}>
                           <li><span style={{ fontSize: "9px" }}>SOLD  COINS</span><span style={{ fontSize: "14px", color: "black" }}>{coinvalue?.data[0]?.tokens_sold.toFixed(2)}</span></li>
                           <li className="text-right"><span style={{ fontSize: "9px" }}>UNSOLD COINS</span><span style={{ fontSize: "14px", color: "black" }}>{(coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold).toFixed(2)}</span></li>
                        </ul>
                        <ProgressBar style={{ display: (coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) == 0 || (coinvalue?.data[0]?.is_active === false) && ((coinvalue?.data[0]?.total_tokens - coinvalue?.data[0]?.tokens_sold) !== 0) ? "none" : "block" }} now={((coinvalue?.data[0]?.tokens_sold) / coinvalue?.data[0]?.total_tokens) * 100}
                           variant="success" /> */}



                     </div>
                  </div>
               </div>

               <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="token-sales card lc  card-full-height blur" style={{ border: "solid 1px green", cursor: "pointer", height: "auto" }} >
                     <div style={{ width: "100%", height: "100%", position: "absolute", zIndex: "2", backgroundColor: ((coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) == 0) || coinvalue?.data[1]?.is_active === true ? "#ffffff00" : "#ffffffcf" }}></div>
                     <div className="card-innr" >
                        <div className="card-head">
                           <div className='row'>
                              <div className='col p-0'>
                                 <h4 className="card-title stats">Funding Type </h4>
                                 <h2 className='h2 title'>{coinvalue?.data[1]?.funding_type}</h2>
                              </div>
                              <div className='col'>
                                 <img className="token-info-icon" src={convertlogo} alt="logo-sm"></img>
                              </div>
                           </div>
                           <h4 className="card-title stats">Price </h4>
                           <h2 className='h2 title'>${coinvalue?.data[1]?.price_per_token} </h2>
                           {/* <h4 className="card-title stats">Total Coins on Sale</h4>
                           <h2 className='h2 title'>{coinvalue?.data[1]?.total_tokens}</h2> */}
                           <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                              {(coinvalue?.data[1]?.is_active === true) && ((coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) !== 0) ? <div style={{display:"flex",zIndex:"3"}}><button className='btn btn-primary btn-block mt-3  mr-1' onClick={(e)=>{paymentOption("coinbase")}} style={{ width: "auto", fontSize: "12px", alignSelf: "center" }}>Buy OZO (Crypto)</button><button className='btn btn-primary btn-block mt-3 ' onClick={(e)=>{paymentOption("paypal")}} style={{ width: "aut", fontSize: "12px", alignSelf: "center" }}>Buy OZO (USD)</button></div> : ""}
                              <p className='' style={{ marginTop: (coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) == 0 ? "1rem" : "1rem", fontWeight: "700", }}>
                                 {
                                    (coinvalue?.data[1]?.is_active === true) && ((coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) == 0) ? "Sold Out"
                                       // : (coinvalue?.data[1]?.is_active === true) && ((coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) !== 0) ? "Ongoing"
                                       : (coinvalue?.data[1]?.is_active === false) && ((coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) == 0) ? "Sold Out"
                                          : (coinvalue?.data[1]?.is_active === false) && ((coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) !== 0) ? "Inactive"
                                             : ""
                                 }</p>


                           </div>
                        </div>
                        {/* <ul className="progress-info mb-2" style={{ display: (coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) == 0 || (coinvalue?.data[1]?.is_active === false) && ((coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) !== 0) ? "none" : "flex" }}>
                           <li><span style={{ fontSize: "9px" }}>SOLD COINS</span><span style={{ fontSize: "14px", color: "black" }}>{coinvalue?.data[1]?.tokens_sold.toFixed(2)}</span></li>
                           <li className="text-right"><span style={{ fontSize: "9px" }}>UNSOLD COINS</span><span style={{ fontSize: "14px", color: "black" }}>{(coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold).toFixed(2)}</span></li>
                        </ul>
                        <ProgressBar style={{ display: (coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) == 0 || (coinvalue?.data[1]?.is_active === false) && ((coinvalue?.data[1]?.total_tokens - coinvalue?.data[1]?.tokens_sold) !== 0) ? "none" : "block" }} now={((coinvalue?.data[1]?.tokens_sold) / coinvalue?.data[1]?.total_tokens) * 100}
                           variant="success" /> */}


                     </div>
                  </div>
               </div>

               <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="token-sales card lc  card-full-height blur" style={{ border: "solid 1px green", cursor: "pointer", height: "auto" }} >
                     <div style={{ width: "100%", height: "100%", position: "absolute", zIndex: "2", backgroundColor: ((coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) == 0) || coinvalue?.data[2]?.is_active === true ? "#ffffff00" : "#ffffffcf" }}></div>
                     <div className="card-innr" >
                        <div className="card-head">
                           <div className='row'>
                              <div className='col p-0'>
                                 <h4 className="card-title stats">Funding Type </h4>
                                 <h2 className='h2 title'>{coinvalue?.data[2]?.funding_type}</h2>
                              </div>
                              <div className='col'>
                                 <img className="token-info-icon" src={convertlogo} alt="logo-sm"></img>
                              </div>
                           </div>
                           <h4 className="card-title stats">Price </h4>
                           <h2 className='h2 title'>${coinvalue?.data[2]?.price_per_token} </h2>
                           {/* <h4 className="card-title stats">Total Coins on Sale</h4>
                           <h2 className='h2 title'>{coinvalue?.data[2]?.total_tokens}</h2> */}
                           <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                              {(coinvalue?.data[2]?.is_active === true) && ((coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) !== 0) ? <div style={{display:"flex",zIndex:"3"}}><button className='btn btn-primary btn-block mt-3  mr-1' onClick={(e)=>{paymentOption("coinbase")}} style={{ width: "auto", fontSize: "12px", alignSelf: "center" }}>Buy OZO (Crypto)</button><button className='btn btn-primary btn-block mt-3 ' onClick={(e)=>{paymentOption("paypal")}} style={{ width: "aut", fontSize: "12px", alignSelf: "center" }}>Buy OZO (USD)</button></div> : ""}

                              
                              <p className='' style={{ marginTop: (coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) == 0 ? "1rem" : "1rem", fontWeight: "700", }}>
                                 {
                                    (coinvalue?.data[2]?.is_active === true) && ((coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) == 0) ? "Sold Out"
                                       // : (coinvalue?.data[2]?.is_active === true) && ((coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) !== 0) ? "Ongoing"
                                       : (coinvalue?.data[2]?.is_active === false) && ((coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) == 0) ? "Sold Out"
                                          : (coinvalue?.data[2]?.is_active === false) && ((coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) !== 0) ? "Inactive"
                                             : ""
                                 }</p>


                           </div>
                        </div>
                        {/* <ul className="progress-info mb-2" style={{ display: (coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) == 0 || (coinvalue?.data[2]?.is_active === false) && ((coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) !== 0) ? "none" : "flex" }}>
                           <li><span style={{ fontSize: "9px" }}>SOLD COINS</span><span style={{ fontSize: "14px", color: "black" }}>{coinvalue?.data[2]?.tokens_sold.toFixed(2)}</span></li>
                           <li className="text-right"><span style={{ fontSize: "9px" }}>UNSOLD COINS</span><span style={{ fontSize: "14px", color: "black" }}>{(coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold).toFixed(2)}</span></li>
                        </ul>
                        <ProgressBar style={{ display: (coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) == 0 || (coinvalue?.data[2]?.is_active === false) && ((coinvalue?.data[2]?.total_tokens - coinvalue?.data[2]?.tokens_sold) !== 0) ? "none" : "block" }} now={((coinvalue?.data[2]?.tokens_sold) / coinvalue?.data[2]?.total_tokens) * 100}
                           variant="success" /> */}



                     </div>
                  </div>
               </div>


            </div>



            <div className='row justify-content-xl-center justify-content-lg-center '>
               <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="token-sales card lc  card-full-height blur" style={{ border: "solid 1px green", cursor: "pointer", height: "auto" }} >
                     <div style={{ width: "100%", height: "100%", position: "absolute", zIndex: "2", backgroundColor: ((coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) == 0) || coinvalue?.data[3]?.is_active === true ? "#ffffff00" : "#ffffffcf" }} ></div>
                     <div className="card-innr" >
                        <div className="card-head">
                           <div className='row'>
                              <div className='col p-0'>
                                 <h4 className="card-title stats">Funding Type </h4>
                                 <h2 className='h2 title'>{coinvalue?.data[3]?.funding_type}</h2>
                              </div>
                              <div className='col'>
                                 <img className="token-info-icon" src={convertlogo} alt="logo-sm"></img>
                              </div>
                           </div>
                           <h4 className="card-title stats">Price </h4>
                           <h2 className='h2 title'>${coinvalue?.data[3]?.price_per_token} </h2>
                           {/* <h4 className="card-title stats">Total Coins on Sale</h4>
                           <h2 className='h2 title'>{coinvalue?.data[3]?.total_tokens}</h2> */}
                           <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                              {(coinvalue?.data[3]?.is_active === true) && ((coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) !== 0) ? <div style={{display:"flex",zIndex:"3"}}><button className='btn btn-primary btn-block mt-3  mr-1' onClick={(e)=>{paymentOption("coinbase")}} style={{ width: "auto", fontSize: "12px", alignSelf: "center" }}>Buy OZO (Crypto)</button><button className='btn btn-primary btn-block mt-3 ' onClick={(e)=>{paymentOption("paypal")}} style={{ width: "aut", fontSize: "12px", alignSelf: "center" }}>Buy OZO (USD)</button></div> : ""}

                              <p className='' style={{ marginTop: (coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) == 0 ? "1rem" : "1rem", fontWeight: "700", }}>
                                 {
                                    (coinvalue?.data[3]?.is_active === true) && ((coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) == 0) ? "Sold Out"
                                       // : (coinvalue?.data[3]?.is_active === true) && ((coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) !== 0) ? "Ongoing"
                                       : (coinvalue?.data[3]?.is_active === false) && ((coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) == 0) ? "Sold Out"
                                          : (coinvalue?.data[3]?.is_active === false) && ((coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) !== 0) ? "Inactive"
                                             : ""
                                 }</p>
                           </div>
                        </div>
                        {/* <ul className="progress-info mb-2" style={{ display: (coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) == 0 || (coinvalue?.data[3]?.is_active === false) && ((coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) !== 0) ? "none" : "flex" }}>
                           <li><span style={{ fontSize: "9px" }}>SOLD COINS</span><span style={{ fontSize: "14px", color: "black" }}>{coinvalue?.data[3]?.tokens_sold.toFixed(2)}</span></li>
                           <li className="text-right"><span style={{ fontSize: "9px" }}>UNSOLD COINS</span><span style={{ fontSize: "14px", color: "black" }}>{(coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold).toFixed(2)}</span></li>
                        </ul>
                        <ProgressBar style={{ display: (coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) == 0 || (coinvalue?.data[3]?.is_active === false) && ((coinvalue?.data[3]?.total_tokens - coinvalue?.data[3]?.tokens_sold) !== 0) ? "none" : "block" }} now={((coinvalue?.data[3]?.tokens_sold) / coinvalue?.data[3]?.total_tokens) * 100}
                           variant="success" /> */}

                     </div>
                  </div>
               </div>

               <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="token-sales card lc  card-full-height blur" style={{ border: "solid 1px green", cursor: "pointer", height: "auto" }} >
                     <div style={{ width: "100%", height: "100%", position: "absolute", zIndex: "2", backgroundColor: ((coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) == 0) || coinvalue?.data[4]?.is_active === true ? "#ffffff00" : "#ffffffcf" }}></div>
                     <div className="card-innr" >
                        <div className="card-head">
                           <div className='row'>
                              <div className='col p-0'>
                                 <h4 className="card-title stats">Funding Type </h4>
                                 <h2 className='h2 title'>{coinvalue?.data[4]?.funding_type}</h2>
                              </div>
                              <div className='col'>
                                 <img className="token-info-icon" src={convertlogo} alt="logo-sm"></img>
                              </div>
                           </div>
                           <h4 className="card-title stats">Price </h4>
                           <h2 className='h2 title'>${coinvalue?.data[4]?.price_per_token} </h2>
                           {/* <h4 className="card-title stats">Total Coins on Sale</h4>
                           <h2 className='h2 title'>{coinvalue?.data[4]?.total_tokens}</h2> */}
                           <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                              {(coinvalue?.data[4]?.is_active === true) && ((coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) !== 0) ? <div style={{display:"flex",zIndex:"3"}}><button className='btn btn-primary btn-block mt-3  mr-1' onClick={(e)=>{paymentOption("coinbase")}} style={{ width: "auto", fontSize: "12px", alignSelf: "center" }}>Buy OZO (Crypto)</button><button className='btn btn-primary btn-block mt-3 ' onClick={(e)=>{paymentOption("paypal")}} style={{ width: "aut", fontSize: "12px", alignSelf: "center" }}>Buy OZO (USD)</button></div> : ""}
                              <p className='' style={{ marginTop: (coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) == 0 ? "1rem" : "1rem", fontWeight: "700", }}>
                                 {
                                    (coinvalue?.data[4]?.is_active === true) && ((coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) == 0) ? "Sold Out"
                                       // : (coinvalue?.data[4]?.is_active === true) && ((coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) !== 0) ? "Ongoing"
                                       : (coinvalue?.data[4]?.is_active === false) && ((coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) == 0) ? "Sold Out"
                                          : (coinvalue?.data[4]?.is_active === false) && ((coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) !== 0) ? "Inactive"
                                             : ""
                                 }</p>


                           </div>
                        </div>
                        {/* <ul className="progress-info mb-2" style={{ display: (coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) == 0 || (coinvalue?.data[4]?.is_active === false) && ((coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) !== 0) ? "none" : "flex" }}>
                           <li><span style={{ fontSize: "9px" }}>SOLD COINS</span><span style={{ fontSize: "14px", color: "black" }}>{coinvalue?.data[4]?.tokens_sold.toFixed(2)}</span></li>
                           <li className="text-right"><span style={{ fontSize: "9px" }}>UNSOLD COINS</span><span style={{ fontSize: "14px", color: "black" }}>{(coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold).toFixed(2)}</span></li>
                        </ul>
                        <ProgressBar style={{ display: (coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) == 0 || (coinvalue?.data[4]?.is_active === false) && ((coinvalue?.data[4]?.total_tokens - coinvalue?.data[4]?.tokens_sold) !== 0) ? "none" : "block" }} now={((coinvalue?.data[4]?.tokens_sold) / coinvalue?.data[4]?.total_tokens) * 100}
                           variant="success" /> */}


                     </div>
                  </div>

                  
               </div>

            </div>

            <div className='row justify-content-xl-center justify-content-lg-center mb-4'>


               <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div style={{ width: "auto", justifyContent: "center", display: "flex", flexWrap: "wrap" }}>
                     <button className='btn btn-primary btn-block m-2 iv' >Listing Price  $0.22</button>

                  </div>

               </div>
               </div>
               <div style={{ marginBottom: "10px", textAlign: "center" }}>

                  <iframe className="videoguide"
                     src="https://www.youtube.com/embed/YJuNt9l2v7o">
                  </iframe>
               </div>
               <div className='row'>

                  <div className="col-xl-6 col-lg-6">
                     <div className="token-calculator card card-full-height">
                        <div className="card-innr">

                           <div className="card-head">
                              <h4 className="card-title">Referral</h4>
                              <p className='mt-2'>Bring in new users and earn 15% of their purchase as rewards.Generate unique referral link to share with users. The referrer gets a reward when the referred person completes a purchase.</p>
                           </div>
                           <div style={{ display: "contents" }}>
                              {showReferral ?
                                 <div className='input-bordered input-with-hint mb-2' onClick={() => copy("https://ico.ozonechain.io/signup?id=" + refferalcode)} style={{
                                    display: "flex", border: "2px solid #e3e3e3", width: "100%", margin: "0", padding: "5px", cursor: "pointer"
                                 }}>
                                    <BiLink />&nbsp;&nbsp;
                                    <p style={{ margin: "0", width: "100%", overflowY: "hidden" }}>https://ico.ozonechain.io/signup?id={refferalcode}
                                       &nbsp;<div style={{ float: "right" }} ><BiCopy onClick={copied} /></div>
                                       {/* <button onClick={() => copy( referralCode )}><BiCopy/></button> */}
                                    </p>
                                 </div>
                                 : <></>
                              }
                              <button className="btn btn-primary " onClick={generateReferral}>Generate referral link</button>
                              <p className='mt-2' style={{ fontWeight: "500" }}>The referral OZO coins will be distributed 30 days after the Token Generation Event (TGE).</p>
                           </div>
                        </div>
                     </div>
                  </div>


                  <div className="col-xl-6 col-lg-6" >
                     <div className="token-calculator card card-full-height">
                        <div className="card-innr">
                           <div className="card-head">
                              <div className='row'>
                                 <div className='col-8 p-0'>
                                    <h4 className="card-title">Coin Calculation</h4>
                                    <p className="card-title-text">Enter amount to calculate coins.</p>
                                 </div>
                                 <div className='col-4'>
                                    <img className="token-info-icon" src={convertlogo} alt="logo-sm"></img>
                                 </div>
                              </div>
                           </div>
                           <div className="token-calc">
                              <div className="token-pay-amount">
                                 <input id="token-base-amount" className="input-bordered input-with-hint" type="number" min={0} value={usdt} onChange={(e) => convertToken(e)} step="0.01" onInput={restrictDecimalDigits} style={{ color: "black" }}></input>
                                 <div className="token-pay-currency"><span className="input-hint input-hint-sap" style={{ color: "black" }}>USD ($)</span></div>
                              </div>
                              <div className="token-eq-sign">=</div>
                              <div className="token-pay-amount">
                                 <input id="token-base-amount" className="input-bordered input-with-hint" type="number" min={0} value={Token} style={{ color: "black" }} onChange={(e) => convertToUsd(e)} step="0.01" onInput={restrictDecimalDigits} ></input>
                                 <div className="token-pay-currency"><span className="input-hint input-hint-sap" style={{ color: "black" }}>OZO</span></div>
                              </div>
                           </div>
                           <div className="token-calc-note note note-plane"><em className="fas fa-info-circle text-light"></em><span className="note-text text-light">Amount calculated based current coins price</span></div>
                           <p className='mt-2'>Coin calculation refers to the process of determining the number of coins, which are digital units of value, that are required for a particular transaction or action on a blockchain network. This calculation is typically based on a variety of factors, including the current market value of the coin</p>
                           <div className="token-buy">
                              {/* <button className="btn btn-primary" onClick={navBuyozo}>BUY OZO</button> */}
                           </div>
                        </div>
                     </div>
                  </div>

               </div>

               <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
               />
               {/* Same as */}
               <ToastContainer />

               <Modal show={paypop}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
                  <Modal.Body>
                     <div style={{ padding: "20px", justifyContent: "center", textAlign: "center" }}>
                        <p style={{ fontSize: "17px" }}>Click Ok to proceed
                        </p>
                        <br></br>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                           <a href={paylink} className="btn btn-primary" style={{ width: "80px" }} onClick={handleClosepaypop} target="_blank">OK</a>

                        </div>
                     </div>
                  </Modal.Body>
               </Modal>

               <Modal show={showaddwallet} onHide={handleclosewallet}>
                  {/* <Modal.Header closeButton>

        </Modal.Header> */}
                  <Modal.Body>



                     <div className="modal-content">
                        <button className="modal-close" style={{ border: "0px", background: "#f0f8ff00" }} onClick={handleclosewallet}><em className="ti ti-close"></em></button>
                        <div className="popup-body">


                           <div className="row">

                           </div>

                           <div className="input-item input-with-label">
                              <label for="token-address" className="input-item-label">Add you Receiving Wallet Address</label>
                              <input className="input-bordered" type="text" value={walletAddress} onChange={(e) => handleWalletAddress(e)}></input>
                              <span className="input-note">Note:Add only ERC-20 compatible (Metamask or Trust Wallet) addresses. Exchange wallet addresses should not be added.</span></div>

                           <div className="gaps-3x"></div>
                           <div className="d-sm-flex justify-content-between align-items-center">
                              <button className="btn btn-primary" onClick={handleAdd}>Add Wallet</button>
                              <div className="gaps-2x d-sm-none"></div>

                           </div>


                        </div>
                     </div>



                  </Modal.Body>
                  {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer> */}
               </Modal>
               <Modal show={payModePopup} onHide={payModePopupClose}>
            <Modal.Body>
               <div className="modal-content">
                  <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={payModePopupClose}><em className="ti ti-close"></em></a>
                  <div className="popup-body">
                     <div className="row">
                     </div>
                     <div className="input-item input-with-label">
                        <p style={{ fontSize: "17px" }}>How do you like to make payment?
                        </p>
                     </div>

                     <div style={{ display: "flex" }}>
                        <button className="btn btn-primary mr-2" onClick={() => handlePayMode("coinbase")}>Crypto</button>
                        <button className="btn btn-primary" onClick={() => handlePayMode("paypal")}>Fiat (USD)</button>
                     </div>
                  </div>
               </div>
            </Modal.Body>

         </Modal>
               <div style={{ position: "fixed", padding: "100px", display: show === true ? "flex" : "none", justifyContent: "center", width: "100%", height: "100%", backgroundColor: "#80808063", top: "0px", left: "0px" }}>
                  <Lottie
                     options={defaultOptions}
                     height={300}
                     width={300}
                     style={{ marginTop: "10%" }} />
               </div>
            </div>
         </div>

         );
}

         export default Dashboardwrapper;
