import React from 'react'
import Modal from 'react-bootstrap/Modal';
const Ordercomponent = (
  {showpending,
    handleClose,
    currentTransactionno,
    transactiondata,
    setTransactionhash,
    submitTransaction,
    Transactionhash
  }
  ) => {

    var filteredData=[]

    if(transactiondata){
         filteredData = transactiondata.filter(item => item.transaction_no === currentTransactionno);
      
    }
// console.log("filtered",filteredData)
  return (
<Modal show={showpending} onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
             <Modal.Body>
            
            <div style={{ padding: "20px" }}>
             <div style={{display:"flex",justifyContent:"space-between"}}>
<h2>Order Details</h2>
<i class="bi bi-x-lg" onClick={handleClose}></i>
</div>
              
<div style={{padding:"10px",borderRadius:"8px",backgroundColor:"#f1f1f1",marginBottom:"20px"}}>
<div className='row' >
                <div className='col p-0'>
                  <label>Transaction ID:</label><span> {filteredData[0]?.transaction_no}</span>
                </div>
                <div className='col p-0'>
                  <label>Order Date:</label><span> {filteredData[0]?.timestamp_initiated}</span>
                </div>
              </div>
              <div className='row'>
                <div className='col p-0'>
                  <label>Type:</label><span> {filteredData[0]?.transaction_type}</span>
                </div>
                <div className='col p-0'>
                  <label>Coins:</label><span> {filteredData[0]?.ozo_received.toFixed(2)}</span>
                </div>
              </div>
              <div className='row'>
                <div className='col p-0'>
                  <label>Payment Crypto Type :</label><span></span>
                </div>
                <div className='col p-0'>
                  <label>Receiving Wallet Address:</label><span> {filteredData[0]?.to_address}</span>
                </div>
              </div>
              <div className='row mb-0'>
                <div className='col p-0'>
                  <label>Status:</label><span> {filteredData[0]?.status}</span>
                </div>
       
              </div>

              </div>




              <p style={{ fontSize: "17px" }}>Please paste the Transaction hash below to close this transaction.
              </p>

              <input className="input-bordered mb-2" type="text" value={Transactionhash} onChange={(e)=>setTransactionhash(e.target.value)}></input>
              <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
              <button type="button" className="btn btn-dark px-5" onClick={()=>submitTransaction(filteredData[0]?.transaction_no,filteredData[0]?.to_address,filteredData[0]?.ozo_received)}>Submit</button>
              </div>
              
            </div>
          </Modal.Body>
        </Modal>
  )
}

export default Ordercomponent