
import React from 'react';
import { useState, useEffect } from 'react';
import metamask from "../../assets/img-ozo/metamask-1.png"
import paypal from "../../assets/img-ozo/pay-c.png"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { selectAllTokenDetails } from '../../reducers/TokenDetailSlice'
import { navwallet, buyvalue,paymentaddress } from '../../reducers/ConstantSlice'
import { useSelector, useDispatch } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import USDTabi from "./USDTabi.json";
import loading from "./loading.gif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import animationData from "./dots.json"
import Lottie from 'react-lottie';
import { apiURI } from "./../../config/config"
import Web3 from 'web3';
import Cookies from 'js-cookie'
function Buyozowrapper() {
   const paymentaddress = useSelector((state) => state.constVar.paymentaddress)
   const buyusdt = useSelector((state) => state.constVar.buyvalue)
   const coinvalue = useSelector((state) => state.TokenDet.arr)
   const round = useSelector((state) => state.TokenDet.round)
   const roundId = useSelector((state) => state.TokenDet.round_id)
   const accountdata = useSelector((state) => state.constVar.accountdata)
   let Navigate = useNavigate();
   const [currentTransactionno, setcurrentTransactionno] = useState("")
   const [usdt, setusdt] = useState()
   const [Token, setToken] = useState()
   const [show, setShow] = useState(false);
   const [showpending, setShowpending] = useState(false);
   const [paypop, setpaypop] = useState(false);

   const [showpaylater, setshowpaylater] = useState(false);
   const [showuserguest, setshowuserguest] = useState(false)
   const [showaddwallet, setshowaddwallet] = useState(false);
   const [transactionHash, settransactionHash] = useState("");
   const [transStatus, settransStatus] = useState("");
   const [fromWallet, setfromWallet] = useState("")
   const [paylink, setpaylink] = useState("")
   const [allWalletDetails, setallWalletDetails] = useState()
   const [walletAddress, setwalletAddress] = useState("");
   const [conditionExecuted, setConditionExecuted] = useState("ifconfalse");
   const [payModePopup, setpayModePopup] = useState(false)

   // const [transaction, setTransaction] = useState({});
   const user_id = useSelector((state) => state.constVar.user_id)
   const dispatch = useDispatch();
   const paymentAddress = "0xCc6B7586f20EdB5273099eF93495Ec8cf3aA3796"
   const tokenAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7"
   const amount = "1000000"

   const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
         preserveAspectRatio: "xMidYMid slice"
      }
   };
   const handleWalletAddress = (e) => {
      console.log(e.target.value, "wallet address")
      setwalletAddress(e.target.value);
   }
   const addWallet = async () => {
      //       var valid = WAValidator.validate(walletAddress);
      // if(valid)
      //     alert('This is a valid address');
      // else
      //     alert('Address INVALID');

      const web3 = new Web3('https://mainnet.infura.io/v3/your-project-id');
      const address = walletAddress; // replace with the address to check
      var existwallet = false;
      for (let i = 0; i < allWalletDetails.length; i++) {
         if (walletAddress === allWalletDetails[i]?.wallet_address) {
            console.log(allWalletDetails[0]?.wallet_address, "inside loop")
            existwallet = true;
         }
      }

      if (web3.utils.isAddress(address)) {

         console.log('Valid Ethereum wallet address');
         console.log("exist wallet", existwallet)

         if (Cookies.get('type') === "user") {


            if (existwallet === false) {
               try {
                  await fetch(apiURI.URL + 'wallet_address/', {
                     method: 'POST',
                     headers: {
                        'Content-Type': 'application/json',
                        'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
                     },
                     body: JSON.stringify(
                        {
                           "user_id": user_id,
                           "wallet_address": walletAddress,
                           "current": allWalletDetails.length > 0 ? false : true,
                        }
                     )
                  })
                     .then(response => response.json())
                     .then(data => {
                        if (data?.message == "Wallet Address already exists") {
                           // alert("This wallet has already been added")
                           toast.warn("This wallet has already been added,If you want to switch Wallet address go to profile", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                           });
                        }
                        else if (data?.message === "failed to add") {
                           toast.error("Wallet is already added to another account", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                           });
                        }
                        else {
                           // alert("Wallet added successfully")
                           toast.success("Wallet added successfully", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                           });
                        }
                        console.log(data)
                     })
                     .catch(error => console.error(error));
               }
               catch (error) {
                  console.log(error, "error");
               }
               getWallet();

            }




            else {
               toast.warn("This wallet has already been added,If you want to switch Wallet address go to profile", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
               });
            }
         }
         else if (Cookies.get('type') === "guest") {

            localStorage.setItem('walletaddress', walletAddress);
            toast.success("wallet added successfully", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
            });

         }

      }
      else {
         // alert("Incorrect wallet address")
         toast.error("Incorrect wallet address", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
         });
      }

      console.log(walletAddress, "wallet adres")



   }


   

   // modals opening and closing
   const handleclosewallet = () => {
      dispatch(navwallet("wallet"))
      setshowaddwallet(false);
   }

   const handleuseguest = () => {

      setshowuserguest(false);
   }
   const payModePopupClose = () => {

      setpayModePopup(false);
   }


   const navigateWallet = () => {
      dispatch(navwallet("wallet"))
      Navigate("/profile")
   }

   const handleUserType = (type) => {
      console.log(type);
      if (type === "yes") {
         Cookies.set('type', "guest");

         //mode of payment ask and post payment
         // setpayModePopup(true);
         postPayment(false);
         handleuseguest();
      }
      else if (type === "no") {
         Cookies.set('type', "guest");
           //mode of payment ask and post payment
         postPayment2(false,"coinbase");
         handleuseguest();
         Navigate("/login")
      }

   }

const handlePayMode = (type) => {
console.log("type :", type)
postPayment(false,type);
payModePopupClose();
}
   


   //ignore pending when pending transaction there- function currently not used
   const setignorePendingfunc = (e) => {
      console.log("setignorepending", e)
      if (e === "yes") {
         postPayment(true);
      }
      setShowpending(false)
   }


   const handleCloselater = () => {
      setshowpaylater(false);
   }
   const handleshowwallet = () => setshowaddwallet(true);
   const handleshowlater = () => setshowpaylater(true);
   const handleClose = () => setShow(false);
   const handleClosepaypop = () => setpaypop(false);

   const handleShow = () => {
      console.log("to wallet address:0xh1282387463287", "from Wallet adress: current address need backend api")

      setShow(true);
   }
   const payNow = () => {

      if ((coinvalue?.data[round]?.total_tokens - coinvalue?.data[round]?.tokens_sold) - Token < 0) {
         alert("Token sales for the current round has been completed. Please wait for the next round to start. Thank you.")
      }
      else {
         if ((fromWallet == null || fromWallet === "" || fromWallet === undefined) && (localStorage.getItem("walletaddress") === undefined || localStorage.getItem("walletaddress") === null || localStorage.getItem("walletaddress") === "")) {

            toast.warn("Please Add a Receiving Wallet address to buy OZO", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
            });
            setConditionExecuted("ifconchecked");
         }
         else {
            setConditionExecuted("ifconcheckedamount");
            if (Token < 50 || Token===undefined) {

               toast.warn("Please enter minimum purchase quantity of 50 tokens to proceed", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
               });
               
            }
            else {
               if (Cookies.get('type') === "guest" && (localStorage.getItem("metamaskaddress") === undefined || localStorage.getItem("metamaskaddress") === null || localStorage.getItem("metamaskaddress") === "")) {

                  setshowuserguest(true)
               }
               else {
                  setpayModePopup(true)
                  // postPayment(false);
               }



            }

         }

      }


   }

   //verifying  payment in etherscan using transaction hash,old method
   const verifyPayment = async () => {
      console.log("verifypayment called !!")

      const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/0a38c92df0ee41fba804cd70dfebb0a9'));
      const USDT_CONTRACT_ADDRESS = '0xdAC17F958D2ee523a2206206994597C13D831ec7';

      // Create contract object
      const contract = new web3.eth.Contract(USDTabi, USDT_CONTRACT_ADDRESS);

      // Transaction hash of the USDT transfer  
      const txHash = "0xff676b5728b41c31c7d8b181a70dcedd2f2e71a4afe4885fad21c3414d341c93";
      // Fetch the transaction receipt
      web3.eth.getTransactionReceipt(txHash)
         .then(receipt => {

            if (!receipt) {
               console.log("Transaction not found");
               return;
            }
            // Get the events emitted by the contract
            contract.getPastEvents('Transfer', { fromBlock: receipt.blockNumber, toBlock: receipt.blockNumber }, (error, events) => {
               if (error) {
                  console.log(error);
               }
               else {

                  for (let i = 0; i < events.length; i++) {
                     // Check if the event is related to the transaction
                     if (events[i].transactionHash === txHash) {
                        console.log("From Address :", events[i].returnValues.from)
                        console.log("To Address :", events[i].returnValues.to)
                        console.log("Value Transferred :", (events[i].returnValues.value / 1000000), "USDT")
                        // settransStatus("paid")

                        updatePayment("paid");
                     }

                  }
               }
            });
         })
         .catch(error => {
            console.error(error);
         });
   }

   useEffect(() => {
      if (buyusdt > 0) {
         console.log("buyval", buyusdt)
         setusdt(buyusdt)
         setToken(buyusdt / coinvalue?.data[round]?.price_per_token);
      }
   }, [])


   // convert token
   // const convertToken = (e) => {
   //    const usdtValue = Number(e.target.value);
   //    if (isNaN(usdtValue) || usdtValue < 0) {
   //      setusdt(undefined);
   //      setToken(undefined);
   //    } else {
   //      setusdt(usdtValue);
   //      setToken(usdtValue / coinvalue?.data[round]?.price_per_token);
   //    }
   //  }
   //no of usd calculation
   //   const convertToUsd = (e) => {
   //    const tokenValue = Number(e.target.value); // convert input value to number
   //    if (isNaN(tokenValue) || tokenValue < 0) {
   //      setusdt(undefined)
   //      setToken(undefined);
   //    } else {
   //      setToken(tokenValue)
   //      setusdt(tokenValue * coinvalue?.data[round]?.price_per_token);
   //    }
   //  }

   const convertToken = (e) => {
      const usdtValue = e.target.value.trim() !== '' ? Number(e.target.value) : undefined;
      if (usdtValue === undefined || isNaN(usdtValue) || usdtValue < 0) {
         setusdt(undefined);
         setToken(undefined);
      } else {
         setusdt(usdtValue);
         setToken(usdtValue / coinvalue?.data[round]?.price_per_token);
      }
   }

   const convertToUsd = (e) => {
      const tokenValue = e.target.value.trim() !== '' ? Number(e.target.value) : undefined;
      if (tokenValue === undefined || isNaN(tokenValue) || tokenValue < 0) {
         setusdt(undefined);
         setToken(undefined);
      } else {
         setToken(tokenValue);
         setusdt(tokenValue * coinvalue?.data[round]?.price_per_token);
      }
   }
   function restrictDecimalDigits(e) {
      const input = e.target;
      const value = input.value;
      const indexOfDecimal = value.indexOf(".");
      if (indexOfDecimal >= 0 && value.substring(indexOfDecimal + 1).length > 2) {
         input.value = parseFloat(value).toFixed(2);
      }
   }


   // Post payment data to backend

   const postPayment = async (igpending,paytype) => {
      console.log("pay type",paytype)
      console.log(localStorage.getItem("walletaddresss"), "wallet address inside post", igpending)
      var currentDate = new Date();
      var options = {
         year: 'numeric',
         month: '2-digit',
         day: '2-digit',
         hour: '2-digit',
         minute: '2-digit',
         second: '2-digit',
         hour12: true
      };
      let timestamp_init = new Intl.DateTimeFormat('en-US', options).format(currentDate);
      console.log(timestamp_init, "timestamp initiate");
      try {
         setShow(true);
         await fetch(apiURI.URL + 'user_transaction/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest" ? "" : `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  user_id: Cookies.get('type') === "guest" ? 143 : user_id,
                  timestamp_submitted: "0",
                  timestamp_confirmed: "0",
                  timestamp_initiated: timestamp_init,
                  transaction_hash: transactionHash,
                  usdt_spent: usdt,
                  ozo_received: Token,
                  from_address: "",
                  to_address: fromWallet == null || fromWallet === undefined || fromWallet === "" ? localStorage.getItem("walletaddress") : fromWallet,
                  transaction_type: "Purchase",
                  tran_status: "pending",
                  round: roundId,
                  ignore_pending: igpending,
                  payment_type:localStorage.getItem("paytype"),
               }
            )
         })
            .then((response) => {
               const json = response.json();
               console.log("response data", json.object)
               return json;
            })
            .then(data => {
               console.log("create data", data?.message)
               // setcurrentTransactionno(data?.message)
               setShow(false);
               if (data?.message === "You have a pending transaction, please wait for it to complete.") {
                  // setShowpending(true)

                  postPayment(true,paytype);
               }
               else if (data?.message === "failed try again later") {
                  toast.warn("An error occured please try after sometime", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                  });
               }
               else {

                  // window.open(data?.message, '_blank');
                  setpaylink(data?.message);
                  setpaypop(true)
                  // Navigate("/transactions")
               }

            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
   }

   const postPayment2 = async (igpending,paytype) => {
      console.log("pay type",paytype)
      console.log(localStorage.getItem("walletaddresss"), "wallet address inside post", igpending)
      var currentDate = new Date();
      var options = {
         year: 'numeric',
         month: '2-digit',
         day: '2-digit',
         hour: '2-digit',
         minute: '2-digit',
         second: '2-digit',
         hour12: true
      };
      let timestamp_init = new Intl.DateTimeFormat('en-US', options).format(currentDate);
      console.log(timestamp_init, "timestamp initiate");
      try {
         setShow(true);
         await fetch(apiURI.URL + 'user_transaction/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest" ? "" : `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  user_id: Cookies.get('type') === "guest" ? 143 : user_id,
                  timestamp_submitted: "0",
                  timestamp_confirmed: "0",
                  timestamp_initiated: timestamp_init,
                  transaction_hash: transactionHash,
                  usdt_spent: usdt,
                  ozo_received: Token,
                  from_address: "",
                  to_address: fromWallet == null || fromWallet === undefined || fromWallet === "" ? localStorage.getItem("walletaddress") : fromWallet,
                  transaction_type: "Purchase",
                  tran_status: "pending",
                  round: roundId,
                  ignore_pending: igpending,
                  payment_type:localStorage.getItem("paytype"),
               }
            )
         })
            .then((response) => {
               const json = response.json();
               console.log("response data", json.object)
               return json;
            })
            .then(data => {
               console.log("create data", data?.message)
               // setcurrentTransactionno(data?.message)
               setShow(false);
               if (data?.message === "You have a pending transaction, please wait for it to complete.") {
                  postPayment2(true,paytype);
               }
               else if (data?.message === "failed try again later") {
                  toast.warn("An error occured please try after sometime", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                  });
               }
               else {

                  // window.open(data?.message, '_blank');
                  // setpaylink(data?.message);
                  localStorage.setItem('link', data?.message);
                  Navigate("/login")
                  // setpaypop(true)
                  // Navigate("/transactions")
               }

            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
   }

   const updatePayment = (a) => {
      console.log(a, "inside call")
      var currentDate = new Date();
      let timestamp_submitted = currentDate.toLocaleString()
      console.log(timestamp_submitted, "timestamp submit");

      try {
         fetch(apiURI.URL + 'user_transaction/', {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {

                  transaction_no: currentTransactionno,
                  timestamp_submitted: timestamp_submitted,
                  timestamp_confirmed: "0",
                  timestamp_initiated: "0",
                  transaction_hash: transactionHash,
                  ozo_received: Token,
                  usdt_spent: usdt,
                  from_address: fromWallet,
                  to_address: "",
                  transaction_type: "purchase",
                  tran_status: a === "paid" ? "paid" : "pending",
               }
            )
         })
            .then((response) => {

               const json = response.json();
               console.log("response data", json)
               return json;
            })

            .then(data => {
               console.log(data)
               if (data.message === "Transaction Updated successfully") {
                  alert("updated")

               }
               else if (data.message === "Transaction hash already exists") {
                  alert("Transaction hash already exists ,please provide valid transaction ID")
               }
               else if (data.message === "Transaction number and transaction hash cannot be empty") {
                  console.log("Transaction number and transaction hash cannot be empty")
               }
            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }
      settransStatus("")
   }




   // submit data
   const SubmitData = () => {
      setToken();
      setusdt(0);
      console.log("to wallet address:0xh1282387463287", "from Wallet adress: current address need backend api", "transaction hash:", transactionHash, "No of tokens & timestamp", Token,)
      // updatePayment ();
      verifyPayment();
      handleClose();
   }


   console.log(coinvalue, "token data from redux")

   const paymentViaMetaMask = async () => {
      if (window.ethereum) {
         const web3 = new Web3(window.ethereum);
         try {
            // Request access to the user's MetaMask account
            await window.ethereum.enable();
            // Switch to the Polygon network (network ID: 0x89)s
            if (await window.ethereum.chainId !== 0x1) {
               await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: "0x1" }],
               });
            }
            // Get the user's accounts
            const accounts = await web3.eth.getAccounts();
            const data = web3.eth.abi.encodeFunctionCall({
               name: 'transfer',
               type: 'function',
               inputs: [{
                  name: '_to',
                  type: 'address'
               }, {
                  name: '_value',
                  type: 'uint256'
               }]
            }, [paymentAddress, amount]);
            const gasPrice = await web3.eth.getGasPrice();
            const gasEstimate = await web3.eth.estimateGas({
               from: accounts[0],
               to: tokenAddress,
               data
            });
            console.log(gasEstimate, "Estimated Gas")
            console.log(gasPrice, "Estimated GasPrice")

            const tx = await web3.eth.sendTransaction({
               from: accounts[0],
               to: tokenAddress,
               data,
               gas: gasEstimate,
            });
            alert(`Successfully transferred ${amount} USDT! Transaction hash: ${tx.transactionHash}`);
         }
         catch (e) {
            console.log(e)
            if (e.code === -32000) {
               alert("Error 404 : Insufficient funds for gas")
            }
            else if (e.code === 4001 && e.message === "User rejected the request.") {
               alert("Please switch to the correct network before attempting to make a payment.")
            }
            else {
               alert(e.message)
            }
         }

      }
      else {
         alert("Please install MetaMask to continue to payment");
      }
   }
   const getWallet = () => {
      console.log(Cookies.get('type'), "Cookies")
      try {
         fetch(apiURI.URL + 'get_wallet_address/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest" ? "" : `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  user_id: Cookies.get('type') === "guest" ? 143 : user_id,

               }
            )
         })
            .then(response => response.json())

            .then(data => {
               console.log("all wallet data", data)
               setallWalletDetails(data);
               let currentWallet = data.find(object => object.current === true);
               setfromWallet(currentWallet.wallet_address)
              
            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }

   }

   useEffect(() => {

      getWallet();

   }, [])
   console.log("from redux id", roundId)

   return (

      <div className="page-content">
         <div className="container">
            <div className="row">
               <div className="main-content col-lg-7">
                  <div className="d-lg-none">
                     {/* <button className="btn btn-danger btn-xl btn-between w-100 mgb-1-5x" onClick={handleshowwallet}>Add your wallet address before buy <em className="ti ti-arrow-right"></em></button> */}
                     <div className="gaps-1x mgb-0-5x d-lg-none d-none d-sm-block">
                     </div>
                     <div >
                        {/* <p>0xh1234928734I6765</p> */}
                     </div>
                  </div>
                  <div className="content-area card">
                     <div className="card-innr">
                        <div className="card-head">
                           <h4 className="card-title" style={{ fontSize: "18px" }}>Enter the USD ($) amount to calculate the number of coins</h4>
                        </div>
                        <div className="card-text">
                           {coinvalue?.data[round]?.default_currency === "usdt" ?
                              <p>Currency : <span>USD ($)</span></p> :
                              "<p>Default Currency : <span>USDT</span></p>"}

                        </div>
                        <div className="card-text">
                           <p> Price Per OZO Coin : <span>{coinvalue?.data[round]?.price_per_token} $</span></p>
                        </div>
                        <div className="card-head">
                        </div>
                        <div className="card-text">
                        </div>
                        <div className="token-contribute">
                           <div className="token-calc">
                              <div className="token-pay-amount">
                                 <input id="token-base-amount" className="input-bordered input-with-hint" type="number" min={0} value={usdt} onChange={(e) => convertToken(e)} step="0.01" onInput={restrictDecimalDigits}></input>
                                 <div className="token-pay-currency"><span className="input-hint input-hint-sap">USD ($)</span></div>
                              </div>
                              <div className="token-received">
                                 <div className="token-eq-sign">=</div>
                                 <div className="token-received-amount">
                                    <div className="token-pay-amount">
                                       <input id="token-base-amount" className="input-bordered input-with-hint" type="number" min={0} value={Token} style={{ color: "black" }} onChange={(e) => convertToUsd(e)} step="0.01" onInput={restrictDecimalDigits}></input>
                                       <div className="token-pay-currency"><span className="input-hint input-hint-sap" style={{ color: "black" }}>OZO</span></div>
                                    </div>
                                    {/* <h5 className="token-amount">{Token === undefined || Token === null ? 0 : Token.toFixed(2)}</h5> */}
                                    {/* <div className="token-symbol ml-2">OZO</div> */}
                                 </div>
                              </div>
                           </div>
                           <div className="token-calc-note note note-plane">
                           </div>
                        </div>

                        <div className="token-overview-wrap">
                           <div className="token-overview">
                              <div className="row">

                                 <div className="col-md-12 text-center">
                                    <div className="token-total"><span className="token-overview-title font-bold">Total OZO</span><span className="token-overview-value token-total-amount text-primary">{Token === undefined ? 0 : Token.toFixed(2)}</span></div>
                                 </div>
                              </div>
                           </div>
                           <div className="note note-plane note-danger note-sm pdt-1x pl-0">
                           </div>
                        </div>
                        <div className="card-head mt-0">
                        </div>
                        <div>
                        <label for="token-address" class="input-item-label cs" >Add your Receiving Wallet Address below</label>
                           <div class="input-item input-with-label">
                              <div className='pr-2'>
                              
                              <input class="input-bordered" type="text" value={walletAddress} onChange={(e) => handleWalletAddress(e)}></input>
                                 <span class="input-note mb-2">Add only ERC-20 compatible (Metamask or Trust Wallet) addresses. Exchange wallet addresses should not be added.</span>
                              </div>
                              <div >
                              <a href="#"  className="btn btn-primary save" onClick={()=>{addWallet()}}>Save<em className="ti ti-arrow-right mgl-2x"></em></a>
                              </div>


                              </div>
                              </div>

                        <div className="card-text">
                           <ul className="d-flex flex-wrap align-items-center guttar-30px">
                              <li><a href="#" onClick={payNow} className="btn btn-primary" >Buy Ozo<em className="ti ti-arrow-right mgl-2x"></em></a></li>
                           </ul>
                        </div>
                        {/* <div className="pay-buttons">
                        </div> */}
                        {/* <div className="pay-notes">
                           <div className="note note-plane note-light note-md font-italic">
                           </div>
                        </div> */}
                                                <p style={{ fontSize: "14px", marginTop: "14px" }}>Make sure to enter exact intiated amount while making payment.</p>
                        <p style={{ fontSize: "14px", marginTop: "12px" }}>The OZO coins bought will be sent to the  receiving wallet address within 24 hours of purchase.</p>

                        <p style={{ fontSize: "14px", marginTop: "12px" }}>Kindly turn off the pop-up blockers in your browser settings to receive payment link.</p>
                        

                        
                     </div>
                  </div>
               </div>

               <div className="aside sidebar-right col-lg-5">
                  <div className="d-none d-lg-block">
                     {/* <button data-toggle="modal" data-target="#add-wallet" className="btn btn-danger btn-xl btn-between w-100" onClick={navigateWallet} >Add your wallet address <em className="ti ti-arrow-right"></em></button> */}
                     <div style={{ height: "auto", backgroundColor: "white", padding: "10px", marginTop: "10px", marginBottom: "10px", borderRadius: "8px", border: "solid 1px green" }}>
                        <label style={{ fontWeight: "bold", cursor: "pointer" }} onClick={navigateWallet}>Receiving Wallet Address</label><br></br><span style={{ wordWrap: "break-word" }}>{fromWallet == undefined || fromWallet == null || fromWallet == "" ? localStorage.getItem("walletaddress") : fromWallet}</span>
                        &nbsp;<em className="fas fa-info-circle text-exlight" data-toggle="tooltip" data-placement="bottom" title="The sozo will be received in this account"></em>
                     </div>
                  </div>
                  <div className="token-statistics card card-token height-auto mb-2">
                     <div className="card-innr">
                        {/* <div className="token-balance">
                           <div className="token-balance-text">
                              <h6 className="card-sub-title">Token Balance</h6>
                              <span className="lead">{coinvalue?.data[round]?.total_tokens - coinvalue?.data[round]?.tokens_sold} <span>SOZO</span></span>
                           </div>
                        </div> */}
                        <div className="token-balance token-balance-s2">
                           <h6 className="card-sub-title">Your Contribution</h6>
                           <ul className="token-balance-list">
                              <li className="token-balance-sub"><span className="lead"></span><span className="sub">{accountdata?.usdt_spent} USD ($)</span></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  {/* <div className="token-sales card">
                     <div className="card-innr">
                        <div className="card-head">
                           <h5 className="card-title card-title-sm">Token Supply Status</h5>
                        </div>
                        <ul className="progress-info">
                           <li><span>SOLD COINS</span> {coinvalue?.data[round]?.tokens_sold.toFixed(2)}</li>
                           <li className="text-right"><span>UNSOLD COINS</span> {(coinvalue?.data[round]?.total_tokens - coinvalue?.data[round]?.tokens_sold).toFixed(2)} </li>
                        </ul>
                        <ProgressBar now={((coinvalue?.data[round]?.tokens_sold) / coinvalue?.data[round]?.total_tokens) * 100}
                           variant="success" />
                        <div className="countdown-clock" data-date="2019/02/05"></div>
                     </div>
                  </div> */}
               </div>
            </div>
         </div>

         <div style={{ position: "fixed", padding: "100px", display: show === true ? "flex" : "none", justifyContent: "center", width: "100%", height: "100%", backgroundColor: "#80808063", top: "0px", left: "0px" }}>
            <Lottie
               options={defaultOptions}
               height={300}
               width={300}
               style={{ marginTop: "10%" }} />
         </div>


         <Modal show={paypop}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body>
               <div style={{ padding: "20px", justifyContent: "center", textAlign: "center" }}>
                  <p style={{ fontSize: "17px" }}>Please click OK to proceed with payment
                  </p>
                  <br></br>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                     <a href={paylink} className="btn btn-primary" style={{ width: "80px" }} onClick={handleClosepaypop} target="_blank">OK</a>

                  </div>
               </div>
            </Modal.Body>
         </Modal>

         <Modal show={showpending} onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body>
               <div style={{ padding: "20px", justifyContent: "center", textAlign: "center" }}>
                  <p style={{ fontSize: "17px" }}>There are pending transactions. Do you wish to proceed with this order?
                  </p>
                  <br></br>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                     <button className="btn btn-primary" onClick={() => setignorePendingfunc("yes")} style={{ width: "80px" }}>Yes</button>
                     <button className="btn btn-primary ml-2" onClick={() => setignorePendingfunc("no")} style={{ width: "80px" }}>No</button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>


         <Modal show={showpaylater} onHide={handleCloselater}>
            <Modal.Body>
               <div className="modal-content">
                  <div className="popup-body text-center">
                     <div className="gaps-2x"></div>
                     <div className="pay-status pay-status-success"><em className="ti ti-check"></em></div>
                     <div className="gaps-2x"></div>
                     <h3>Your Order has placed Successfully !!</h3>
                     <ul className="pay-list guttar-20px">
                     </ul>
                     <div className="gaps-2x"></div>
                     <Link to="/transactions"><a href="" className="btn btn-primary">Close</a></Link>
                     <div className="gaps-1x"></div>
                  </div>
               </div>
            </Modal.Body>
         </Modal>



         <Modal show={showaddwallet} onHide={handleclosewallet}>
            <Modal.Body>
               <div className="modal-content">
                  <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={handleclosewallet}><em className="ti ti-close"></em></a>
                  <div className="popup-body">
                     <form action="#">
                        <div className="row">
                        </div>
                        <div className="input-item input-with-label">
                           <label for="token-address" className="input-item-label">Your Address for tokens:</label>
                           <input className="input-bordered" type="text" id="token-address" name="token-address" value="0xde0b295669a9fd93d5f28d9ec85e40f4cb697bae"></input>
                           <span className="input-note">Note: Address should be ERC20-compliant.</span>
                        </div>
                        <div className="gaps-3x"></div>
                        <div className="d-sm-flex justify-content-between align-items-center">
                           <Link to="/profile"><button className="btn btn-primary" onClick={handleclosewallet}>Add Wallet</button></Link>
                           <div className="gaps-2x d-sm-none"></div>
                        </div>
                     </form>
                  </div>
               </div>
            </Modal.Body>

         </Modal>

         <Modal show={showuserguest} onHide={handleuseguest}>
            <Modal.Body>
               <div className="modal-content">
                  <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={handleclosewallet}><em className="ti ti-close"></em></a>
                  <div className="popup-body">

                     <div className="row">
                     </div>
                     <div className="input-item input-with-label">

                        <p style={{ fontSize: "17px" }}>Do you want to buy OZO coins without registration ?
                        </p>

                     </div>

                     <div style={{ display: "flex" }}>
                        <button className="btn btn-primary mr-2" onClick={() => handleUserType("yes")}>Yes</button>
                        <button className="btn btn-primary" onClick={() => handleUserType("no")}>No</button>
                     </div>




                  </div>
               </div>
            </Modal.Body>

         </Modal>
         <Modal show={payModePopup} onHide={payModePopupClose}>
            <Modal.Body>
               <div className="modal-content">
                  <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={payModePopupClose}><em className="ti ti-close"></em></a>
                  <div className="popup-body">
                     <div className="row">
                     </div>
                     <div className="input-item input-with-label">
                        <p style={{ fontSize: "17px" }}>How do you like to make payment?
                        </p>
                     </div>

                     <div style={{ display: "flex" }}>
                        <button className="btn btn-primary mr-2" onClick={() => handlePayMode("coinbase")}>Coinbase</button>
                        <button className="btn btn-primary" onClick={() => handlePayMode("paypal")}>Paypal</button>
                     </div>
                  </div>
               </div>
            </Modal.Body>

         </Modal>


         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />

         <ToastContainer />


<p style={{color:"transparent"}}>{conditionExecuted}</p>
      </div>





   );
}

export default Buyozowrapper;
