
import React from 'react';
import Topbar from "../topbar"
import Navbar from "../navbar"
import "../assets/css/style.css"
import "../assets/css/semi-dark.css"
import "../assets/css/customize.css"
import "./wallet-admin.css"
import Walletwrapperadmin from "./walletwrapper-admin"
import { useState, useEffect } from 'react';

import Web3 from 'web3';


function Walletadmin() {

   const [style, setstyle] = useState('');
   const [displaymobnav, setdisplaymobnav] = useState('');
   const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
   const [walletAddress, setWalletAddress] = useState("0x3b1e20Aea65D5dC0b7948258f3B0b41618a96c6b")
   const [ozoValue, setOzoValue] = useState(0);
   const [sozoValue, setSozoValue] = useState(0)

   useEffect(() => {
      const handleResize = () => setViewportWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   useEffect(() => {
      if (viewportWidth < 1024) {
      setstyle("-300px")
      }
      else {
         setstyle("0px")
      }


   }, [viewportWidth]);
   const handleButtonClick = () => {
      console.log("clicked")
      if (viewportWidth < 1024) {
         console.log("check if")

         setstyle(prevStyle => prevStyle === "-300px"? "0px" : "-300px")

      }
   };

   useEffect(() => {
      console.log("Use Effect Wallet Admin")

      async function setData (){
         await checkBalance()
         await checkTokenBalance()
      }
      setData(); 
      
   },[])

   const checkBalance = async ()=> {
      const web3 = new Web3('https://node1.testnet.ozonechain.io/');
      const balance = await web3.eth.getBalance(walletAddress);
      const ozoValue =  parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(5);
      setOzoValue(ozoValue)



      }
   const checkTokenBalance = async () => {
         const web3 = new Web3('https://node1.testnet.ozonechain.io/');
         const balanceAbi = [  {
            "type": "function",
            "name": "balanceOf",
            "inputs": [
              {
                "type": "address",
                "name": "account",
                "internalType": "address"
              }
            ],
            "outputs": [
              {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
              }
            ],
            "stateMutability": "view"
          },]
         const tokenContract = new web3.eth.Contract(balanceAbi, "0x795a5fd0484B36837BC8F78f020316cF035A90A7");
         const balance = await tokenContract.methods.balanceOf(walletAddress).call();
         const tokenValue = parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(5);;
         setSozoValue(tokenValue);
         }

         const refreshData = async() =>{
            console.log("Data Refreshed");
            alert("Balance Updated Successfully ")
            await checkBalance();
            await checkTokenBalance();
         }

   return (
      <div className="wrapper">
   <Topbar handleButtonClick={handleButtonClick}></Topbar>
            <Navbar setstyle={setstyle}
            style={style}
            handleButtonClick={handleButtonClick}
            ></Navbar>
            <Walletwrapperadmin
            walletAddress={walletAddress}
            sozoValue={sozoValue}
            ozoValue={ozoValue} 
            refreshData = {refreshData}   
            />
         </div>
    

   );
}

export default Walletadmin;
