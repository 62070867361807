
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import {  Link } from 'react-router-dom';
import { BiCopy,BiLink } from 'react-icons/bi';
import { useCopyToClipboard } from "usehooks-ts";
import { useNavigate } from 'react-router-dom';
import { navwallet } from '../../reducers/ConstantSlice'
import { ToastContainer, toast } from 'react-toastify';
import {apiURI} from "./../../config/config"
import Cookies from 'js-cookie'
function Accountwrapper() {
   const [fromWallet, setfromWallet] = useState("")
   const [accountToken, setaccountToken] = useState()
   const user_id = useSelector((state) => state.constVar.user_id)
   const refferalcode = useSelector((state) => state.constVar.refferalcode)
   const accountdata = useSelector((state) => state.constVar.accountdata)
   const [showReferral, setshowReferral] = useState(false)
   const [value, copy] = useCopyToClipboard();
   let Navigate = useNavigate();
   let dispatch=useDispatch();

   //API fetch for getting current set wallet address
   const getWallet = () => {
   
      try {
         fetch(apiURI.URL+'get_wallet_address/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  "user_id": user_id,

               }
            )
         })
            .then(response => response.json())

            .then(data => {
               console.log("all wallet data", data)
               let currentWallet = data.find(object => object.current === true);
               setfromWallet(currentWallet.wallet_address)
            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }

   }

//API fetch for getting Tokens purchase details
   const accountDetails = () => {
   
      try {
         fetch(apiURI.URL+'myaccount/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  "user_id": user_id,

               }
            )
         })
            .then(response => response.json())

            .then(data => {
               console.log("Account token details", data)
               setaccountToken(data);
      
            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }

   }
   useEffect(() => {

      getWallet();
      accountDetails();
      // setaccountToken(accountdata);

   }, [])

   const generateReferral = () => {
      if(fromWallet!= null && fromWallet!=="" && fromWallet!==undefined)
      {
         setshowReferral(true)
      }
      else{
         alert("Please add a wallet address to your profile to generate referal link")
      }
      
         }
         const navigateWallet = () => {
            dispatch(navwallet("wallet"))
            Navigate("/profile")
         }
         const navBuyozo = () => {
       
       
            Navigate('/buyozo');
         }
         const copied = () =>{
            toast.success("Copied Successfully", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
            });
         }

  return (
    
   <div className="page-content">
   <div className="container">
      <div className="row">
         <div className="main-content col-lg-8">
            <div className="content-area card" style={{background:""}}>
               <div className="card-innr">
                  <div className="card-head">
                     <h4 className="card-title">Account</h4>
                  </div>


                  <div className="card-bordered" style={{border:"0px solid",background:"#f6fff3"}}>

                     <div className="card-innr">
                        <div className="row guttar-vr-15px align-items-center">
                           <div className="col-md-8 p-0">
                              <div className="total-block">
                                 <h6 className="total-title-sm" style={{fontSize:"14px",color:"green",fontWeight:"400"}}>Coin Balance</h6>
                                 <span className="total-amount-lead"style={{fontSize:"14px",color:"black"}}>{accountToken?.token_balance===undefined||accountToken?.token_balance===null?0:accountToken?.token_balance.toFixed(2)}<span className="color-green"> OZO</span>
                                 </span>
                                 {/* <p className="total-note">Equivalent to <span>0 USDT</span></p> */}
                              </div>
                           </div>
                           {/* <div className="col-md-8 ">
                              <Link to="/buyozo"><button  className="btn btn-primary">Buy Token</button></Link>
                           </div> */}
                        </div>
                     </div>
                     <div className="sap sap-light"></div>
                     <div className="card-innr">
                        {/* <div className="total-block">
                           <h5 className="total-title-sm">Total Token Amount</h5>
                           <span className="total-amount">0  OZO</span>
                        </div> */}
                        <div className="total-block">
                           <ul className="list total-wg">
                              <li>
                                 <span className="total-title-xs" style={{fontSize:"14px",color:"green",fontWeight:"400"}}>Purchased Coins&nbsp;:&nbsp;</span>
                                 <span className="total-amount-sm"style={{fontSize:"14px",color:"black",fontWeight:"400"}}>{accountToken?.purchased_token===undefined||accountToken?.purchased_token===null?0:accountToken?.purchased_token.toFixed(2)} OZO</span>
                              </li>
                              <li>
                                 <span className="total-title-xs" style={{fontSize:"14px",color:"green",fontWeight:"400"}}>Referral Coins&nbsp;:&nbsp;</span>
                                 <span className="total-amount-sm"style={{fontSize:"14px",color:"black",fontWeight:"400"}}>{accountToken?.token_referral===undefined||accountToken?.token_referral===null?0:accountToken?.token_referral.toFixed(2)} OZO</span>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="sap sap-light"></div>
                     <div className="card-innr">
                        <div className="total-block">
                           <h5 className="total-title-sm" style={{fontSize:"14px",color:"green",fontWeight:"400"}}>Total Contributed</h5>
                           <span className="total-amount"style={{fontSize:"14px",color:"black",fontWeight:"400"}}>{accountToken?.usdt_spent===undefined||accountToken?.usdt_spent===null?0:accountToken?.usdt_spent.toFixed(2)} USD ($)</span>
                        </div>
                     </div>
           
                  </div>
                  <button className="btn btn-primary mt-4" onClick={navBuyozo}>BUY OZO</button>
               </div>
            </div>
             
         </div>
 
         <div className="aside sidebar-right col-lg-4">
            <div className="account-info card">
               <div className="card-innr">
                  {/* <h6 className="card-title card-title-sm">Your Account Status</h6>
                  <ul className="btn-grp">
                  <li><p className="btn btn-auto btn-xs btn-success" style={{cursor:"default"}}>Email Verified</p></li> */}
                    
                  {/* </ul> */}
                  <div className="gaps-2-5x"></div>
                  <h6 className="card-title card-title-sm" style={{cursor:"pointer"}} onClick={navigateWallet}>Receiving Wallet Address</h6>
                  <div className="d-flex justify-content-between"><span><span style={{wordBreak:"break-word"}}  >{fromWallet}</span> 
                  &nbsp;&nbsp;<em className="fas fa-info-circle text-exlight" data-toggle="tooltip" data-placement="bottom" title="The sozo will be received in this account"></em>
                  </span>
                  {/* <Link to="/profile"><p className="link link-ucap">Edit</p></Link> */}
                  </div>
               </div>
            </div>
            <div className="referral-info card">
                     <div className="card-innr">
                        <h6 className="card-title card-title-sm">Earn with Referral</h6>
                        <p className=" pdb-0-5x">Invite your friends and receive referral tokens.</p>

                        {showReferral?
                        <div  className="copy-wrap mgb-0-5x" onClick={() => copy( "https://dev.icoapp.frontend.ozonechain.io/signup?id=" + refferalcode )} style={{ 
                      display:"flex",border : "2px solid #e3e3e3", width : "100%",margin : "0", padding:"5px", cursor:"pointer"}}>
                    <BiLink/>&nbsp;&nbsp;

                    <p style={{margin:"0",width:"100%",overflowY:"hidden"}} >https://dev.icoapp.frontend.ozonechain.io/signup?id={refferalcode}
                    &nbsp;<div style={{float:"right"}} className="copy-trigger copy-clipboard"><BiCopy  onClick={copied}/></div>  
                    {/* <button onClick={() => copy( referralCode )}><BiCopy/></button> */}
                      </p> 
                    </div>:<></>}
                    <button className="btn btn-primary " onClick={generateReferral}>Generate referral link</button>
                    <p className='mt-2' style={{fontWeight:"500"}}>The referral OZO coins will be distributed 30 days after the Token Generation Event (TGE).</p>
                        {/* <div className="copy-wrap mgb-0-5x"><span className="copy-feedback"></span><em className="fas fa-link"></em><input type="text" className="copy-address" value="https://ozone.io/" disabled></input><button className="copy-trigger copy-clipboard" data-clipboard-text="https://demo.themenio.com/ico?ref=7d264f90653733592"><em className="ti ti-files"></em></button></div> */}

                     </div>
                     <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
         {/* Same as */}
         <ToastContainer />
                  </div>
            
         </div>
    
      </div>

   </div>

</div>

       
    
             
  );
}

export default Accountwrapper;
