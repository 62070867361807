import React from "react";
import { useState, useEffect } from 'react';
import "./checktransaction.css"
import {apiURI} from "./../../config/config"
import Cookies from 'js-cookie'
function Checktransactionwrapper() {
    const [transData, settransData] = useState()

    const getTransactions = () => {
   if(localStorage.getItem('walletaddress')==null||localStorage.getItem('walletaddress')==undefined||localStorage.getItem('walletaddress')=="")
   {
console.log("no data available")
   }
   else{
    try {
        fetch(apiURI.URL+'search_transaction/', {
           method: 'POST',
           headers: {
              'Content-Type': 'application/json',
              'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
           },
           body: JSON.stringify(
             {
                 "wallet_address": localStorage.getItem('walletaddress')
                 }
           )
        })
           .then(response => response.json())

           .then(data => {
       console.log("data",data)
       settransData(data);
           })
           .catch(error => console.error(error));
     }
     catch (error) {
        console.log(error, "error");
     }
   }
        
  
     }

    useEffect(() => {

        getTransactions();

     }, [])
   
   return (

      <>
 <div className="page-content">
   <div className="container">
      <div className="row">
      <div className="main-content col-lg-12">
            <div className="content-area card" style={{background:""}}>
               <div className="card-innr">
               <h4 className="card-title mb-2">Your Transactions</h4>
                  <p className="mb-4" style={{color:"grey"}}>Add you Receiving Wallet Address to view transactions</p>
                  <div className="card-head" style={{overflowY:"scroll"}}>

                  <table className="data-table dt-init user-tnx" >
                        <thead>
                           <tr className="data-item data-head">
                              <th className="data-col dt-tnxno">Tranx NO</th>
                              <th className="data-col dt-tnxno">Funding&nbsp;&nbsp;</th>
                              <th className="data-col dt-tnxno">Coins&nbsp;</th>

                              <th className="data-col dt-tnxno">Payment Req</th>
                              <th className="data-col dt-tnxno"> Payment done</th>
                              <th className="data-col dt-tnxno">Receiving Wallet Address</th>
                              <th className="data-col dt-tnxno">
                                 <div className="dt-type-text">Type</div>
                              </th>

                              <th className="data-col dt-tnxno">STATUS</th>
                              <th className="data-col"></th>
                              {/* <th className="data-col">Actions</th> */}

                           </tr>
                        </thead>
                        <tbody >
                           {
                              transData?.length > 0 &&
                              transData?.map((i, index) => (

                                <tr className="data-item">
                                <td className="data-col ">
                                   <div className="d-flex align-items-center">
                                      <div className="fake-class"><span className="lead tnx-id">{i?.transaction_no}</span><span className="sub sub-date">{i?.timestamp_initiated}</span></div>
                                   </div>
                                </td>
                                <td className="data-col"><span className="lead token-amount">{i?.funding_type}</span></td>
                                <td className="data-col"><span className="lead token-amount">{i?.ozo_received.toFixed(2)}</span><span className="sub sub-symbol">OZO</span></td>
                                {/* {(coinvalue?.data[0]?.price_per_token)*i?.send_tokens} */}
                                <td className="data-col"><span className="lead amount-pay">{i?.usdt_spent.toFixed(2)}</span><span className="sub sub-symbol">USD ($) </span></td>
                                <td className="data-col"><span className="lead user-info"> {i?.paid_amount===undefined||i?.paid_amount===null?0:i?.paid_amount.toFixed(2)} $</span><span className="sub sub-date"></span></td>
                                
                                <td className="data-col"><span className="lead user-info">{i?.to_address.substring(0, 8)}.....{i?.to_address.substring(38, 42)}</span><span className="sub sub-date"></span></td>
                                <td className="data-col"><span className="lead user-info">{i?.transaction_type}</span><span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">P</span></td>
                                
                                {(i?.coinbase_status === "NEW" ||i?.coinbase_status === "PENDING"  ||i?.status==="pending")? 
                                <td className="data-col"><span className="badge badge-warning ucap" >Pending</span></td>
                                 :i?.coinbase_status === "COMPLETED" && i?.status === "paid"? 
                                <td className="data-col"><span className="badge badge-success ucap">Paid</span></td>
                                :i?.coinbase_status === "UNRESOLVED" && i?.status === "paid"? 
                                <td className="data-col"><span className="badge badge-success ucap">Paid</span></td>
                                :i?.coinbase_status === "COMPLETED" && i?.status === "transferred"? 
                                <td className="data-col"><span className="badge badge-info ucap">Transferred</span></td>
                                :<td className="data-col"><span className="badge badge-danger ucap">{i?.coinbase_status}</span></td>
                                }
                                
                                <td className="data-col">
                                   <ul className="data-action-list  d-md-inline-flex">
                                   {(i?.coinbase_status === "COMPLETED" ||i?.coinbase_status === "EXPIRED" ||i?.coinbase_status === "CANCELED" ||i?.coinbase_status === "REFUNDED"||i?.coinbase_status === "PARTIALLY_REFUNDED"|| i?.coinbase_status === "UNRESOLVED")  ?
                                   <></>
                                   :   
                                   (i?.coinbase_status === "PENDING" ||i?.coinbase_status === "NEW"||i?.coinbase_status === "RESOLVED"  ||i?.status==="pending")&&(i?.transaction_type!=="Referral") ?                           
                                   <li><a href={"https://commerce.coinbase.com/charges/"+i?.coinbase_charge_id} className="btn btn-auto btn-primary btn-xs" style={{width:"auto"}} target="_blank" rel="noreferrer"><span>Pay <span className="d-none d-xl-inline-block">Now</span></span><em className="ti ti-wallet"></em></a></li>
                                  :
                                  <></>
                                   }
     
     
                                   </ul>
                                </td>
                                {/* <td className="data-col">
                                <ul className="data-action-list  d-md-inline-flex">
                                <li>
                                   <button  onClick={() => {viewTransaction(i?.transaction_no)}} className="btn btn-light-alt btn-xs btn-icon">
                                      <em className="ti ti-eye"></em>
                                   </button>
                                   </li>
                                   {i?.status==="pending"?
                                                                    <li onClick={() => {showDelete(i?.transaction_no)}}>
                                                                    <p class="btn btn-danger-alt btn-xs btn-icon"><em class="ti ti-trash"></em></p></li>:""}
     
                                      </ul>
                                </td> */}
                             </tr>
                              ))
                           }



                        </tbody>
                     </table>
  
    
      </div>
      </div>
      </div>
      </div>
      </div>

   </div>

</div>

       
      </>



   );
}

export default Checktransactionwrapper;
