import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import {apiURI} from "./../../../config/config"
import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie'
function Password() {
   const [oldPassword, setOldPassword] = useState("");
   const [newPassword, setNewPassword] = useState("");
   const [newPasswordConfirm, setnewPasswordConfirm] = useState("")
   const [confstate, setconfstate] = useState(true)
   const [passwordType, setPasswordType] = useState("password");
   const [passwordTypecn, setPasswordTypecn] = useState("password");
   const [passwordTypecn2, setPasswordTypecn2] = useState("password");
   const user_id = useSelector((state) => state.constVar.user_id)

   const togglePassword = () => {
      if (passwordType === "password") {
         setPasswordType("text")
         return;
      }
      setPasswordType("password")
   }
   const togglePasswordcn = () => {
      if (passwordTypecn === "password") {
         setPasswordTypecn("text")
         return;
      }
      setPasswordTypecn("password")
   }

   const togglePasswordcn2 = () => {
      if (passwordTypecn2 === "password") {
         setPasswordTypecn2("text")
         return;
      }
      setPasswordTypecn2("password")
   }

   const submitPassword = () => {


if(oldPassword === ""|| oldPassword === undefined || oldPassword === null)
{
alert("Please enter your old password")
}
else{
   if (newPassword == newPasswordConfirm) {
      setconfstate(true)
      try {
         fetch(apiURI.URL+'change_password/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`},
            body: JSON.stringify(
               {
                  user_id: Cookies.get('type')==="guest"?143:user_id,
                  "old_password": oldPassword,
                  "new_password": newPassword,
               }
            )
         })
            .then((response) => {

               const json = response.json();
               console.log("response data", json)
               return json
            })

            .then(data => {
               console.log(data, "password")
               if (data.message === "Old password is incorrect") {
                  alert("your old password is incorrect")
               }
               else {
                  alert("updated successfully")
               }


            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }


   }
   else {
      setconfstate(false)
   }

}

   }

   return (

      <div className="tab-pane show active" id="password">
         <div className="row">
            <div className="col-md-6">
               <div className="input-item input-with-label" >
                  <label for="old-pass" className="input-item-label" >Old Password</label>
                  <div style={{display:"flex"}}>
                  <input className="input-bordered" type={passwordType} onChange={(e) => { setOldPassword(e.target.value) }} placeholder='***********' tabIndex={1}></input>
                  <div className="input-group-btn">
                     <button className="" onClick={togglePassword} style={{ height: '100%', borderRadius: '0 4px 4px 0',width:"50px",padding:"3px",border:"solid 1px grey",color:"grey",backgroundColor:"transparent" }}>
                        {passwordType === "password" ? <IconEyeOff /> : <IconEye />}
                     </button>
                  </div>

                  </div>
               
               </div>

            </div>

         </div>

         <div className="row">
            <div className="col-md-6">
               <div className="input-item input-with-label" >
                  <label for="new-pass" className="input-item-label">New Password</label>
                  <div  style={{display:"flex"}}>
                  <input className="input-bordered" type={passwordTypecn} onChange={(e) => { setNewPassword(e.target.value) }} tabIndex={2}></input>
                  <div className="input-group-btn">
                     <button className="" onClick={togglePasswordcn} style={{ height: '100%', borderRadius: '0 4px 4px 0',width:"50px",padding:"3px",border:"solid 1px grey",color:"grey",backgroundColor:"transparent" }}>
                        {passwordTypecn === "password" ? <IconEyeOff /> : <IconEye />}
                     </button>
                  </div>
                  </div>
              
               </div>

            </div>

            <div className="col-md-6">
               <div className="input-item input-with-label">
                  <label for="confirm-pass" className="input-item-label">Confirm New Password</label>
                  <div  style={{display:"flex"}}>
                  <input className="input-bordered" type={passwordTypecn2} onChange={(e) => { setnewPasswordConfirm(e.target.value) }} tabIndex={3}></input>
                  <div className="input-group-btn">
                     <button className="" onClick={togglePasswordcn2} style={{ height: '100%', borderRadius: '0 4px 4px 0',width:"50px",padding:"3px",border:"solid 1px grey",color:"grey",backgroundColor:"transparent" }}>
                        {passwordTypecn2 === "password" ? <IconEyeOff /> : <IconEye />}
                     </button>
                  </div>
                  </div>
              
                  
                  {confstate ? <></> : <p style={{ color: "red", fontSize: "12px", marginTop: "10px" }}>Confirm password does not match new password</p>}
               </div>

            </div>

         </div>

         <div className="note note-plane note-info pdb-1x">
            <em className="fas fa-info-circle"></em>
            <p>Password should be minimum 8 letter and include lower and uppercase letter.</p>
         </div>
         <div className="gaps-1x"></div>

         <div className="d-sm-flex justify-content-between align-items-center">
            <button className="btn btn-primary" onClick={() => { submitPassword() }}>Update</button>
            <div className="gaps-2x d-sm-none"></div>
            {/* <span className="text-success"><em className="ti ti-check-box"></em>  Changed Password</span> */}
         </div>
      </div>







   );
}

export default Password;
