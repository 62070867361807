// import React, { useState } from 'react'

// import { useAddress, useDisconnect } from "@thirdweb-dev/react";

// import { Web3Button } from "@thirdweb-dev/react";
// import { ethers } from 'ethers';
// import Web3 from 'web3';
// import { useSelector } from 'react-redux';
// import { apiURI } from '../../config/config';
// import Cookies from 'js-cookie'

// const VestingWallet = ({ OZOValue, getApi }) => {

//   const currentaddress = useSelector((state) => state.constVar.currentwallet)
//   const user_id = useSelector((state) => state.constVar.user_id)
//   const [status, setStatus] = useState("");

//   const address = useAddress();
//   const disconnect = useDisconnect();


//   const checkBalance = async (value) => {
//     const web3 = new Web3('https://node1.testnet.ozonechain.io/');
//     const balance = await web3.eth.getBalance("0x3b1e20Aea65D5dC0b7948258f3B0b41618a96c6b");
//     const ozoValue = parseFloat(web3.utils.fromWei(balance, 'ether')) * 2;
//     return ozoValue;
//   }


//   const verifyTransaction = async (contract) => {

//     // console.log("Connected Wallet Address Info :",contract.events.contractWrapper.signer._address)
//     // console.log("Address : ", currentaddress,address)

//     if (OZOValue >= await checkBalance(OZOValue)) {
//       alert("Please Try Again After Few Hours")
//     }
//     else if (address !== currentaddress) {
//       alert("Incorrect Wallet Address Connected")
//       disconnect();
//     }
//     else if (OZOValue <= 1) {
//       alert("Minimum 1 OZO Required")
//     }
//     else {
//       const amount = ethers.utils.parseEther(OZOValue.toString());
//       // console.log("Amount",amount)

//       const response = await contract.call("burn", [amount]);

//       console.log(response.receipt, "Receipt ");
//       if (response.receipt.status === 1) {

//         console.log(response.receipt.status, "Transaction status");
//         console.log("From :", response.receipt.events[0].args[0]);
//         console.log("To :", response.receipt.events[0].args[1]);
//         console.log("Value :",
//           ethers.utils.formatEther(
//             (response.receipt.events[0].args[2]._hex.toString())
//           )
//         );
//         // Add any other verification code here
//         console.log("Burn Hash ", response.receipt.transactionHash)
//         setStatus("burnt")
//         const id = await createTransaction("response.receipt.transactionHash");
//         await transferOZO(amount, id)
//         // console.log("id received",id)
//       }
//     }

//   };

//   const transferOZO = async (amount, id) => {
//     const web3 = new Web3('https://node1.testnet.ozonechain.io/');
//     const privateKey = '1db528ef49379554cf982f54eb5210644613d1673acb3f1920768fd5ca1d50f5';
//     const account = web3.eth.accounts.privateKeyToAccount(privateKey);

//     // const recipient = "0xCc6B7586f20EdB5273099eF93495Ec8cf3aA3796"; 
//     const recipient = address;
//     // const amount = web3.utils.toWei('1', 'ether');
//     const tx = {
//       from: account.address,
//       to: recipient,
//       value: amount,
//       gas: 21000, // Gas limit
//       gasPrice: '20000000000', // Default gas price in wei
//       nonce: await web3.eth.getTransactionCount(account.address, 'pending'),
//     };

//     // Get the current gas price from the network
//     const gasPrice = await web3.eth.getGasPrice();
//     // console.log(gasPrice,"gasPrice");
//     // Estimate gas required for the transaction
//     const gasEstimate = await web3.eth.estimateGas(tx);
//     // console.log(gasEstimate,"gasEstimate");
//     // Set the gas limit and gas price for the transaction
//     tx.gas = gasEstimate;
//     tx.gasPrice = gasPrice; // Use current gas price in wei

//     //   Sign and send the transaction
//     const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);
//     web3.eth.sendSignedTransaction(signedTx.rawTransaction)
//       .then(async (receipt) => {
//         // console.log(receipt,"Transfer Receipt");
//         // console.log(receipt.transactionHash,"Txn Hash")
//         await updateTransaction(receipt.transactionHash, id);

//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }


//   const createTransaction = async (txn_hash) => {
//     const formattedDate = new Date().toISOString().slice(0, 16).replace('T', ' ');
//     try {
//       return await fetch(apiURI.URL + 'create_vesting_history/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${localStorage.getItem('token')}`
//         },
//         body: JSON.stringify(
//           {
//             user_id: Cookies.get('type')==="guest"?143:user_id,
//             wallet_address: address,
//             ozo_claimed: OZOValue,
//             txn_hash: "Pending",
//             burn_hash: txn_hash,
//             date: formattedDate,
//           }
//         )
//       })
//         .then((response) => {
//           const json = response.json();
//           return json;
//         })
//         .then(data => {
//           console.log("Vesting Data", data.success)
//           // alert(` ${OZOValue} SOZO's Burnt Successfully`)
//           return (data.success)
//         })
//         .catch(error => console.error(error));
//     }
//     catch (error) {
//       console.log(error, "error");

//     }
//   }

//   const updateTransaction = async (txn_hash, id) => {
//     try {
//       await fetch(apiURI.URL + 'update_vesting_history/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${localStorage.getItem('token')}`
//         },
//         body: JSON.stringify(
//           {
//             txn_id: id,
//             user_id: Cookies.get('type')==="guest"?143:user_id,
//             txn_hash: txn_hash,
//           }
//         )
//       })
//         .then((response) => {
//           const json = response.json();
//           return json;
//         })
//         .then(data => {
//           // console.log("Vesting Data", data.message)
//           // alert(` ${OZOValue} OZO Transferred Successfully`)
//           getApi()

//         })
//         .catch(error => console.error(error));
//     }
//     catch (error) {
//       console.log(error, "error");
//     }
//   }


//   const addToken = async () => {
//     console.log("Add Token Clicked")
//     await window.ethereum.request({
//       method: 'wallet_watchAsset',
//       params: {
//         type: 'ERC20',
//         options: {
//           address: "0x795a5fd0484B36837BC8F78f020316cF035A90A7",
//           symbol: 'SOZO',
//           decimals: 18,
//           image: 'https://ozonechain.io/static/images/favicon.png',
//         },
//       },
//     });
//   }

//   const addNetwork = async () => {
//     console.log("Add Network Clicked")
//     await window.ethereum.request({
//       method: 'wallet_addEthereumChain',
//       params: [
//         {
//           chainId: '0x191', // Chain ID of the network you want to add
//           chainName: 'Ozone Chain Testnet', // Name of the network
//           nativeCurrency: {
//             name: 'OZO',
//             symbol: 'OZO',
//             decimals: 18,
//           },
//           rpcUrls: ['https://node1.testnet.ozonechain.io/'], // RPC endpoint of the network
//           blockExplorerUrls: ['https://testnet.ozonescan.io/'], // Block explorer URL
//         },
//       ],
//     });
//   }




//   return (
//     <div>
//       {status === "" ?
//         <Web3Button
//           contractAddress="0x795a5fd0484B36837BC8F78f020316cF035A90A7"
//           action={async (contract) => {
//             try {
//               await verifyTransaction(contract);
//             } catch (error) {
//               console.log(error, "Error");
//               alert("User Rejected the Signature ")
//               // Handle the rejection here, for example by displaying an error message to the user
//             }
//           }}
//           theme='light'
//         // className='btn btn-primary mb-1'
//         >
//           Burn {OZOValue} SOZO
//         </Web3Button>
//         :
//         status === "burnt" ? <h5>{OZOValue} SOZO's Burnt Successfully OZO Being Transferred . . .</h5> :
//           ""
//       }
//       <br />
//       <br />
//       <button className='btn btn-primary mt-2' onClick={() => addToken()}>Add Token 🪙</button>
//       <button className='btn btn-primary mt-2 ' onClick={() => addNetwork()}>Add Network 📡</button>
//     </div>
//   );

// }

// export default VestingWallet


