import React from "react";

import { Link } from 'react-router-dom';
import dashboardlogo from "../assets/img-ozo/dashboard.png"
import buylogo from "../assets/img-ozo/ozo-ico.png"
import transactionlogo from "../assets/img-ozo/tran.png"
import profilelogo from "../assets/img-ozo/profile.png"
import myaccountlogo from "../assets/img-ozo/my-account.png"
import vesting from "../assets/img-ozo/vesting.png"

import exchangelistingD from "../assets/img-ozo/el-d.jpg"
import exchangelistingM from "../assets/img-ozo/el-m.jpg"
import idoD from "../assets/img-ozo/ido-d.jpg"
import idoM from "../assets/img-ozo/ido-m.jpg"
import lpM from "../assets/img-ozo/lp-m.jpg"
import lpD from "../assets/img-ozo/lp-d.jpg"

import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Cookies from 'js-cookie'
function Navbar({ setDivClass,
  divClass, displaymobnav, ban }) {


  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };


  return (

    <>
      {ban ?
        <>

          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            indicators={false}
            controls={false}
          >
            <Carousel.Item>
              <div className="main-block">
                <div class="block">
                  <img
                    className="d-block w-100"
                    src={exchangelistingD}
                    alt=""
                  />
                </div>
              </div>

            </Carousel.Item>
            <Carousel.Item>
              <div className="main-block">
                <div class="block">
                  <img
                    className="d-block w-100"
                    src={idoD}
                    alt=""
                  />
                </div>
              </div>

            </Carousel.Item>
            <Carousel.Item>
              <div className="main-block">
                <div class="block">
                  <img
                    className="d-block w-100"
                    src={lpD}
                    alt=""
                  />
                </div>
              </div>

            </Carousel.Item>

          </Carousel>

          {/* <img src={banner2}></img> */}





          <div className="main-block2">
            <div class="block2">
              <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                indicators={false}
                controls={false}
              >
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={exchangelistingM}
                    alt=""
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={idoM}
                    alt=""
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={lpM}
                    alt=""
                  />
                </Carousel.Item>
              </Carousel>

            </div>
          </div>

        </> : ""

      }





    </>



  );
}

export default Navbar;
