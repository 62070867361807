import { configureStore } from '@reduxjs/toolkit'


import ConstantSlice from '../reducers/ConstantSlice'
import TokenDetailSlice from '../reducers/TokenDetailSlice'


export default configureStore({
  reducer: {

    constVar:ConstantSlice,
    TokenDet:TokenDetailSlice,

},
})