
import React, { useState, useEffect } from 'react';
import { apiURI } from '../../config/config';

import { useSelector } from 'react-redux';
import { user_id } from '../../reducers/ConstantSlice';
import VestingWallet from './VestingWallet';
import Cookies from 'js-cookie'

function Vestingwrapper() {
  const [mode, setMode] = useState("vesting")

  const [vestingData, setVestingData] = useState()
  const [loadingData, setLoadingData] = useState(true)
  const [currentRound, setcurrentRound] = useState()
  const [ErrorMessage, setErrorMessage] = useState(false)
  const [fromWallet, setfromWallet] = useState("")

  const user_id = useSelector((state) => state.constVar.user_id)
  const currentaddress = useSelector((state) => state.constVar.currentwallet)

  useEffect(() => {
    console.log("User_id :", user_id, "Wallet Address :",)
    async function getData() {
      await getVestingData();
    }
    getData();

  }, [])

  const roundSelect = (e) => {
    console.log(e.target.value, "value")
    if (e.target.value === "angel") {

      setcurrentRound(0)
    }
    else if (e.target.value === "presale1") {
      setcurrentRound(1)
    }
    else if (e.target.value === "presale2") {
      setcurrentRound(2)
    }
    else if (e.target.value === "presale3") {
      setcurrentRound(3)
    }
    else {
      setcurrentRound()
    }

  }

  const getVestingData = async () => {
    try {
      await fetch(apiURI.URL + 'get_vesting_details/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(
          {
            to_address: "",
            user_id: Cookies.get('type')==="guest"?143:user_id,
            wallet_address: currentaddress
          }
        )
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(data => {
          // console.log("Vesting Data", data.message)
          if (data.message === "No Vesting transactions found for the user and wallet address") {
            // console.log("False Case")
            setErrorMessage(true)
            setTimeout(() => {
              setLoadingData(false)
            }, 2000)
          }
          else {
            setVestingData(data.data);
            setTimeout(() => {
              setLoadingData(false)
            }, 2000)
          }
        })
        .catch(error => console.error(error));
    }
    catch (error) {
      console.log(error, "error");
    }
  }

  const getApiData = () => {
    setLoadingData(true)
    getVestingData()
  }




  return (


    <div className="page-content tr">
      <div className="container">
        <div className="card content-area">
          <div className="card-innr ct cs">
            <div className="card-head">
              <h4 className="card-title mb-2">Vesting</h4>
            </div>

            {loadingData ? <div>Loading . . .</div> :

              ErrorMessage ? <div><h5>No Vesting transactions found for the user and wallet address</h5></div> :
                (
                  <div >
                    <div style={{ borderRadius: "8px", border: "1px solid rgb(1 157 50)", padding: "20px", marginBottom: "30px" }}>


                      <div className='row'>
                        <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 p-0' >
                          <div className="form-group row mt-2">
                            <label for="hEmail" className="col-xl-4 col-sm-6 col-sm-2 col-form-label lab t p-0">Select Round</label>
                            <div className="col-xl-8 col-lg-8 col-sm-10">
                              <select className="input-bordered input-with-hint" style={{ width: "auto", height: "40px", border: "solid 1px green", fontSize: "15px", color: "black" }} onChange={(e) => roundSelect(e)}>
                                <option selected>Choose...</option>
                                <option value="angel">Angel</option>
                                <option value="presale1">Presale 1</option>
                                <option value="presale2">Presale 2</option>
                                <option value="presale3">Presale 3</option>

                              </select>
                            </div>
                          </div>
                          <div className="form-group row mt-2">
                            <label for="hEmail" className="col-xl-4 col-sm-2 col-form-label lab  p-0 ">Wallet Address :</label>
                            <div className="col-xl-8 col-lg-8 col-sm-10">
                              <span className='val' style={{ width: "330px", display: "block" }}>{vestingData?.vesting_details[currentRound]?.wallet_address}</span>
                            </div>
                          </div>

                          <div className="form-group row mt-2">
                            <label for="hEmail" className="col-xl-4 col-sm-3 col-sm-2 col-form-label lab p-0 ">Alloted SOZO :</label>
                            <div className="col-xl-8 col-lg-8 col-sm-10">
                              <span className='val'>{vestingData?.vesting_details[currentRound]?.ozo_alloted}</span>
                            </div>
                          </div>

                          <div className="form-group row mt-2">
                            <label for="hEmail" className="col-xl-4 col-sm-3 col-sm-2 col-form-label lab p-0 ">Cliff (months) :</label>
                            <div className="col-xl-8 col-lg-8 col-sm-10">
                              <span className='val'>{vestingData?.vesting_details[currentRound]?.cliff_period}</span>
                            </div>
                          </div>



                          {/* <div className="form-group row mt-2">
        <label for="hEmail" className="col-xl-4 col-sm-3 col-sm-2 col-form-label lab">OZO's Earned</label>
        <div className="col-xl-8 col-lg-8 col-sm-10">
          <span>200</span>
        </div>
    </div>
    
    <div className="form-group row mt-2">
        <label for="hEmail" className="col-xl-4 col-sm-3 col-sm-2 col-form-label lab">Unclaimed OZO's</label>
        <div className="col-xl-8 col-lg-8 col-sm-10">
          <span>50</span>
        </div>
    </div>
    <div className="form-group row mt-2">
        <label for="hEmail" className="col-xl-4 col-sm-3 col-sm-2 col-form-label lab">Claimed OZO's</label>
        <div className="col-xl-8 col-lg-8 col-sm-10">
          <span>150</span>
        </div>
    </div> */}




                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 p-0'>
                          {/* <div className="form-group row mt-2">
                      <label for="hEmail" className="col-xl-4 col-sm-3 col-sm-2 col-form-label lab p-0 ">TGE (%) :</label>
                      <div className="col-xl-8 col-lg-8 col-sm-10">
                        <span className='val'>{vestingData?.vesting_details[currentRound]?.tge_percent} </span>
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label for="hEmail" className="col-xl-4 col-sm-3 col-sm-2 col-form-label lab p-0 ">TGE (OZO) :</label>
                      <div className="col-xl-8 col-lg-8 col-sm-10">
                        <span className='val'>{vestingData?.vesting_details[currentRound]?.tge_ozo} </span>
                      </div>
                    </div> */}


                          <div className="form-group row mt-2">
                            <label for="hEmail" className="col-xl-4 col-sm-2 col-form-label lab  p-0 ">TGE Date : </label>
                            <div className="col-xl-8 col-lg-8 col-sm-10">
                              <span className='val'>{vestingData?.vesting_details[currentRound]?.tge_date}</span>
                            </div>
                          </div>
                          <div className="form-group row mt-2">
                            <label for="hEmail" className="col-xl-4 col-sm-2 col-form-label lab  p-0 ">Start Date :</label>
                            <div className="col-xl-8 col-lg-8col-sm-10">
                              <span className='val'>{vestingData?.vesting_details[currentRound]?.start_date}</span>
                            </div>
                          </div>
                          <div className="form-group row mt-2">
                            <label for="hEmail" className="col-xl-4 col-sm-2 col-form-label lab  p-0 ">End Date :</label>
                            <div className="col-xl-8 col-lg-9 col-sm-10">
                              <span className='val'>{vestingData?.vesting_details[currentRound]?.end_date}</span>
                            </div>
                          </div>
                          <div className="form-group row mt-2">
                            <label for="hEmail" className="col-xl-4 col-sm-3 col-sm-2 col-form-label lab p-0 ">Vesting (Daily) :</label>
                            <div className="col-xl-8 col-lg-8 col-sm-10">
                              <span className='val'>{vestingData?.vesting_details[currentRound]?.vesting_period}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mt-2' style={{ justifyContent: "center", marginBottom: "20px" }}>
                        <VestingWallet OZOValue={vestingData?.total_unclaimed_ozo} getApi={() => getApiData()} >

                        </VestingWallet>
                      </div>


                      <div style={{ display: 'flex' }}>
                        <button className='btncs mb-4 ml-1' style={{ backgroundColor: mode === "vesting" ? "green" : "white", color: mode === "vesting" ? "white" : "green", border: "solid 1px green" }} onClick={(e) => { setMode("vesting") }}>Vesting</button>
                        <button className='btncs mb-4 ml-1' style={{ backgroundColor: mode === "transactions" ? "green" : "white", color: mode === "transactions" ? "white" : "green", border: "solid 1px green" }} onClick={(e) => { setMode("transactions") }}>Transactions</button>
                      </div>

                      {
                        mode === "vesting" ?
                          <div>
                            <div className='row'>
                              <div style={{ display: "flex", justifyContent: "space-between", width: "95%" }}>

                                <h4 className="card-title mb-2">Vesting plan</h4>
                                <div style={{ display: "flex" }}>
                                  <p className='ml-5' style={{ fontSize: "16px" }}>Total Released OZO: {vestingData?.total_released_ozo}</p>
                                  <p className='ml-5' style={{ fontSize: "16px" }}>Total Unreleased OZO: {vestingData?.total_unreleased_ozo}</p>
                                </div>

                              </div>


                            </div>
                            <div className='row'>
                              <div className="table-responsive mt-3">
                                <table className="data-table dt-init user-tnx dataTable no-footer">
                                  <thead className="table-secondary">
                                    <tr className="data-item data-head">


                                      <th className="data-col dt-tnxno sorting_disabled">Round</th>
                                      <th className="data-col dt-tnxno sorting_disabled" style={{ textAlign: "center" }}>OZO/day</th>
                                      <th className="data-col dt-tnxno sorting_disabled" style={{ textAlign: "center" }}>Completed Days</th>
                                      <th className="data-col dt-tnxno sorting_disabled" style={{ textAlign: "center" }}>Released OZO</th>
                                      <th className="data-col dt-tnxno sorting_disabled" style={{ textAlign: "center" }}> Days Left</th>
                                      <th className="data-col dt-tnxno sorting_disabled" style={{ textAlign: "center" }}>Unreleased OZO</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      Object.keys(vestingData.vesting_plan).map((key) => {
                                        return (
                                          <tr className="data-item odd" key={key} >
                                            <td className="data-col dt-usd-amount">{key}</td>
                                            <td className="data-col dt-usd-amount" style={{ textAlign: "center" }}> {vestingData.vesting_plan[key].ozo_perday}</td>
                                            <td className="data-col dt-usd-amount" style={{ textAlign: "center" }}>{vestingData.vesting_plan[key].completed_days}</td>
                                            <td className="data-col dt-usd-amount" style={{ textAlign: "center" }}>{vestingData.vesting_plan[key].released_ozo}</td>
                                            <td className="data-col dt-usd-amount" style={{ textAlign: "center" }}>{vestingData.vesting_plan[key].days_left}</td>
                                            <td className="data-col dt-usd-amount" style={{ textAlign: "center" }}>{vestingData.vesting_plan[key].unreleased_ozo}</td>
                                          </tr>)
                                      })
                                    }

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div> :
                          <div>
                            <div className='row'>
                              <div style={{ display: "flex", justifyContent: "space-between", width: "95%" }}>
                                <h4 className="card-title mb-2">Transactions</h4>
                                <div style={{ display: "flex" }}>
                                  <p className='ml-4' style={{ fontSize: "16px" }}>Total Claimed OZO: {vestingData?.total_claimed_ozo}</p>
                                  <p className='ml-4' style={{ fontSize: "16px" }}>Total Unclaimed OZO: {vestingData?.total_unclaimed_ozo}</p>

                                </div>

                              </div>
                            </div>
                            <div className='row'>
                              <div className="table-responsive mt-3">
                                <table className="data-table dt-init user-tnx dataTable no-footer">
                                  <thead className="table-secondary">
                                    <tr className="data-item data-head">

                                      <th className="data-col dt-tnxno sorting_disabled">S.No</th>
                                      <th className="data-col dt-tnxno sorting_disabled" style={{ textAlign: "center" }}>DATE</th>
                                      <th className="data-col dt-tnxno sorting_disabled" style={{ textAlign: "center" }}>OZO CLAIMED</th>
                                      <th className="data-col dt-tnxno sorting_disabled" style={{ textAlign: "center" }}>TXN LINK</th>
                                      {/* <th className="th">Unclaimed OZO</th> */}

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {vestingData?.vesting_history.map((value, index) => {
                                      return (
                                        <tr className="data-item odd" >
                                          <td className="data-col dt-usd-amount" >{index + 1}</td>
                                          <td className="data-col dt-usd-amount" style={{ textAlign: "center" }}>{value.date.slice(0, 10)}</td>
                                          <td className="data-col dt-usd-amount" style={{ textAlign: "center" }}>{value.ozo_claimed}</td>
                                          <td className="data-col dt-usd-amount" style={{ textAlign: "center" }}><a href={`https://testnet.ozonescan.io/tx/${value.txn_hash}`} target="_blank" style={{ textDecoration: "underline" }}>{value.txn_hash}</a></td>
                                        </tr>
                                      )
                                    })
                                    }
                                    {/* <tr className="data-item odd">
                      <td className="data-col dt-usd-amount"> 1</td>
                      <td className="data-col dt-usd-amount">10/04/23</td>
                      <td className="data-col dt-usd-amount"> 10</td>
                      <td className="data-col dt-usd-amount"><span style={{ textDecoration: "underline" }}>Link </span></td>
                    </tr> */}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                      }

                    </div>






                  </div>
                )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vestingwrapper;
