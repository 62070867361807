import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';

import 'regenerator-runtime/runtime'

const fetchRateAdaptor = createEntityAdapter();
const initialState = fetchRateAdaptor.getInitialState({
  status: "idle",
  error: null,
  searchTerm: "",
  arr:[],
  round:0,
  round_id:0

});
// export const fetchrate = createAsyncThunk('TokendetailData/fetchrate', async () => {
//  var dataval;
// console.log("inside fetch rate")
//  await fetch("https://dev.icoapp.backend.ozonechain.io/ozonetoken/")
//         .then((response) => {
//             const json = response.json();
//             console.log(json,"res")
//             return json;
//         })
//         .then((data) => {
// console.log(data,"coinmarket");
// dataval=data;
// return data;
//         })
//         return dataval  

// })
export const Tokendetail = createSlice({
  name: 'TokendetailData',
  initialState,
  reducers: {
    searchTerm: (state, action) => {
      console.log("dararayd,s,msmsl", action.payload);
      state.searchTerm = action.payload;
    },
    ratesRemoveAll(state, action) {
     console.log(action.payload?.data,"incoming")
     const activeRound = action.payload?.data.find(element => element.is_active);
     const activeIndex = action.payload?.data.findIndex(element => element.is_active);
     const activeRoundId = activeRound ? activeRound.id : null;
     
    
     console.log("round is redux data",activeIndex,activeRoundId)

     state.round_id=activeRoundId;
     if(activeIndex==0)
     {
      state.round =0;
      console.log("round 1")
     }
     else if(activeIndex==1)
     {
      state.round=1;
        console.log("round 2")
     }
     else if(activeIndex==2)
     {
      state.round=2;
        console.log("round 3")
     }
     else if(activeIndex==3)
     {
      state.round=3;
        console.log("round 4")
     }
     else if(activeIndex==4)
     {
      state.round=4;
        console.log("round 5")
     }
   state.arr= action.payload;
    }
    

  },
  extraReducers: {
}
});

export const {ratesRemoveAll} = Tokendetail.actions;
export default Tokendetail.reducer;




