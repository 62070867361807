import React from "react";

import {  Link } from 'react-router-dom';
import Logo from "./assets/img-ozo/favico-256x256.png"



function Navbaradmin({style,handleButtonClick}) {




  return (
    
   <aside className="sidebar-wrapper" style={{left:style}}>
          <div className="sidebar-header">
            <div>
              <img src={Logo} className="logo-icon" alt="logo icon"></img>
            </div>
            <div>
              <h4 className="logo-text">OZONE</h4>
            </div>
            <div className="toggle-icon ms-auto"> <i className="bi bi-list" onClick={handleButtonClick}></i>
            </div>
          </div>
   
          <ul className="metismenu" id="menu">
            <li>
            <Link to="/dashboard-admin">
                <div className="parent-icon"><span className="bi bi-grid-fill"></span>
                <div className="menu-title">Dashboard</div>
                </div>
                </Link>
              
            </li>
            {/* <li>
            <Link to="/wallet-admin">
                <div className="parent-icon"><i className="bi bi-wallet-fill"></i>
               
               <div className="menu-title">Wallets</div>
               </div>
               </Link>
             
            </li> */}
            <li>
            <Link to="/transactions-admin">
                <div className="parent-icon"><i className="bi bi-arrow-down-up"></i>
                <div className="menu-title">Transactions</div>
               
                </div>
                </Link>
             
            </li>
            <li>
            <Link to="/ozone-distribution">
                <div className="parent-icon"><i class="bi bi-safe2-fill"></i>
                <div className="menu-title">Ozone Distribution</div>
               
                </div>
                </Link>
             
            </li>
            <li>
            <Link to="/baby-ozone-distribution">
                <div className="parent-icon"><i class="bi bi-safe2-fill"></i>
                <div className="menu-title">BabyOZO Airdrop</div>
               
                </div>
                </Link>
             
            </li>
            <li>
            <Link to="/ozone-transfer">
                <div className="parent-icon"><i class="bi bi-safe2-fill"></i>
                <div className="menu-title">Ozone Transfer</div>
               
                </div>
                </Link>
             
            </li>
         
</ul></aside>


         
         
     
   
  );
}

export default Navbaradmin;
