
import React from 'react';
import { useState } from 'react';


function Walletwrapperadmin( {walletAddress ,sozoValue, ozoValue,refreshData}) {
  return (
    <main className="page-content-admin">
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">

        
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item"><a href="javascript:;"><i className="bx bx-home-alt"></i></a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Wallets</li>
            </ol>
          </nav>
        </div>
        <div className="ms-auto">
          {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleVerticallycenteredModal">Add Wallet</button> */}
        </div>
      </div>
      <div className="card">
           <div className="card-body">
             <div className="d-flex align-items-center" style={{    justifyContent: "space-between"}}>
                <h5 className="mb-0">Wallet Details</h5>
                <button onClick={() => refreshData()} className='btn btn-primary'>Refresh</button>
                 {/* <form className="ms-auto position-relative">
                   <div className="position-absolute top-50 translate-middle-y search-icon px-3" style={{marginLeft:"80%",marginTop:"8px"}}><i className="bi bi-search" ></i></div>
                   <input className="form-control ps-5" type="text" placeholder="search"></input>
                 </form> */}
             </div>
             <div className="table-responsive mt-3">
               <table className="table align-middle">
                 <thead className="table-secondary">
                   <tr>
                    {/* <th className="th">#</th> */}
                    <th className="th">S.No</th>
                    <th className="th">Wallet Address</th>
                    <th className="th">SOZO's Available</th>
                    <th className="th">OZO's Available</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                    {/* <td>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked></input>
                        <label className="form-check-label" for="flexRadioDefault2"></label>
                      </div>
                    </td> */}
                    <td>1</td>
                    <td>{walletAddress}</td>
                    <td>{sozoValue}</td>
                    <td>{ozoValue}</td>
                   </tr>
                 </tbody>
               </table>
             </div>
           </div>
         </div>
    </main>
  );
}

export default Walletwrapperadmin;
