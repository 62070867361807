
import React from 'react';
import logo from "../../assets/img-ozo/logo-ozone-dark-284x85.png"
import Footer from "../footer"

function Paymentsuccess() {




   return (

      <div className="">
    
         <div style={{height:"20vh",display:"flex",justifyContent:"center",padding:"130px",flexDirection:""}}>
            <div>
            <img src={logo} alt="logo" style={{width:"300px",height:"auto"}}></img>
            </div>
            
        

         </div>
         <div style={{height:"50vh",display:"flex",justifyContent:"center"}}>
         <p style={{fontSize:"30px"}}>Payment Successfull !!</p>

         </div>
         
         <Footer></Footer>
      </div>

   );
}

export default Paymentsuccess;
