
import React, { useEffect } from 'react';
import { useState } from 'react';
// import balancelogo from "../../assets/img-ozo/logo-monogram-200x200-white.png"
// import convertlogo from "../../assets/img-ozo/favico-256x256-1.png"
// import { ArrowRight } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {  Link } from 'react-router-dom';
import {apiURI} from '../../../config/config'
import Web3 from 'web3';
import ProgressBar from 'react-bootstrap/ProgressBar';
import animationData from "./dots.json"
import Lottie from 'react-lottie';

function Dashboardwrapperadmin() {
  const [show, setShow] = useState(false);
  const [showload, setShowload] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
const [fundingType, setfundingType] = useState("angel")
const [dataRound, setdataRound] = useState(0)
const [fundingData, setfundingData]=useState()
const [walletAddress, setWalletAddress] = useState("0x3b1e20Aea65D5dC0b7948258f3B0b41618a96c6b")
const [ozoValue, setOzoValue] = useState(0);
const [sozoValue, setSozoValue] = useState(0)

   //variable for lottie animation
   const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
       preserveAspectRatio: "xMidYMid slice"
    }
 };

//wallet balance details.

useEffect(() => {
  console.log("Use Effect Wallet Admin")

  async function setData (){
     await checkBalance()
     await checkTokenBalance()
  }
  setData(); 
  
},[])

const checkBalance = async ()=> {
  const web3 = new Web3('https://node1.testnet.ozonechain.io/');
  const balance = await web3.eth.getBalance(walletAddress);
  const ozoValue =  parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(5);
  setOzoValue(ozoValue)



  }
const checkTokenBalance = async () => {
     const web3 = new Web3('https://node1.testnet.ozonechain.io/');
     const balanceAbi = [  {
        "type": "function",
        "name": "balanceOf",
        "inputs": [
          {
            "type": "address",
            "name": "account",
            "internalType": "address"
          }
        ],
        "outputs": [
          {
            "type": "uint256",
            "name": "",
            "internalType": "uint256"
          }
        ],
        "stateMutability": "view"
      },]
     const tokenContract = new web3.eth.Contract(balanceAbi, "0x795a5fd0484B36837BC8F78f020316cF035A90A7");
     const balance = await tokenContract.methods.balanceOf(walletAddress).call();
     const tokenValue = parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(5);;
     setSozoValue(tokenValue);
     }

     const refreshData = async() =>{
        console.log("Data Refreshed");
        alert("Balance Updated Successfully ")
        await checkBalance();
        await checkTokenBalance();
     }
 

const handlefundingType = (e) => {
console.log("clicked",e.target.value)

if(e.target.value==="Pre Sale 1")
{
  let round_id
  fundingData.data.tokenomics.forEach((round, index) => {
    if (round.funding_type==="Pre Sale 1") 
    {
      round_id = round.round_id;
      updateRound(round_id)
    }
  });
  
}
else if(e.target.value==="Pre Sale 2")
{

  let round_id
  fundingData.data.tokenomics.forEach((round, index) => {
    if (round.funding_type==="Pre Sale 2") 
    {
      round_id = round.round_id;
      updateRound(round_id)
    }
  });
}
else if(e.target.value==="Pre Sale 3")
{
  let round_id
  fundingData.data.tokenomics.forEach((round, index) => {
    if (round.funding_type==="Pre Sale 3") 
    {
      round_id = round.round_id;
      updateRound(round_id)
    }
  });
}
else if(e.target.value==="Pre Sale 4")
{
  let round_id
  fundingData.data.tokenomics.forEach((round, index) => {
    if (round.funding_type==="Pre Sale 4") 
    {
      round_id = round.round_id;
      updateRound(round_id)
    }
  });
}
else if(e.target.value==="Public Sale")
{

  let round_id
  fundingData.data.tokenomics.forEach((round, index) => {
    if (round.funding_type==="Public Sale") 
    {
      round_id = round.round_id;
      updateRound(round_id)
    }
  });
}

}


const getDashboard = () => {
  
  try {
     fetch(apiURI.URL+'admin_dashboard/', {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${localStorage.getItem('token')}`
        },

     })
        .then(response => response.json())
        .then(data => {
          console.log(data,"admin data")
          let activeRoundIndex;
          let activeFundingType;
          
          data.data.tokenomics.forEach((round, index) => {
            if (round.is_round_active) {
              activeRoundIndex = index;
              activeFundingType = round.funding_type;
            }
          });

          setdataRound(activeRoundIndex);
          console.log(activeFundingType);
          setfundingType(activeFundingType)
          setfundingData(data);
        })
        .catch(error => console.error(error));
  }
  catch (error) {
     console.log(error, "error");
  }
}
const updateRound = async (round) => {
  setShowload(true);
  try {
     await fetch(apiURI.URL+'ozonetoken/', {
        method: 'PUT',
        headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(
          {
            "round_id" :round,
         
          }
       )

     })
        .then(response => response.json())
        .then(data => {
          setShowload(false)
          console.log(data,"update round")
        })
        .catch(error => console.error(error));
  }
  catch (error) {
     console.log(error, "error");
  }
  getDashboard();
}



useEffect(() => {
  getDashboard();
}, [])

   
  return (
   <main className="page-content-admin">

<div style={{borderRadius:"8px",backgroundColor:"#e7e7e7",padding:"20px"}}>
<div className='row'>
  <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
    <form onChange={(e) => handlefundingType(e)} Value={fundingType}>
      <div className=" " style={{display:"flex",flexDirection:"row",justifyContent:"start"}}>

      <div style={{marginRight:"10px",backgroundColor:"white",borderRadius:"50px",padding:"5px 10px"}}>

      <input style={{marginRight:"5px"}} type="radio" checked={fundingType === "Pre Sale 1"? true :false } value="Pre Sale 1"></input>
      <label >Presale 1</label>
      </div>
      <div style={{marginRight:"10px",backgroundColor:"white",borderRadius:"50px",padding:"5px 10px"}}>

      <input style={{marginRight:"5px"}} type="radio" checked={fundingType === "Pre Sale 2"? true :false } value="Pre Sale 2"></input>
      <label >Presale 2</label>
      </div>
      <div style={{marginRight:"10px",backgroundColor:"white",borderRadius:"50px",padding:"5px 10px"}}>
      
      <input style={{marginRight:"5px"}} type="radio" checked={fundingType === "Pre Sale 3"? true :false } value="Pre Sale 3"></input>
      <label >Presale 3</label>
      </div>
      <div style={{marginRight:"10px",backgroundColor:"white",borderRadius:"50px",padding:"5px 10px"}}>
      
      <input style={{marginRight:"5px"}} type="radio" checked={fundingType === "Pre Sale 4"? true :false } value="Pre Sale 4"></input>
      <label >Presale 4</label>
      </div>
      <div style={{marginRight:"10px",backgroundColor:"white",borderRadius:"50px",padding:"5px 10px"}}>
      
      <input style={{marginRight:"5px"}} type="radio" checked={fundingType === "Public Sale"? true :false } value="Public Sale"></input>
      <label >Public Sale</label>
      </div>


      </div>
    </form>
    
  </div>
</div>
<hr></hr>
<div >
       <div className="row">
       <div className='col-12 mb-2 ml-2'><h3>Rounds  </h3></div>
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}}>Funding Type</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0"> {fundingData?.data?.tokenomics[dataRound]?.funding_type}</h4>
              </div>
              <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
            
              </div>
            </div>
          </div>
        </div>
       </div>
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}}>Price</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0"> {fundingData?.data?.tokenomics[dataRound]?.price} $</h4>
              </div>
              <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs "></i>
              </div>
            </div>
          </div>
        </div>
       </div>
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}}>Total Coins on Sale</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0">  {fundingData?.data?.tokenomics[dataRound]?.total_tokens}</h4>
              </div>
              <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div>
            </div>
          </div>
        </div>
       </div>

       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}}>Pending Transactions</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0">  {fundingData?.data?.tokenomics[dataRound]?.pending_transactions_count}</h4>
              </div>
              <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div>
            </div>
          </div>
        </div>
       </div>

       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}}>Fundraise Target</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0">{fundingData?.data?.tokenomics[dataRound]?.fundraise_target}</h4>
              </div>
              <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div>
            </div>
          </div>
        </div>
       </div>
       {/* <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}}>Funds Received</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0"> {fundingData?.data?.tokenomics[dataRound]?.funds_received} </h4>
              </div>
              <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div>
            </div>
          </div>
        </div>
       </div> */}
       <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <div className="progress-wrapper">
                <div className="d-flex align-items-center gap-2">
                
                   <div className="mt-0 mb-0"><p className='tokenstatus'>Sold Coins </p><span className="price-value">{fundingData?.data?.tokenomics[dataRound]?.tokens_sold.toFixed(2)}</span> </div>
                   <div className="ms-auto mt-0 mb-0 text-right"><p className='tokenstatus'>Unsold Coins</p><span className="price-value">{(fundingData?.data?.tokenomics[dataRound]?.total_tokens-fundingData?.data?.tokenomics[dataRound]?.tokens_sold).toFixed(2)}</span></div>
        
             
                   
                </div>
                <ProgressBar now={((fundingData?.data?.tokenomics[dataRound]?.tokens_sold)/fundingData?.data?.tokenomics[dataRound]?.total_tokens)*100}style={{width:"100%"}} variant="success"/>
                {/* <div className="progress">
                  <div className="progress-bar bg-custom" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" ></div>
       
                </div> */}
              </div>
              {/* <button href="#" className="btn btn-sm btn-success mt-3" data-bs-toggle="modal" data-bs-target="#exampleVerticallycenteredModal">Edit</button> */}
            </div>
            </div>
          </div>
        </div>
       </div>
       </div>
   

<div className='row'>
  <div className='col-12 mb-2 ml-2'><h3>Referrals</h3></div>
  
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs mb-2">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}}>Referrals</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0"> {fundingData?.data?.tokenomics[dataRound]?.referrals.toFixed(2)} </h4>
              </div>
              <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div>
            </div>
          </div>
        </div>
       </div>
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs mb-2">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}}>Referral Coins</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0"> {fundingData?.data?.tokenomics[dataRound]?.referral_tokens.toFixed(2)} </h4>
              </div>
              <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div>
            </div>
          </div>
        </div>
       </div>
  
       </div>
       </div>
       </div>





       <hr></hr>
       <div>
       <div className='row'>
       <div className='col-12 mb-2 ml-2'><h3>Overall</h3></div>
       {/* <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center"> */}
              {/* <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}}>Total Funds Received</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0" > ${fundingData?.data?.total_funds_received} </h4>
              </div> */}
              {/* <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div> */}
            {/* </div>
          </div>
        </div>
       </div> */}
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}} >Total Non Referral Coins</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0"> {fundingData?.data?.total_non_referral_tokens.toFixed(2)} </h4>
              </div>
              {/* <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div> */}
            </div>
          </div>
        </div>
       </div>
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}} >Total Referral Coins</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0"> {fundingData?.data?.total_referral_tokens.toFixed(2)}</h4>
              </div>
              {/* <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div> */}
            </div>
          </div>
        </div>
       </div>
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}} >Total Coins</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0">{(fundingData?.data?.total_referral_tokens+fundingData?.data?.total_non_referral_tokens).toFixed(2)}</h4>
              </div>
              {/* <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div> */}
            </div>
          </div>
        </div>
       </div>
      
 
   </div>

   <div className='row'>
   {/* <div className='col-12 mb-2 ml-2'><h3>Reserves</h3></div> */}
   
       {/* <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}} >OZO Available</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0"> {ozoValue}</h4>
              </div> */}
              {/* <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div> */}
            {/* </div>
          </div>
        </div>
       </div> */}
       {/* <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card rounded-4 cs">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1" style={{color:"#525252",fontSize:"12px"}} >SOZO Available</p>
                <h4 style={{fontColor:"#1c1c1c !important"}} className="mb-0 mt-0"> {sozoValue}</h4>
              </div> */}
              {/* <div className="ms-auto widget-icon bg-custom text-white">
                <i className="bi bi-cash-coin cs"></i>
              </div> */}
            {/* </div>
          </div>
        </div>
       </div> */}
   </div>
   </div>



      <Modal show={show} onHide={handleClose}>

        <Modal.Body>      
        <div className="modal-content p-4">
                          <div className="modal-header">
                            <h5 className="modal-title">Edit Price Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>X</button>
                          </div>
                          <div className="modal-body">
                            
                                <form>
                                  <div className="mb-3">
                                    <label className="form-label">Balance Tokens in supply</label>
                                    <input className="form-control mb-3" type="text" placeholder="Enter Balance Token in Supply" aria-label="default input example"></input>
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">Total Tokens Supply</label>
                                    <input className="form-control mb-3" type="text" placeholder="Enter Total Tokens in Supply" aria-label="default input example"></input>
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">1 OZO =</label>
                                    <input className="form-control mb-3" type="text" placeholder="Enter USDT Amount" aria-label="default input example"></input>
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">Set To wallet Address</label>
                                    <select className="form-select mb-3" aria-label="Default select example">
                                      <option selected>Select Wallet Address</option>
                                      <option value="1">0XSDCDF33SD1C3SCD</option>
                                      <option value="2">0XSDCDF33SD1C3SCD</option>
                                      <option value="3">0XSDCDF33SD1C3SCD</option>
                                    </select>
                                  </div>
                                </form>
                              

                          </div>
                          <div className="modal-footer">

                            <button type="button" className="btn btn-primary">Submit</button>
                          </div>
                        </div>
                        </Modal.Body>
   
      </Modal>

      <div style={{ position: "fixed", padding: "100px", display: showload===true? "flex":"none" , justifyContent: "center", width: "100%", height: "100%", backgroundColor: "#80808063",top:"0px",left:"0px",margin:"0px" }}>
            <Lottie
               options={defaultOptions}
               height={300}
               width={300}
               style={{ marginTop: "10%" }}/>
         </div>
</main>
 
  );
}

export default Dashboardwrapperadmin;
