import React from "react";
import { useState, useEffect } from 'react';
import "./checktransaction.css"
import Footer from "../footer"
import Topbar from "../topbar"
import Navbar from "../navbar"
import Checktransactionwrapper from "./checktransactionwrapper";
function Checktransaction() {
    const [divClass, setDivClass] = useState('');
    const [displaymobnav, setdisplaymobnav] = useState('');
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
//navbar window/mobile switch
useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
 }, []);

 useEffect(() => {
    if (viewportWidth < 992) {
       setDivClass('navbar-mobile');
       setdisplaymobnav("none")
    }
    else {
       setdisplaymobnav("block")
    }
 }, [viewportWidth]);

 const handleButtonClick = () => {
    console.log("clicked")
    if (viewportWidth < 992) {
       console.log("check if")
       setDivClass(prevClass => prevClass === 'navbar-mobile' ? 'navbar-mobile active' : 'navbar-mobile');
       setdisplaymobnav(prevClass => prevClass === 'none' ? 'block' : 'none')
    }
 };



   return (

      <>
     <Topbar
               handleButtonClick={handleButtonClick}
               setdisplaymobnav={setdisplaymobnav}
      
               displaymobnav={displaymobnav}
               divClass={divClass}
               setDivClass={setDivClass}
            ></Topbar>
               <Navbar
      
               displaymobnav={displaymobnav}
               divClass={divClass}
               setDivClass={setDivClass}
            ></Navbar>
<Checktransactionwrapper></Checktransactionwrapper>

<Footer></Footer>
      </>



   );
}

export default Checktransaction;
