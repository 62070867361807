import React from "react";

const Transactionadminrow = ({ i, showOrder }) => {
  console.log(i?.transaction_no, "transno");

  return (
    <tr style={{ fontWeight: "400" }}>
      <td>{i?.transaction_no}</td>
      <td>{i?.timestamp_initiated.substring(0, 9)}</td>
      <td>{i?.funding_type}</td>

      {i?.transaction_type === "purchase" ||
      i?.transaction_type === "Purchase" ? (
        <td>
          <span className="badge bg-primary">Purchase</span>
        </td>
      ) : i?.transaction_type === "Referral" ? (
        <td>
          <span className="badge bg-secondary">Referral</span>
        </td>
      ) :  i?.transaction_type === "NFT" ||i?.transaction_type === "nft"  ?(
       <td>
          <span className="badge bg-warning">NFT</span>
        </td>
      ):(
        ""
      )
    
    }

      <td>{i?.ozo_received.toFixed(2)} OZO</td>
      <td>{i?.usdt_spent.toFixed(2)}</td>
      <td>
        {i?.paid_amount === undefined || i?.paid_amount === null
          ? 0
          : i?.paid_amount.toFixed(2)}
      </td>
      <td>
        {i?.to_address.substring(0, 8)}.....{i?.to_address.substring(38, 42)}
      </td>
      {i?.status === "transferred" ? (
        <td>
          <span
            className="badge bg-success"
            style={{
              color: "white",
              border: "0px solid",
              background: "#26af04 !important",
            }}
          >
            Transferred
          </span>
        </td>
      ) : i?.status === "EXPIRED" || i?.status === "expired" ? (
        <td>
          <span className="badge bg-danger" style={{ width: "80px" }}>
            Expired
          </span>
        </td>
      ) : i?.status === "paid" ? (
        <td>
          <span className="badge bg-info" style={{ width: "80px" }}>
            Paid
          </span>
        </td>
      ) : i?.status === "pending" ? (
        <td>
          <span className="badge bg-warning" style={{ width: "80px" }}>
            Pending
          </span>
        </td>
      ) 
      
      : (
        ""
      )}

      <td>
        <div style={{ textAlign: "center" }}>
          {i?.status === "paid" ? (
            <button
              type="button"
              class="btn-sm btn-success px-2"
              style={{ fontSize: "12px", padding: "1px" }}
              onClick={() => showOrder(i?.transaction_no)}
            >
              Close Transaction
            </button>
          ) : (
            ""
          )}
        </div>
      </td>
    </tr>
  );
};

export default Transactionadminrow;
