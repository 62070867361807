
import React from 'react';
// import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import {  Link } from 'react-router-dom';



function Walletdet({handleshoweditwallet,deleteWallet,updateWallet,i,index}) {
console.log("inside component",i?.wallet_address);



const deleteCurrent = () => {
   deleteWallet(i?.wallet_address);
}

const onCheck = () => {
   console.log("walllet add:" , i?.wallet_address)
   updateWallet(i?.wallet_address)
}

   return (


<tr className="data-item" >
 <td className="data-col dt-user">{index+1}</td>
 <td className="data-col dt-user">{i?.wallet_address}</td>
 <td className="data-col text-right">
    <ul className="data-action-list  d-md-inline-flex">
       <li>
         <div className="input-item" onClick={onCheck}>
   
         <input type="checkbox" className="input-radio input-radio-md"   checked={i?.current}>
         </input>
    

         <label for="radio_5"></label>
         </div>
         </li>
       {/* <li><a href="#" className="btn btn-info-alt btn-xs btn-icon" onClick={handleshoweditwallet}><em className="ti ti-pencil" ></em></a></li> */}
       <li><p  className="btn btn-danger-alt btn-xs btn-icon" onClick={deleteCurrent}>
         <em className="ti ti-trash"></em></p></li>
    </ul>
 </td>
</tr>
   );
}

export default Walletdet;
