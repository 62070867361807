
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
function Transactioview({show,handleClose,allTransaction,viewTransactiono}) 
{
    const coinvalue = useSelector((state) => state.TokenDet.arr)


var filteredData=[]

if(allTransaction){
     filteredData = allTransaction.filter(item => item.transaction_no === viewTransactiono);
  
}


   return (
                  
  
                  <Modal 
                  show={show} 
                  onHide={handleClose}
                  size="xl"
                  >

                    <Modal.Body>      
                  <div className="modal-content">
                  <button  className="modal-close"  style={{border:"0px solid",backgroundColor:"transparent"}}><em className="ti ti-close" onClick={handleClose}></em></button>
                  <div className="popup-body popup-body-lg">
                  <div className="content-area">
                     <div className="card-head d-flex justify-content-between align-items-center">
                        <h4 className="card-title mb-0">Transaction Details</h4>
                     </div>
                     <div className="gaps-1-5x"></div>
                     <div className="data-details d-md-flex">
                        <div className="fake-class"><span className="data-details-title">Tranx Date</span><span className="data-details-info">{filteredData[0]?.timestamp_initiated}</span></div>
                        {filteredData[0]?.coinbase_status === "NEW"?
                        <div className="fake-class"><span className="data-details-title">Tranx Status</span><span className="badge badge-info ucap">PENDING</span></div>:
                        <div className="fake-class"><span className="data-details-title">Tranx Status</span><span className="badge badge-info ucap">{filteredData[0]?.status}</span></div>
                        }
                        {/* <div className="fake-class"><span className="data-details-title">Tranx Approved Note</span><span className="data-details-info">By <strong>Admin</strong> at 24 Jul, 2018 10:12PM</span></div> */}
                     </div>
                     <div className="gaps-3x"></div>
                     <h6 className="card-sub-title">Transaction Info</h6>
                     <ul className="data-details-list">
                        <li>
                           <div className="data-details-head">Transaction Type</div>
                           <div className="data-details-des"><strong>{filteredData[0]?.transaction_type}</strong></div>
                        </li>
                   
                        {/* <li>
                           <div className="data-details-head">Payment Type</div>
                           <div className="data-details-des"><strong>Metamask</strong></div>
                        </li> */}
                    
                        <li>
                           <div className="data-details-head">Receiving Wallet Address</div>
                           <div className="data-details-des"><strong>{filteredData[0]?.to_address}</strong></div>
                        </li>
                  
                        {/* <li>
                           <div className="data-details-head">Tx Hash</div>
                           <div className="data-details-des"><span>{filteredData[0]?.transaction_hash}</span> <span></span></div>
                        </li>
                  
                        <li>
                           <div className="data-details-head">Deposit To</div>
                           <div className="data-details-des"><span>{filteredData[0]?.to_address}</span> <span></span></div>
                        </li> */}
                   
                        <li>
                           <div className="data-details-head">Amount Contributed</div>
                           {/* {(coinvalue?.price_per_token)*filteredData[0]?.send_tokens} */}
                           <div className="data-details-des"><span className="price-symbol"> {filteredData[0]?.usdt_spent===undefined ||filteredData[0]?.usdt_spent===null?"":filteredData[0]?.usdt_spent.toFixed(2) } USD ($)</span></div>
                        </li>
                      
                        <li>
                           <div className="data-details-head">Coins</div>
                           <div className="data-details-des">{filteredData[0]?.ozo_received.toFixed(2)} <span className="price-symbol">OZO</span></div>
                        </li>
                  
                     </ul>
             
                     <div className="gaps-3x"></div>
        
                     
                 
                  </div>
           
               </div>
            </div>



</Modal.Body>

</Modal>
                 
   );
}

export default Transactioview;
