import React, { useState, useEffect } from 'react';

import ExcelJS from 'exceljs';
import readXlsxFile from 'read-excel-file';
// import "./DistributionWrapper.css"
import Web3 from 'web3';
import Modal from 'react-bootstrap/Modal';

import Lottie from 'react-lottie';
import BabyOZOabi from "./BabyOZOabi.json";
import animationData from "../transactions/dots.json"
import { ethers,BigNumber } from 'ethers';  



const BabyOZOWrapper = () => {

    const [data, setData] = useState([]);
    const [excel, setExcel] = useState(false);
    const [popup, setPopup] = useState(false)
    const [counter, setCounter] = useState(0);
    const [total, setTotal] = useState(0)
    const transactionHash = [];

    const [addressBalance, setAddressBalance] = useState(0)
    const [babyOZOBalance, setBabyOZOBalance] = useState(0)

    const [loading, setLoading] = useState(false)

useEffect(() => {
    const getBalance =async () => {
        const web3 = new Web3("https://node1.ozonechain.io")
        const balance = await web3.eth.getBalance("0xFe3829fE9e50ddb78F5F9bB5050CcD0B3753C16A");
        const ethBalance = parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(3);
        setAddressBalance(ethBalance)

        const contractAddress = "0xB77C34954847Ea37d00678b16F33C0012B701d7F";
        // const contractAddress = '0xd4daf9bde56333c5a5fce22cf69f45a431abc1f0'
        const tokenContract = new web3.eth.Contract(BabyOZOabi, contractAddress);
        const babyozo_balance = await tokenContract.methods.balanceOf("0xFe3829fE9e50ddb78F5F9bB5050CcD0B3753C16A").call();
        // const babyozo_balance = await tokenContract.methods.balanceOf("0x3b1e20Aea65D5dC0b7948258f3B0b41618a96c6b").call();
        const tokenBalance = parseFloat(web3.utils.fromWei(babyozo_balance, 'ether')).toFixed(3);
        setBabyOZOBalance(tokenBalance);
    }
    getBalance();
}, [])


const handleUpload = (event) => {
    let fileObj = event.target.files[0];
    readXlsxFile(fileObj).then((rows) => {
      setData(rows);
      setExcel(true);
    });
  };

  const txs = [];


  const handleTransfer = async () => {
    setPopup(false)

    try{
    const web3 = new Web3("https://node1.ozonechain.io");
    // const privateKey = "1db528ef49379554cf982f54eb5210644613d1673acb3f1920768fd5ca1d50f5";
    // const privateKey = "58a94d422be7a67da2a0faffda66cf6f3008413dc8a1581444e6ca2133c167d5";
    const privateKey = process.env.REACT_APP_PRIVATE_KEY;
    const account = web3.eth.accounts.privateKeyToAccount(privateKey);
    web3.eth.accounts.wallet.add(account);
    web3.eth.defaultAccount = account.address;
    setTotal(data.length-1)
    setLoading(true)

    const contractAddress = "0xB77C34954847Ea37d00678b16F33C0012B701d7F"; 
    // const contractAddress = '0xd4daf9bde56333c5a5fce22cf69f45a431abc1f0'
    const contract = new web3.eth.Contract(BabyOZOabi, contractAddress);



    for (let i = 1; i < data.length; i++) {
       
        const address = web3.utils.toChecksumAddress(data[i][1].toString())
        // const amount = (data[i][2] * 10 **18).toString();
        const amount = ethers.utils.parseUnits(data[i][2].toString(), 18);
        const amountInWei = amount.toString();

        // console.log(amount,"Amount",amountInWei)
        const estimatedGas = await contract.methods.transfer(address, amountInWei).estimateGas({ from: web3.eth.defaultAccount });
        const tx = {
            from: web3.eth.defaultAccount,
            to: contractAddress,
            value: 0,
            gasPrice: web3.utils.toWei("8", "gwei"),
            gasLimit: estimatedGas,
            data: contract.methods.transfer(address, amountInWei).encodeABI(),
          };
    
          txs.push(tx);
        }
      
      for (let i = 0; i < txs.length; i++) {
        // console.log("gasPrice",tx)
        const tx = txs[i];
        const nonce = await web3.eth.getTransactionCount(web3.eth.defaultAccount,"pending")
        tx.nonce = nonce;
        const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);
        const sentTx = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
        // console.log(sentTx)
        const txHash = sentTx.transactionHash;
        // console.log(txHash,"TXN Hash")

        setCounter(i+1)
        await transactionHash.push(txHash);
    }
    setLoading(false)
    setCounter(0)
    setTotal(0)
    await handleDownload();
    }
    catch(e) {
        alert(e);
        setLoading(false)
        await handleDownload();
    }
};

const handleDownload = async() => {
    // console.log("txn hash download",transactionHash)
    // console.log("Excel Data",data)
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Transactions');

        worksheet.columns = [
            { header: 'S.NO', key: 'index' },
            { header: 'Public Address', key: 'address' },
            { header: 'Amount', key: 'amount' },
            { header: 'Transaction Hash', key: 'hash' }
        ];

        data.slice(1).forEach((row, i) => {
            const address = row[1];
            const amount = row[2];
            const hash = transactionHash[i];
            const index = i+1;

            worksheet.addRow({ index, address, amount, hash });
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ozo_distribution.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            readXlsxFile(blob).then((rows) => {
                setData(rows);
            });
        });
    }


    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

  return (
    <main className="page-content-admin">
    <div className=''>
    <h2>BABY OZO Airdrop</h2>
        <hr />
        <div className='d-flex mb-5 mt-3' style={{justifyContent:"space-evenly"}}>
        <div className='d-flex flex-column' >
            <h5>Primary Wallet Address : </h5> 
            <p>0xFe3829fE9e50ddb78F5F9bB5050CcD0B3753C16A</p>
            
        </div>
        <div className='d-flex flex-column' >
            <h5>Ozone Balance : </h5>
            <p style={{textAlign:"center"}}>{addressBalance} OZO</p> 
        </div>
        </div>

        <div className='d-flex mb-5 mt-3' style={{justifyContent:"space-evenly"}}>
        <div className='d-flex flex-column' >
            <h5>Baby OZO Contract Address : </h5> 
            <p>0xB77C34954847Ea37d00678b16F33C0012B701d7F</p>
            
        </div>
        <div className='d-flex flex-column' >
            <h5>BABY OZO Balance : </h5>
            <p style={{textAlign:"center"}}>{babyOZOBalance} BABYOZO</p> 
        </div>
        </div>

        <div className='d-flex 'style={{justifyContent:"space-evenly"}}>
            <input type="file" name="file" onChange={handleUpload} />
            <button className='btn btn-primary' disabled= {!excel ? true : false } onClick={() => setPopup(true)}>Transfer BABY OZO</button>
        </div>
      <br />

      {excel ? 

      <table className='table align-middle'>
        <thead className='table-secondary'>
        {data.slice(0,1).map((row, i) => (
          <tr key={i}>
            {row.map((cell, j) => (
              <th key={j}>{cell}</th>
            ))}
          </tr>
        ))}
        </thead>
        {data.slice(1).map((row, i) => (
          <tr key={i}>
            {row.map((cell, j) => (
              <td key={j}>{cell}</td>
            ))}
          </tr>
        ))}
      </table>
        : 
        <h5 className='mt-4' style={{textAlign:"center"}}>Please Upload a File to Transfer</h5> 
         }
    </div>
    <Modal show={popup} onHide={() => {setPopup(false)}}>
    <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
            <Modal.Body>
               <div className="modal-content">
                  <div className="popup-body">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                     <button className="btn btn-primary"  style={{ width: "80px" }}>Yes</button>
                     <button className="btn btn-primary ml-2" style={{ width: "80px" }}>No</button>
                  </div>
                  </div>
               </div>
            </Modal.Body>

         </Modal>

         <Modal show={popup} onHide={() => {setPopup(false)}}>
        <Modal.Header >
          <Modal.Title>Ozone Distribution Confirmation </Modal.Title>
          <a className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => {setPopup(false)}} style={{cursor:"pointer"}}><em className="ti ti-close"></em></a>
        </Modal.Header>
        <Modal.Body>
          <p className='mt-4 mb-4 ' style={{textAlign:"center"}}> Please confirm your decision to proceed with the transfer of funds or engage in a similar financial transaction. It is important to ensure that you have thoroughly considered and are fully committed to this action before proceeding.</p>
        </Modal.Body>
        <Modal.Footer>
        <button className="btn btn-outline-success"  style={{ width: "80px" }} onClick={handleTransfer} >Yes</button>
        <button className="btn btn-outline-success" style={{ width: "80px" }}  onClick={() => {setPopup(false)}}>No</button>
        </Modal.Footer>
      </Modal>

      <Modal show={loading} centered size="sm">
        <Modal.Body>
        <Lottie
               options={defaultOptions}
               height={200}
               width={150}
               />
               <p className='mb-4' style={{fontSize: "20px",marginTop: "-40px",color:"black",textAlign:"center"}}> {counter}/{total} Completed</p>
        </Modal.Body>
      </Modal>
    </main>
  )
}

export default BabyOZOWrapper


