
import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {QRCodeSVG} from 'qrcode.react';
import Web3 from 'web3';
import USDTabi from "./USDTabi.json"; 
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {apiURI} from "./../../config/config"
import Cookies from 'js-cookie'
function Transactionpay({showPay,handleClosePay,allTransaction,viewTransactiono,transactionHash,settransactionHash,getTransactions,setdataSubmit,dataSubmit}) 
{
   const paymentaddress = useSelector((state) => state.constVar.paymentaddress)
   const [fromWallet, setfromWallet] = useState("")
   const user_id = useSelector((state) => state.constVar.user_id)

    var filteredData=[]

    if(allTransaction)
    {
         filteredData = allTransaction.filter(item => item.transaction_no === viewTransactiono);
    }
    const verifyPayment = async () => {
      console.log("verifypayment called !!")

         const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/0a38c92df0ee41fba804cd70dfebb0a9'));
         const USDT_CONTRACT_ADDRESS = '0xdAC17F958D2ee523a2206206994597C13D831ec7'; 

            // Create contract object
            const contract = new web3.eth.Contract(USDTabi, USDT_CONTRACT_ADDRESS);

            // Transaction hash of the USDT transfer  
            const txHash = "0xff676b5728b41c31c7d8b181a70dcedd2f2e71a4afe4885fad21c3414d341c93";
            // '0xff676b5728b41c31c7d8b181a70dcedd2f2e71a4afe4885fad21c3414d341c93';
         //  "  0xbb08d95f2ac2d37f1d24c316185ba07b598a910ef25eda5130e61eaf9ef6e209"
            
       
            // Fetch the transaction receipt
            web3.eth.getTransactionReceipt(txHash)
               .then(receipt => {
              
                              if (!receipt) {
                                 console.log("Transaction not found");
                                 return;
                              }
            // Get the events emitted by the contract
            contract.getPastEvents('Transfer', { fromBlock: receipt.blockNumber, toBlock: receipt.blockNumber }, (error, events) => {
            if (error) {
               console.log(error);
            }
            else {
       
               for (let i = 0; i < events.length; i++) {
                // Check if the event is related to the transaction
                  if (events[i].transactionHash === txHash) {
                    console.log("From Address :",events[i].returnValues.from)
                    console.log("To Address :",events[i].returnValues.to)
                    console.log("Value Transferred :",(events[i].returnValues.value/1000000),"USDT")
                    updatePayment("paid");
                  }
               }
            }
         });
      })
      .catch(error => {
         console.error(error);
      });
    }





















    const updatePayment =  async (a) => {
        // var currentDate = new Date();
        // let timestamp_submitted=currentDate.toLocaleString()
        // console.log(timestamp_submitted,"timestamp submit");
     
     try{
        fetch(apiURI.URL+'user_transaction/', {
           method: 'PUT',
           headers: { 'Content-Type': 'application/json',
           'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
         },
           body: JSON.stringify(
              { 
       
        transaction_no:viewTransactiono,
        timestamp_submitted:filteredData[0]?.timestamp_submitted,
        timestamp_confirmed:filteredData[0]?.timestamp_confirmed,
        timestamp_initiated:filteredData[0]?.timestamp_initiated,
        transaction_hash:transactionHash,
        send_tokens:filteredData[0]?.send_tokens,
        from_address:fromWallet, 
        to_address:paymentaddress, 
        transaction_type:filteredData[0]?.transaction_type,
        tran_status:a === "paid"? "paid":"pending",
              }
              )
         })
         .then((response) => {
     
           const json = response.json();
           console.log("response data",json)
           return json;
       })
           
       .then(data =>{
         console.log(data)
         if(data.message === "Transaction Updated successfully")
         {
            alert("updated")
     
         }
         else if(data.message ==="Transaction hash already exists")
         {
        alert("Transaction hash already exists ,please provide valid transaction ID")
         }
         else if(data.message ==="Transaction number and transaction hash cannot be empty")
         {
         console.log("Transaction number and transaction hash cannot be empty")
         }
      } )
           .catch(error => console.error(error));
     }
     catch(error){
        console.log(error, "error");
     }
     await getTransactions ();
     handleClosePay();
     }

     const submitData = () => {

      //   updatePayment();
      verifyPayment();
        setdataSubmit(!dataSubmit)
       
    }


    const getWallet = () => {

      try {
         fetch(apiURI.URL+'get_wallet_address/', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(
               {
                  user_id: Cookies.get('type')==="guest"?143:user_id,

               }
            )
         })
            .then(response => response.json())

            .then(data => {
               console.log("all wallet data", data)
               let currentWallet = data.find(object => object.current === true);
               setfromWallet(currentWallet.wallet_address)
            })
            .catch(error => console.error(error));
      }
      catch (error) {
         console.log(error, "error");
      }

   }

   useEffect(() => {

      getWallet();

   }, [])





   return (
                  
  
                  <Modal 
                  show={showPay} 
                  onHide={handleClosePay}
                  size="xl"
                  >

                    <Modal.Body>      
                  <div className="modal-content">
                  <a   className="modal-close"  >
                    <em className="ti ti-close" onClick={handleClosePay}></em></a>
                  <div className="popup-body popup-body-lg">
                  <div className="content-area">
                  <div style={{width:"100%"}}>
                        <div style={{textAlign:"left"}}>
                     <label style={{fontSize:"16px",color:"black"}}>Copy below wallet address to make payment or scan the qr to get payment link</label>
                     </div>
                     <div className="copy-wrap mgb-0-5x">
                        <span className="copy-feedback"></span>
                     <em className="fas fa-link"></em>
                     <input type="text" className="copy-address mb-4" value="0xCc6B7586f20EdB5273099eF93495Ec8cf3aA3796"></input>
                     {/* <button className="copy-trigger copy-clipboard" data-clipboard-text="https://demo.themenio.com/ico?ref=7d264f90653733592"><em className="ti ti-files"></em></button> */}
                     </div>
                     <QRCodeSVG value="0xCc6B7586f20EdB5273099eF93495Ec8cf3aA3796" />

                   
             

                     </div>
                  <label style={{fontSize:"16px",color:"black",marginTop:"40px"}}>Please paste your transaction hash to complete payment</label>
                   
               
                     <input type="text" className="copy-address mb-4" value={transactionHash} onChange={(e)=>settransactionHash(e.target.value)}></input>

                     <button  onClick={()=>submitData()} className="btn btn-primary">Submit</button>
            
                 
                  </div>
           
               </div>
            </div>



</Modal.Body>

</Modal>
                 
   );
}

export default Transactionpay;
