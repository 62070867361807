import React from 'react';
import './entrypages.css';
import ozologo from "../../assets/img-ozo/logo-ozone-dark-284x85.png"
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { apiURI } from "./../../config/config"
import Lottie from 'react-lottie';
import Modal from 'react-bootstrap/Modal';
import animationData from "./dots.json"
import Cookies from 'js-cookie'

function Forgotpassword() {

   const [email, setemail] = useState("")
   const [show, setShow] = useState(false);
   const [showPopup, setshowPopup] = useState(false);
   const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
         preserveAspectRatio: "xMidYMid slice"
      }
   };

   const submit = () => {
      let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = pattern.test(email);
      if (result) {
         try {
            setShow(true);
            fetch(apiURI.URL + 'forgot_password/', {
               method: 'POST',
               headers: { 'Content-Type': 'application/json',
               'Authorization': Cookies.get('type') === "guest"?"":`Bearer ${localStorage.getItem('token')}`
             },
               body: JSON.stringify(
                  {
                     "email": email
                  }
               )
            })
               .then((response) => {

                  const json = response.json();
                  console.log("response data", json)
                  return json;
               })

               .then(data => {
                  setShow(false);
                  if (data?.message === "Password reset link sent to your email address") {
                     setshowPopup(true)
                  }

               })
               .catch(error => console.error(error));
         }
         catch (error) {
            console.log(error, "error");
         }
      }
      else {
         alert("please enter valid email")
      }

   }

   const navigatelogin = () => {
      setshowPopup(false)
      // navigate("/login")
   }

   return (
      <div className="page-ath-wrap">
         <div className="page-ath-content">
            <div className="page-ath-header"><a href="index.php" className="page-ath-logo"><img src={ozologo} alt="logo"></img></a></div>
            <div className="page-ath-form">
               <h2 className="page-ath-heading">Reset password
                  {/* <span>If you forgot your password, well, then we’ll email you instructions to reset your password.</span> */}
               </h2>
               <div action="#">
                  <div className="input-item">
                     <input type="text" placeholder="Your Email" className="input-bordered" onChange={(e) => { setemail(e.target.value) }}></input>
                  </div>
                  <div className="d-flex justify-content-between align-items-center cs">
                     <div><button className="btn btn-primary btn-block" onClick={submit}>Send Reset Link</button></div>
                     <div><Link to="/login"><p className='mt-2'>Return to login</p></Link></div>
                  </div>
                  <div className="gaps-2x"></div>
               </div>
            </div>
            <div className="page-ath-footer">
               <ul className="footer-links">
                  <li>&copy; 2023 Ozonechain Labs.</li>
               </ul>
            </div>
         </div>
         <div className="page-ath-gfx">
            <div className="w-100 d-flex justify-content-center">
            </div>
         </div>
         <div style={{ position: "fixed", padding: "100px", display: show === true ? "flex" : "none", justifyContent: "center", width: "100%", height: "100%", backgroundColor: "#80808063", top: "0px", left: "0px" }}>
            <Lottie
               options={defaultOptions}
               height={300}
               width={300}
               style={{ marginTop: "10%" }} />
            <Modal show={showPopup}
               size="sm"
               aria-labelledby="contained-modal-title-vcenter"
               centered>
               <Modal.Body>
                  <div style={{ padding: "20px", justifyContent: "center", textAlign: "center" }}>
                     <p style={{ fontSize: "17px" }}>A password reset link will be sent if provided mail id is registered,Please check your email.
                     </p>
                     <br></br>
                     <div style={{ display: "flex", justifyContent: "center" }}>
                        <button className="btn btn-primary" style={{ width: "80px" }} onClick={navigatelogin}>OK</button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </div>
      </div>
   );
}

export default Forgotpassword;
